import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useAxios from '@use-hooks/axios';
import { oc } from 'ts-optchain';

import { useWhyDidYouUpdate, useSt8 } from '../../hooks';
import { useRootStore } from '../../store';
import ChangePassForm, { ChangePassFormData } from './change-pass-form';
import ModalSpinner from '../../components/modal-spinner';
import ModalDialog from '../../components/modal-dialog';
import { ROUTES } from '../../routes';

type Props = RouteComponentProps & {};

const ChangePassView: FC<Props> = props => {
  /*-------------------- Body -----------------------------------------------*/
  const { appStore } = useRootStore();
  const username = useSt8('');
  const tempPass = useSt8('');
  const password = useSt8('');
  const message = useSt8('');

  const url =
    appStore.webapi +
    `xSetNewPassword.cshtml?` +
    `NewPass=${password()}&` +
    `TmpPass=${tempPass()}&` +
    `Username=${username()}`;

  const { response, loading, error, reFetch } = useAxios({ url, method: 'GET' });
  const data = oc(response).data(false);

  /* --Το effect τρεχει οταν αλλάζει το data  */

  useEffect(() => {
    if (error) {
      message('Σφάλμα επικοινωνίας με τον Server!');
    } else {
      if (data !== false) {
        const res = JSON.parse(data);
        // const errResult: string = oc(res).ErrResult('');
        const errMessage = oc(res).ErrMessage('Σφάλμα στην απάντηση του Server');
        const errResult = oc(res).ErrResult(-1);
        message(errMessage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  // useWhyDidYouUpdate('[ChangePassView]', { data, loading, error });
  /*-------------------- Handlers -------------------------------------------*/
  const handleSubmit = (res: ChangePassFormData) => {
    username(res.username);
    password(res.password);
    tempPass(res.tempPass);
    reFetch();
  };

  const handleDesmiss = () => {
    props.history.push(ROUTES.Login);
  };

  /*-------------------- Render ---------------------------------------------*/
  return (
    <div style={{ margin: 'auto' }}>
      <ChangePassForm onSubmit={handleSubmit} />
      <ModalDialog title={message()} trigger={message() !== ''} onClick={handleDesmiss} />
      <ModalSpinner show={loading}></ModalSpinner>
    </div>
  );
};

export default ChangePassView;
