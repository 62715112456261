import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import ModalSpinner from '../../components/modal-spinner';
import { Card, Form, Button } from 'react-bootstrap';
import { useRootStore } from '../../store';
import ModalDialog from '../../components/modal-dialog';
import './reward-page.css';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import useAxios, { axios } from '@use-hooks/axios';
import { oc } from 'ts-optchain';

type Props = RouteComponentProps & {};

const RewardPage: FC<Props> = props => {
  /*-------------------- Body -----------------------------------------------*/
  const { appStore } = useRootStore();

  // ---------------------------  preRequest Hook
  const cstPointId = appStore.cstPointId;
  const user = appStore.user;
  const password = appStore.password;
  const urlPre =
    appStore.webapi +
    `CstAwardParticipation.cshtml?User=${user}` +
    `&Pass=${password}` +
    `&CstPointId=${cstPointId}` +
    `&Type=GET`;

  const {
    response: responsePre,
    loading: loadingPre,
    error: errorPre,
    reFetch: preRefetch,
  } = useAxios({
    url: urlPre,
    method: 'GET',
    trigger: [],
  });
  const awardNoParticipate = oc(responsePre).data.AwardNoParticipate(undefined);

  /*-------------------- Handlers -------------------------------------------*/
  const handleAwardNoParticipateChange = async event => {
    if (event.currentTarget.checked) {
      alert('checked');
      const urlSetNoParticipate =
        appStore.webapi +
        `CstAwardParticipation.cshtml?User=${user}` +
        `&Pass=${password}` +
        `&CstPointId=${cstPointId}` +
        `&Type=SET` +
        `&Value=true`;
      await axios.get(urlSetNoParticipate);
      preRefetch();
    } else {
      alert('Επικοινωνήστε με την EcoElastika για να συμμετάσχετε ξανά.');
      const chBox: any = document.getElementById('awardNoParticipate');
      if (chBox) chBox.checked = true;
    }
  };
  // const handleSubmit = (formData: AgreementFormData) => {
  //   setAgreement({ ...formData, AgreementId: calculateWichAgreementId() });
  //   appStore.consent();
  //   submit();
  // };

  /*-------------------- Render ---------------------------------------------*/
  return (
    <>
      <Card className="bg-light shadow">
        <Card.Header>
          <Card.Title className="mb-0 d-flex flex-row justify-content-between align-items-center">
            <span>Όροι Επιβράβευσης</span>
            <Form.Group className="mb-0 mt-2" controlId="report-footer-checkbox">
              {awardNoParticipate != undefined && (
                <Form.Check
                  id="awardNoParticipate"
                  type="checkbox"
                  label="Δεν επιθυμώ να συμμετέχω"
                  defaultChecked={awardNoParticipate}
                  onChange={handleAwardNoParticipateChange}
                />
              )}
            </Form.Group>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="mb-4">
            <img
              // width="500"
              // height="125"
              width="30%"
              src="./images/ecopoint-REWARD-logo-transparent.png"
              style={{ marginTop: '2.5%' }}
            />
            <img
              width="20%"
              // width="490"
              // height="311"
              src="./images/synepeia-1.png"
              style={{ float: 'right' }}
            />
          </div>
          <div style={{ clear: 'right' }}></div>
          <h2 className="mt-4 mb-4" style={{ textAlign: 'center' }}>
            Πρόγραμμα Ανταμοιβής
          </h2>
          <div className="wpb_wrapper text-center" style={{ width: '70%', margin: 'auto' }}>
            <p>
              H <b>Ecoelastika</b> επιβραβεύει τα&nbsp;
              <strong className="shortcd">
                eco<span className="green">elastika</span>spots
              </strong>
              &nbsp;που δίνουν αποκλειστικά την παραγγελία τους εδώ! Μέσω της εφαρμογής{' '}
              <b>Ecopoint</b>.
            </p>
            <p>
              Διεκδικήστε με τη λήξη κάθε έτους προπληρωμένες δωροκάρτες SPENDEO Edenred αξίας 250€.
            </p>
            <p>
              <strong className="shortcd">
                <span className="green">Διαβάστε τους όρους συμμετοχής</span>
              </strong>
            </p>
            <div className="mb-4" style={{ textAlign: 'center' }}>
              <Button
                variant="success"
                onClick={() => {
                  window.open(
                    './ECOELASTIKA_ΟΡΟΙ ΚΑΙ ΠΡΟΎΠΟΘΕΣΕΙΣ_LOYALTY_BT FINAL 14.07.2023.pdf',
                    '_blank',
                    'noreferrer',
                  );
                }}
              >
                Όροι Συμμετοχής
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
export default RewardPage;
