import React from 'react';

import { useAgreementsQuery, useAgreementByIdPDFQuery } from '../../api';
import { Card, Button, Col, Row, Table, Spinner } from 'react-bootstrap';

export const AgreementsView = props => {
  const { loading, error, records, reFetch } = useAgreementsQuery();

  return (
    <Card className="bg-light shadow">
      <Card.Header>
        <Card.Title className="mb-0 d-flex flex-row justify-content-between align-items-center">
          <span>Ιστορικό Όρων Συνεργασίας</span>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {records && (
          <Row className="m-1">
            <Col>
              <Table className="mb-1" responsive size="sm">
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }} colSpan={6}>
                      Υπογεγραμένοι Όροι Συνεργασίας:
                    </th>
                  </tr>
                  <tr>
                    <th style={{ textAlign: 'center' }}>Ημερομηνία</th>
                    <th style={{ textAlign: 'center' }}>Σύμβαση</th>
                    <th style={{ textAlign: 'center' }}>Χρήστης</th>
                    <th style={{ textAlign: 'center' }}>Έχει Τερματιστεί;</th>
                    <th style={{ textAlign: 'center' }}>Ημ/νία Τερματισμού</th>
                    <th style={{ textAlign: 'center' }}>Ενέργειες</th>
                  </tr>
                  <tr></tr>
                </thead>
                <tbody>
                  {records.map((rec, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ textAlign: 'center' }}>{rec.ConsentDate}</td>
                        <td style={{ textAlign: 'left' }}>{rec.AgreementName}</td>
                        <td style={{ textAlign: 'center' }}>{rec.UserProfile_EMail}</td>
                        <td style={{ textAlign: 'center' }}>{rec.IsTerminated ? 'ΝΑΙ' : 'ΟΧΙ'}</td>

                        <td style={{ textAlign: 'center' }}>
                          {rec.IsTerminated ? rec.TerminatedDate : ''}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <PrintAgreement agreementIID={rec.IID} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

const PrintAgreement = ({ agreementIID }) => {
  const { busy, finish: finishPdf, reFetch } = useAgreementByIdPDFQuery({
    agreementId: agreementIID,
    type: 'PDF',
  });

  return (
    <>
      {busy ? <Spinner animation="border" variant="primary" size="sm" /> : null}
      <Button variant="link" onClick={reFetch}>
        Εκτύπωση
      </Button>
    </>
  );
};
