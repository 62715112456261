import React, { FC, useEffect, useState } from 'react';
import { Form, Button, Card, Alert, OverlayTrigger, Tooltip, Row } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import * as yup from 'yup';
import useForm from 'react-hook-form';
import { oc } from 'ts-optchain';

// import { useWhyDidYouUpdate, useLogger } from '../../hooks';
import { OrderRequest, CstPointForm } from '../../models';
import { useEffectOnce, useLogger } from '../../hooks';
import FlexRow from '../../components/flex-row';

/**
 * Setup Yup Validation & Type
 */
const validationSchema = yup.object().shape({
  Address: yup.string().required(),
  Municipality: yup.string().required(),
  ZipCode: yup.string().required(),
  VAT: yup.string(),
  IRS: yup.string(),
  ima: yup.string().matches(/^[0-9-]*$/, ''),
  Phone1: yup.string().required(),
  Phone2: yup.string(),
  Phone3: yup.string(),
  Fax1: yup.string(),
  Email: yup.string().required(),
  Contact: yup.string().required(),
});

export type CstPointFormData = {
  Address: string;
  Municipality: string;
  ZipCode: string;
  VAT: string;
  IRS: string;
  ima: string;
  Phone1: string;
  Phone2: string;
  Phone3: string;
  Fax1: string;
  Email: string;
  Contact: string;
};

type Props = {
  cstPoint: CstPointForm;
  onSubmit: (data: CstPointFormData) => void;
  recordsMun: any;
};

const CstPointCard: FC<Props> = ({ cstPoint, onSubmit, recordsMun }) => {
  //useLogger('[RequestCard]', order.CstPointStatus, order.OrderId);
  /*-------------------- Body -----------------------------------------------*/

  const [showAlert, setShowAlert] = useState(true);

  const initialValues = {
    Address: cstPoint.Address,
    Municipality: cstPoint.Municipality,
    ZipCode: cstPoint.ZipCode,
    VAT: cstPoint.VAT,
    IRS: cstPoint.IRS,
    ima: cstPoint.ima,
    Phone1: cstPoint.Phone1,
    Phone2: cstPoint.Phone2,
    Phone3: cstPoint.Phone3,
    Fax1: cstPoint.Fax1,
    Email: cstPoint.Email,
    Contact: cstPoint.Contact,
  };

  const { register, handleSubmit, formState, errors, reset, getValues, setValue } = useForm<
    CstPointFormData
  >({
    defaultValues: initialValues,
    validationSchema: validationSchema,
    mode: 'onBlur',
  });

  const canSubmit = formState.dirty && formState.isValid;

  /*-------------------- Handlers -------------------------------------------*/
  const handleOnSubmit = (data: CstPointFormData) => {
    console.log('Submit:', data);

    onSubmit(data);
  };

  const handleAlertDesmiss = () => {
    setShowAlert(false);
  };

  /*-------------------- Render ---------------------------------------------*/
  // useWhyDidYouUpdate('[RequestCard]', { order, formState, errors, sum });
  return (
    <Card className="bg-light shadow">
      <Card.Header>
        <Card.Title className="mb-0">Προφίλ Σημείου Συλλογής</Card.Title>
      </Card.Header>

      <Form onSubmit={handleSubmit(handleOnSubmit)}>
        <Card.Body className="p-2 p-sm-4">
          <Form.Group className="mb-1 mb-sm-2" controlId="formAddress">
            <Form.Label className="mb-1 mb-sm-2">Διεύθυνση</Form.Label>
            <Form.Control
              name="Address"
              type="string"
              ref={register}
              isInvalid={'Address' in errors}
            />
            <Form.Control.Feedback type="invalid">
              {'Το πεδίο είναι υποχρεωτικό'}
            </Form.Control.Feedback>
          </Form.Group>

          {/* <Form.Group className="mb-1 mb-sm-2" controlId="formMunicipality">
            <Form.Label>Δήμος</Form.Label>
            <Form.Control
              name="Municipality"
              as="select"
              ref={register}
              isInvalid={'Municipality' in errors}

            >
              {recordsMun.map(record => {
                return (
                  <option key={record.Municipality} value={record.Municipality}>
                    {record.Municipality}
                  </option>
                );
              })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {'Το πεδίο είναι υποχρεωτικό'}
            </Form.Control.Feedback>
          </Form.Group> */}
          {recordsMun && (
            <SelectMunicipality recordsMun={recordsMun} register={register} errors={errors} />
          )}

          <Form.Group className="mb-1 mb-sm-2" controlId="formZipCode">
            <Form.Label className="mb-1 mb-sm-2">T.K.</Form.Label>
            <Form.Control
              name="ZipCode"
              type="string"
              ref={register}
              isInvalid={'ZipCode' in errors}
            />
            <Form.Control.Feedback type="invalid">
              {'Το πεδίο είναι υποχρεωτικό'}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-1 mb-sm-2" controlId="formVAT">
            <Form.Label className="mb-1 mb-sm-2">ΑΦΜ</Form.Label>
            <Form.Control
              name="VAT"
              type="string"
              ref={register}
              disabled
              isInvalid={'VAT' in errors}
            />
          </Form.Group>

          <Form.Group className="mb-1 mb-sm-2" controlId="formIRS">
            <Form.Label className="mb-1 mb-sm-2">ΔΟΥ</Form.Label>
            <Form.Control
              name="IRS"
              type="string"
              ref={register}
              disabled
              isInvalid={'IRS' in errors}
            />
          </Form.Group>

          <Form.Group className="mb-1 mb-sm-2" controlId="formIma">
            <Form.Label className="mb-1 mb-sm-2">Η.Μ.Α.</Form.Label>
            <Form.Control name="ima" type="string" ref={register} isInvalid={'ima' in errors} />
            <Form.Control.Feedback type="invalid">
              {`Το πεδίο μπορεί να λάβει μόνο αριθμούς και το σύμβολο " - "`}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-1 mb-sm-2" controlId="formPhone1">
            <Form.Label className="mb-1 mb-sm-2">Τηλέφωνο</Form.Label>
            <Form.Control
              name="Phone1"
              type="string"
              ref={register}
              isInvalid={'Phone1' in errors}
            />
            <Form.Control.Feedback type="invalid">
              {'Το πεδίο είναι υποχρεωτικό'}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-1 mb-sm-2" controlId="formPhone2">
            <Form.Label className="mb-1 mb-sm-2">Τηλέφωνο 2</Form.Label>
            <Form.Control
              name="Phone2"
              type="string"
              ref={register}
              isInvalid={'Phone2' in errors}
            />
          </Form.Group>

          <Form.Group className="mb-1 mb-sm-2" controlId="formPhone3">
            <Form.Label className="mb-1 mb-sm-2">Κινητό</Form.Label>
            <Form.Control
              name="Phone3"
              type="string"
              ref={register}
              isInvalid={'Phone3' in errors}
            />
          </Form.Group>

          <Form.Group className="mb-1 mb-sm-2" controlId="formFax1">
            <Form.Label className="mb-1 mb-sm-2">Fax</Form.Label>
            <Form.Control name="Fax1" type="string" ref={register} isInvalid={'Fax1' in errors} />
          </Form.Group>

          <Form.Group className="mb-1 mb-sm-2" controlId="formEmail">
            <Form.Label className="mb-1 mb-sm-2">Email</Form.Label>
            <Form.Control name="Email" type="string" ref={register} isInvalid={'Email' in errors} />
            <Form.Control.Feedback type="invalid">
              {'Το πεδίο είναι υποχρεωτικό'}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-1 mb-sm-2" controlId="formContact">
            <Form.Label className="mb-1 mb-sm-2">Εκπρόσωπος</Form.Label>
            <Form.Control
              name="Contact"
              type="string"
              ref={register}
              isInvalid={'Contact' in errors}
            />
            <Form.Control.Feedback type="invalid">
              {'Το πεδίο είναι υποχρεωτικό'}
            </Form.Control.Feedback>
          </Form.Group>
        </Card.Body>
        <Card.Footer>
          <FlexRow>
            <Button block variant="primary" type="submit" disabled={!canSubmit}>
              <span>{'Αποθήκευση'}</span>
            </Button>
            <span className="mx-1"></span>
            <Button
              block
              variant="danger"
              onClick={() => {
                reset(initialValues);
              }}
              disabled={!canSubmit}
            >
              <span>{'Ακύρωση'}</span>
            </Button>
          </FlexRow>
        </Card.Footer>
      </Form>
    </Card>
  );
};

const SelectMunicipality = ({ recordsMun, register, errors }) => {
  const [options, setOptions] = useState();

  useEffectOnce(() => {
    let values: any = [];
    for (let index = 0; index < recordsMun.length; index++) {
      const record = recordsMun[index];

      values.push(
        <option key={record.Municipality} value={record.Municipality}>
          {record.Municipality}
        </option>,
      );
    }
    setOptions(values);
  });

  if (!options) return <>...</>;
  else
    return (
      <Form.Group className="mb-1 mb-sm-2" controlId="formMunicipality">
        <Form.Label>Δήμος</Form.Label>
        <Form.Control
          name="Municipality"
          as="select"
          ref={register}
          isInvalid={'Municipality' in errors}
        >
          {options}
        </Form.Control>

        <Form.Control.Feedback type="invalid">{'Το πεδίο είναι υποχρεωτικό'}</Form.Control.Feedback>
      </Form.Group>
    );
};

export default CstPointCard;
