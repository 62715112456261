import React, { FC, useEffect } from 'react';
import { BrowserRouter, useHistory } from 'react-router-dom';
import AddToHomescreen from 'react-add-to-homescreen';

import { RootStoreProvider, useRootStore } from '../store';
import AppRouter from './app-route';
import { useLogger } from '../hooks';
//--- Datepicker registration-----
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import el from 'date-fns/locale/el';
import CacheBuster from '../components/CacheBuster';
import { useIdleTimer } from 'react-idle-timer';

registerLocale('el', el);
setDefaultLocale('el');

export type ConfigType = {
  webapi: string;
};

type Props = {
  config?: ConfigType;
};

export const App: FC<Props> = ({ config = {} }) => {
  useLogger('[***APP***]', config);
  const { appStore } = useRootStore();

  // Start --- Idle Timer => Logout on Inactivity -----------------------------
  const handleOnIdle = event => {
    // Καλείται όταν λήξει το timeout
    console.log('User is Inactive for too long', event);
    //console.log('last active', getLastActiveTime());

    // Logout User
    if (appStore.isLogIn) {
      localStorage.clear();
      appStore.logOut();
    }
  };

  const minutesPassed = lastUpdateDate => {
    const updateDate = new Date(lastUpdateDate);
    const now = new Date();
    const diff = (now.getTime() - updateDate.getTime()) / (1000 * 60);
    return Math.abs(Math.round(diff));
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15, // logout after 15 minutes of inactivity
    onIdle: handleOnIdle,
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500,
  });

  const handleAddToHomescreenClick = () => {
    alert(`
      1. Open Share menu
      2. Tap on "Add to Home Screen" button`);
  };

  useEffect(() => {
    // Clear on app load για να ζητήσει login
    const dt = sessionStorage.getItem('date');
    if (!dt) {
      console.log('clear login data');
      localStorage.clear();
      appStore.logOut();
    }
  }, [appStore]);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload, latestVersion, currentVersion }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          if (minutesPassed(appStore.lastUpdateDate) >= 5 && latestVersion > currentVersion) {
            if ('serviceWorker' in navigator) {
              navigator.serviceWorker.getRegistrations().then(function(registrations) {
                for (let registration of registrations) {
                  window.alert(
                    `Θα γίνει αναβάθμιση της εφαρμογής στην νέα έκδοση (${latestVersion}) από την έκδοση (${currentVersion}) που έχετε τώρα`,
                  );
                  registration.update().then(() => {
                    appStore.setUpdateDate(new Date().valueOf());
                    refreshCacheAndReload();
                  });
                }
              });
            } else {
              appStore.setUpdateDate(new Date().valueOf());
              refreshCacheAndReload();
            }
          }
        }

        return (
          <BrowserRouter basename="/">
            <AddToHomescreen
              className="pwa"
              onAddToHomescreenClick={handleAddToHomescreenClick}
              icon={'https://img.icons8.com/bubbles/100/000000/touchscreen-smartphone.png'}
            />
            <RootStoreProvider>
              <AppRouter />
            </RootStoreProvider>
          </BrowserRouter>
        );
      }}
    </CacheBuster>
  );
};

export default App;
