import React, { FC, useState } from 'react';
import { Navbar, Nav, Form, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FaChevronCircleDown, FaGift, FaUser } from 'react-icons/fa';
// import { useWhyDidYouUpdate, useLogger } from '../hooks';
import { ROUTES } from '../routes';
import { useRootStore } from '../store';
import './header.css';
import { CstPointTypes } from '../common/constants';
/**
 * ΣΗΜΕΙΩΣΕΙΣ:
 *
 * Για το σωστό έλεγχο του Collapse/Expand στις μικρές οθόνες, δεν χρησιμοποιώ
 * τον αυτόματο τρόπο με το collapseOnSelect γιατί εδώ έχω κουμπί στο logout
 * και το 'αυτόματο' παίζει σωστά μόνο με navlinks.
 * Αναγκαστικά κάνω εγώ την διαχείριση κρατώντας την κατάσταση στο state με τη
 * μεταβλητή expanded και χρησιμοποιώντας τα events onToggle και onSelect,
 * προσοχή όμως, για να παίξει το onSelect ΠΡΕΠΕΙ να έχω δηλώσει eventKey σε κάθε
 * Nav.Link αλλιώς ΔΕΝ δημιουργείται event.
 *
 */
type Props = {
  isLogin: boolean;
  isPointSelected: boolean;
  isStorePoint: boolean;
  user: string;
  onLogoutClick: () => void;
};

const Header: FC<Props> = ({ isLogin, isPointSelected, isStorePoint, user, onLogoutClick }) => {
  /*-------------------- Body -----------------------------------------------*/
  const [expanded, setExpanded] = useState(false);
  const { appStore } = useRootStore();

  /*-------------------- Handlers -------------------------------------------*/
  const handleLogoutClick = () => {
    setExpanded(false);
    onLogoutClick();
  };

  const handleToggle = state => {
    //console.log('Toggle', state);
    setExpanded(state);
  };

  const handleSelect = () => {
    //console.log('Select');
    setExpanded(false);
  };

  const handleUnselectCP = () => {
    appStore.setPrevRoute(window.location.pathname);
    appStore.unselectPoint();
  };
  /*-------------------- Render ---------------------------------------------*/
  return (
    <header>
      <Navbar
        expand="xl"
        bg="dark"
        variant="dark"
        fixed="top"
        onToggle={handleToggle}
        onSelect={handleSelect}
        expanded={expanded}
      >
        <Navbar.Brand
          href={`https://${window.location.hostname}/`}
          //target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt=""
            src="/ecopoint_blackback_2.png"
            width="200"
            height="66"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>

        {isLogin && (
          <>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                {isStorePoint && isPointSelected && (
                  <Nav.Link
                    className="pl-3"
                    as={NavLink}
                    to={ROUTES.Request}
                    eventKey={ROUTES.Request}
                    /* disabled={
                      new Date('2021-04-16') < new Date() &&
                      (!appStore.hasConsented || appStore.isNotActive)
                    } */
                  >
                    Νέα Αίτηση
                  </Nav.Link>
                )}

                {!isStorePoint && isPointSelected && (
                  <Nav.Link
                    className="pl-3"
                    as={NavLink}
                    to={ROUTES.Request2}
                    eventKey={ROUTES.Request2}
                  >
                    Νέα Αίτηση
                  </Nav.Link>
                )}

                {isStorePoint && isPointSelected && (
                  <Nav.Link
                    className="pl-3"
                    as={NavLink}
                    to={ROUTES.Orders}
                    eventKey={ROUTES.Orders}
                  >
                    Παραστατικά Διακίνησης
                  </Nav.Link>
                )}

                {!isStorePoint && isPointSelected && (
                  <Nav.Link
                    as={NavLink}
                    className="pl-3"
                    to={ROUTES.Orders2}
                    eventKey={ROUTES.Orders2}
                  >
                    Παραστατικά Διακίνησης
                  </Nav.Link>
                )}

                {isPointSelected && (
                  <Nav.Link
                    as={NavLink}
                    className="pl-3"
                    to={ROUTES.PeriodReport}
                    eventKey={ROUTES.PeriodReport}
                  >
                    Ιστορικό
                  </Nav.Link>
                )}

                {isPointSelected && (
                  <Nav.Link
                    as={NavLink}
                    className="pl-3"
                    to={ROUTES.Certificate}
                    eventKey={ROUTES.Certificate}
                  >
                    Βεβαίωση
                  </Nav.Link>
                )}

                {isPointSelected && (
                  <Nav.Link
                    as={NavLink}
                    className="pl-3"
                    to={ROUTES.YearReport}
                    eventKey={ROUTES.YearReport}
                  >
                    Ετήσια Έκθεση / Η.Μ.Α
                  </Nav.Link>
                )}
                {/* Συμβάσεις μόνο για Καταστήματα */}
                {/* {appStore.cstPointTypeId === CstPointTypes.Shops && isPointSelected && ( */}
                {appStore.showTerms && (
                  <Nav.Link
                    as={NavLink}
                    className="pl-3"
                    to={ROUTES.Agreement}
                    eventKey={ROUTES.Agreement}
                  >
                    Όροι Συνεργασίας
                  </Nav.Link>
                )}
                {appStore.cstPointTypeId === CstPointTypes.Shops &&
                  isPointSelected &&
                  appStore.showNewTerms && (
                    <Nav.Link
                      as={NavLink}
                      className="pl-3"
                      to={ROUTES.NewAgreement}
                      eventKey={ROUTES.NewAgreement}
                    >
                      Νέοι Όροι Συνεργασίας
                    </Nav.Link>
                  )}

                {/* {isPointSelected && (
                  <Nav.Link as={NavLink} className="pl-3" to={ROUTES.About} eventKey={ROUTES.About}>
                    Πληροφορίες
                  </Nav.Link>
                )} */}

                {isPointSelected && (
                  <Nav.Link
                    as={NavLink}
                    className="pl-3"
                    to={ROUTES.CstPoint}
                    eventKey={ROUTES.CstPoint}
                  >
                    Προφίλ &nbsp;
                    <FaUser size={32} />
                  </Nav.Link>
                )}
                {isPointSelected && appStore.isTireShop && (
                  <Nav.Link
                    as={NavLink}
                    className="pl-3"
                    to={ROUTES.Reward}
                    eventKey={ROUTES.Reward}
                  >
                    Επιβράβευση &nbsp;
                    <FaGift size={32} />
                  </Nav.Link>
                )}
              </Nav>
              <Nav>
                {isLogin && (
                  <Navbar.Text className="pr-2">
                    <>
                      <i className="fas fa-user pr-1"></i>
                      <span>{user}</span>
                    </>
                    {appStore.cstPoints.length > 1 && appStore.cstPointName && (
                      <div
                        onClick={() => handleUnselectCP()}
                        style={{ textAlign: 'right', color: '#fff', cursor: 'pointer' }}
                      >
                        {appStore.cstPointName}
                        <span className="pl-1">
                          <FaChevronCircleDown />
                        </span>
                      </div>
                    )}
                  </Navbar.Text>
                )}
              </Nav>
              <Form inline>
                <Button variant="outline-success" className="border-0" onClick={handleLogoutClick}>
                  <i className="fas fa-sign-out-alt pr-2"></i>'Εξοδος
                </Button>
              </Form>
            </Navbar.Collapse>
          </>
        )}
      </Navbar>
    </header>
  );
};

export default Header;
