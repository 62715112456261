import { useState, useEffect } from 'react';
import useAxios from '@use-hooks/axios';
import { oc } from 'ts-optchain';
import { useRootStore } from '../store';
import { useWhyDidYouUpdate } from '../hooks';
import { axios } from '@use-hooks/axios';

export const useSubmitAgreementQuery = () => {
  const { appStore } = useRootStore();
  const [records, setRecords] = useState<any>();
  const [finish, setFinish] = useState(false);

  const buildUrl = agreement => {
    let url =
      appStore.webapi +
      `xSubmitAgreement.cshtml?` +
      `User=${appStore.user}` +
      `&Pass=${appStore.password}` +
      `&CstPointId=${appStore.cstPointId}` +
      `&AgreementId=${agreement.AgreementId}`;
    if (agreement.dataChanged === false) {
      url = url + `&DataChanged=false`;
    } else {
      url =
        url +
        `&DataChanged=true` +
        `&NewAddress=${agreement.Address}` +
        `&NewMunicipality=${agreement.Municipality}` +
        `&NewZipCode=${agreement.ZipCode}` +
        `&NewPhone1=${agreement.Phone1}` +
        `&NewPhone2=${agreement.Phone2}` +
        `&NewPhone3=${agreement.Phone3}` +
        `&NewFax1=${agreement.Fax1}` +
        `&NewEmail=${agreement.Email}` +
        `&NewContact=${agreement.Contact}` +
        `&NewContactPosition=${agreement.ContactPosition}` +
        `&NewAddressLongitude=${agreement.AddressLongitude}` +
        `&NewAddressLatitude=${agreement.AddressLatitude}`;
    }
    url =
      url +
      `&IsTyreImporter=${agreement.IsTyreImporter}` +
      `&CertifyNoTyreImport=${agreement.CertifyNoTyreImport}`;

    return url;
  };

  /*
  const { response, loading, error, reFetch } = useAxios({
    url: url,
    method: 'GET',
  });
  const data = oc(response).data();
  useEffect(() => {
    if (error) {
      setMessage('Σφάλμα επικοινωνίας με τον Server!');
    } else {
      if (!loading && !!data) {
        const resp = data as any;
        setRecords(resp);
        setFinish(true);
      }
    }
  }, [response, loading, error, data]);
  */
  const reFetch = async agreement => {
    if (!agreement || !agreement.AgreementId) {
      throw new Error('Λάθος στα στοιχεία.');
    }
    setFinish(false);
    const url = buildUrl(agreement);
    const response = await axios.get(url).catch(error => {
      setFinish(true);
      throw new Error('Λάθος στον Σέρβερ.');
    });

    setRecords(response);
    setFinish(true);
  };

  return {
    finish,
    records,
    reFetch,
  };
};
