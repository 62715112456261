import { useState, useEffect } from 'react';
import useAxios from '@use-hooks/axios';
import { oc } from 'ts-optchain';
import { useRootStore } from '../store';
import { useWhyDidYouUpdate } from '../hooks';

/*
###
GET http://192.168.160.203:8085/webapi/xOrders.cshtml?User=bikenrider@gmail.com&Pass=motion$hellas&CstPointId=2481
###
*/

export interface Order {
  ColDate: string;
  Col_DocId: string;
  CollectorName: string;
  CstPointId: string;
  ErrMessage: string;
  ErrResult: number;
  Notes: string;
  OrderId: number;
  RecNo: string;
  ReqDate: string;
  Status: string;
  Tyre1: string;
  Tyre2: string;
  Tyre3: string;
  Tyre4: string;
  Tyre5: string;
  PlannedDate: string;
  IsPlannedDateConfirmed: boolean;
  CollectorPhone: string;
}

export const useOrdersQuery = () => {
  const { appStore } = useRootStore();
  const [message, setMessage] = useState('');
  const [records, setRecords] = useState([] as Order[]);
  const { response, loading, error, reFetch } = useAxios({
    url:
      appStore.webapi +
      `xOrders.cshtml?` +
      `User=${appStore.user}&` +
      `Pass=${appStore.password}&` +
      `CstPointId=${appStore.cstPointId}`,
    method: 'GET',
    trigger: [],
    forceDispatchEffect: () => true,
  });
  const data = oc(response).data();
  useEffect(() => {
    if (error) {
      setMessage('Σφάλμα επικοινωνίας με τον Server!');
      setRecords([]);
    } else {
      if (!loading && !!data) {
        const resp = data as Order[];
        setMessage(resp[0].ErrMessage);
        if (resp[0].ErrResult === 0) {
          setRecords(resp);
        } else {
          setRecords([]);
        }
      }
    }
  }, [response, loading, error, data]);
  useWhyDidYouUpdate('[useOrdersQuery]', { loading, data, error });
  return {
    loading,
    error,
    message,
    records,
    reFetch,
  };
};
