import React, { FC } from 'react';
import { Spinner, Modal } from 'react-bootstrap';

// import { useWhyDidYouUpdate } from '../hooks';

type Props = {
  show: boolean;
};

const ModalSpinner: FC<Props> = ({ show }) => {
  /*-------------------- Body -----------------------------------------------*/
  /*-------------------- Handlers -------------------------------------------*/
  /*-------------------- Render ---------------------------------------------*/
  // useWhyDidYouUpdate('[ModalSpinner]', show);
  return (
    <Modal className="transparent-modal fade" show={show} size="sm" centered>
      <Modal.Body className="d-flex justify-content-center align-items-center">
        <Spinner
          style={{ width: '4rem', height: '4rem', borderWidth: '0.5rem' }}
          className="bigger-spinner"
          animation="border"
          variant="light"
        />
      </Modal.Body>
    </Modal>
  );
};

export default ModalSpinner;
