export class ROUTES {
  static About = '/about';
  static ChangePass = '/changePass';
  static ForgotPass = '/forgotPass';
  static Login = '/';
  static Select = '/select'; // Select Point
  static Logout = '/logout';
  static Orders = '/orders';
  static Orders2 = '/orders2';
  static PeriodReport = '/period-report';
  static Register = '/register';
  static Request = '/request';
  static Request2 = '/request2';
  static YearReport = '/year-report';
  static Agreement = '/agreement';
  static NewAgreement = '/new-agreement';
  static Certificate = '/certificate';
  static CstPoint = '/cstpoint';
  static Reward = '/reward';
}
