import React, { FC } from 'react';
import { Card, Button } from 'react-bootstrap';
import shortid from 'shortid';
import { RouteComponentProps } from 'react-router-dom';

import { useWhyDidYouUpdate } from '../../hooks';
import { useRootStore } from '../../store';

type Props = RouteComponentProps & {};
const SelectPointView: FC<Props> = props => {
  /*-------------------- Body -----------------------------------------------*/
  const { appStore } = useRootStore();

  /*-------------------- Handlers -------------------------------------------*/
  const handleClick = (data: number) => {
    appStore.selectPoint(data);
  };

  useWhyDidYouUpdate('[SelectPointView]', props);
  /*-------------------- Render ---------------------------------------------*/
  return (
    <Card className="bg-light shadow">
      <Card.Header>
        <Card.Title className="mb-0">Επιλογή Σημείου Συλλογής</Card.Title>
      </Card.Header>
      <Card.Body className="p-2 p-sm-4 transparent-card">
        {appStore.cstPoints.map(item => (
          <Button
            key={shortid.generate()}
            variant="light"
            className="p-0"
            block
            onClick={() => handleClick(item.CstPointId)}
          >
            <Card className="p-2">
              <Card.Body>
                <Card.Text>
                  <b>{item.CstPointName}</b>

                  <div style={{ paddingTop: '2px', color: '#6C6E70' }}>{item.CstPointAddress}</div>

                  <span style={{ color: '#6C6E70', fontSize: 'smaller' }}>
                    <i>{item.GroupName}</i>
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Button>
        ))}
      </Card.Body>
    </Card>
  );
};

export default SelectPointView;
