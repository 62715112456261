import { useState, useEffect } from 'react';
import useAxios from '@use-hooks/axios';
import { oc } from 'ts-optchain';
import { useRootStore } from '../store';
import { useSt8, useWhyDidYouUpdate } from '../hooks';
import { saveAs } from 'file-saver';
import { base64StringToBlob } from 'blob-util';
import { getType } from 'mime/lite';
/*
###
http://motion-2012:8085/webapi/YearCertificateALL.cshtml?CstPointId=10&Year=2017&Type=BLOB&User=h.sarris@gmail.com
###

###
http://motion-2012:8085/webapi/PeriodCertificateAll.cshtml?CstPointId=10&From=01/01/2014&To=01/01/2017&Type=BLOB&User=h.sarris@gmail.com
###

###
http://motion-2012:8085/webapi/OrderCertificateALL.cshtml?OrderId=3000&Type=BLOB&User=h.sarris@gmail.com
###
*/

export const useCertificateQuery = (type: 'BLOB' | 'EMAIL', pointId = 0) => {
  const { appStore } = useRootStore();
  const [message, setMessage] = useState('');
  const [load, setLoad] = useState(false);
  const [finish, setFinish] = useState(false);

  let url = appStore.webapi + `Certificate.cshtml?`;

  url = url + `CstPointId=${pointId}&`;

  url = url + `Type=${type}&User=${appStore.user}`;

  console.log(url);

  const { response, loading, error, reFetch } = useAxios({
    url,
    method: 'GET',
  });
  const data = oc(response).data();

  /* --Το effect τρεχει οταν αλλάζει το loading/error */
  useEffect(() => {
    if (loading) {
      setLoad(true);
      setFinish(false);
    }
    if (!loading && !!data && load) {
      setLoad(false);
      setMessage(data.ErrMessage);
      if (data.ErrResult === 0) {
        if (type === 'BLOB') {
          const type = getType('pdf');
          const blob = base64StringToBlob(data.Blob, type);
          const isWebView =
            navigator.userAgent.includes('wv') || navigator.userAgent.includes('EcopointWeb');
          if (isWebView) {
            window.postMessage('/webapi/PDF/' + data.Filename, '*');
          } else {
            saveAs(blob, data.Filename);
          }
        }
      }
      setFinish(true);
    }
  }, [loading, load, error, type, data]);

  useWhyDidYouUpdate('[useCertificateQuery]', { url, loading, data, error, load, finish });
  return {
    data,
    load,
    loading,
    busy: load || loading,
    finish,
    error,
    reFetch,
  };
};
