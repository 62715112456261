export enum AgreementTypes {
  Shops_0 = 1,
  Shops_Sept_2022 = 2,
  Shops_Sept_2023 = 3,
}

export enum CstPointTypes {
  Shops = 0,
  OthersNoDialitirio = 1,
  Dialitirio = 2,
}

export enum AgreementsIds {
  Shops2021 = 1,
  Shops2022 = 2,
  Shops2023 = 3,
  Dialitiria = 4,
  Stolos = 5,
  Kdai = 6,
  Municipalities = 7,
  Apovlita = 8,
  Anagomosi = 9,
  UsedTires = 10,
}
