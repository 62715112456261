import { useState, useEffect } from 'react';
import useAxios from '@use-hooks/axios';
import { oc } from 'ts-optchain';
import { useRootStore } from '../store';
import { useWhyDidYouUpdate } from '../hooks';

/*
###
GET http://motion-2012:8085/webapi/xOrders2.cshtml?User=gtseliou@anamet.gr&Pass=motion$hellas&CstPointId=1
###
*/

export interface OrderW {
  ColDate: string;
  Col_DocId: string;
  CollectorName: string;
  CstPointId: string;
  ErrMessage: string;
  ErrResult: number;
  Notes: string;
  OrderId: number;
  RecNo: string;
  ReqDate: string;
  Status: string;
  Weight: string;
}

export const useOrders2Query = () => {
  const { appStore } = useRootStore();
  const [message, setMessage] = useState('');
  const [records, setRecords] = useState([] as OrderW[]);
  const { response, loading, error, reFetch } = useAxios({
    url:
      appStore.webapi +
      `xOrders2.cshtml?` +
      `User=${appStore.user}&` +
      `Pass=${appStore.password}&` +
      `CstPointId=${appStore.cstPointId}`,
    method: 'GET',
    trigger: [],
    forceDispatchEffect: () => true,
  });
  const data = oc(response).data();
  useEffect(() => {
    if (error) {
      setMessage('Σφάλμα επικοινωνίας με τον Server!');
      setRecords([]);
    } else {
      if (!loading && !!data) {
        const resp = data as OrderW[];
        setMessage(resp[0].ErrMessage);
        if (resp[0].ErrResult === 0) {
          setRecords(resp);
        } else {
          setRecords([]);
        }
      }
    }
  }, [response, loading, error, data]);
  useWhyDidYouUpdate('[useOrders2Query]', { loading, data, error });
  return {
    loading,
    error,
    message,
    records,
    reFetch,
  };
};
