import { useState, useEffect } from 'react';
import useAxios from '@use-hooks/axios';
import { oc } from 'ts-optchain';
import { useRootStore } from '../store';
import { useWhyDidYouUpdate } from '../hooks';

/*
###
GET http://192.168.160.203:8085/webapi/xOrders.cshtml?User=bikenrider@gmail.com&Pass=motion$hellas&CstPointId=2481
###
*/

export interface GeocodeReverse {
  lat: number;
  lng: number
}

export const useGeocodeReverseQuery = (variables) => {
  const { appStore } = useRootStore();
  const [message, setMessage] = useState('');
  const [records, setRecords] = useState<any>();
  const url = `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDTPYVZ76DV-eairJCtDjYAIyQp5rYcG_w` +
    `&latlng=${variables.lat},${variables.lng}`
    + `&language=el`;
  const { response, loading, error, reFetch } = useAxios({
    url: url,
    method: 'GET',
  });
  const data = oc(response).data();
  useEffect(() => {
    console.log('url=', url);
    if (error) {
      setMessage('Σφάλμα επικοινωνίας με τον Server!');
    } else {
      if (!loading && !!data && data.results.length > 0) {
        const resp = data.results[0].address_components as any;
        let address;
        let zipCode;
        let streetNumber;
        resp.map((row) => {
          if (row.types[0] === "street_number") streetNumber = row.long_name;
          else if (row.types[0] === 'route') address = row.long_name;
          else if (row.types[0] === 'postal_code') zipCode = row.long_name;
        })
        address = address + " " + streetNumber;

          setRecords({address: address, zipCode: zipCode});
      }
    }
  }, [response, loading, error, data]);

  useWhyDidYouUpdate('[useGeocodeReverseQuery]', { loading, data, error });
  return {
    loading,
    error,
    message,
    records,
    reFetch,
  };
};
