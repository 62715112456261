import { useState, useEffect } from 'react';
import useAxios from '@use-hooks/axios';
import { oc } from 'ts-optchain';
import { useRootStore } from '../store';
import { useWhyDidYouUpdate } from '../hooks';

/*
###
GET http://192.168.160.203:8085/webapi/xOrders.cshtml?User=bikenrider@gmail.com&Pass=motion$hellas&CstPointId=2481
###
*/

export interface Agreement {
  HasConsented: boolean;
  ConsentDate: string;
  NewAddress: string;
  NewMunicipality: string;
  NewNomos: string;
  NewDistrict: string;
  NewZipCode: string;
  NewPhone1: string;
  NewPhone2: string;
  NewPhone3: string;
  NewFax1: string;
  NewFax2: string;
  NewEmail: string;
  NewContact: string;
  NewContactPosition: string;
  NewAddressLatitude: number;
  NewAddressLongitude: number;
  DataChanged: boolean;
  AgreementId: number;
  PdfFileName: string;
  InUsePdfFileName: string;
}

export const useAgreementQuery = () => {
  const { appStore } = useRootStore();
  const [message, setMessage] = useState('');
  const [records, setRecords] = useState<Agreement>();
  const url =
    appStore.webapi +
    `xGetAgreement.cshtml?` +
    `User=${appStore.user}` +
    `&Pass=${appStore.password}` +
    `&CstPointId=${appStore.cstPointId}`;
  const { response, loading, error, reFetch } = useAxios({
    url: url,
    method: 'GET',
  });
  const data = oc(response).data();
  useEffect(() => {
    if (error) {
      setMessage('Σφάλμα επικοινωνίας με τον Server!');
    } else {
      if (!loading && !!data) {
        const resp = data as Agreement;
        setRecords(resp);
      }
    }
  }, [response, loading, error, data]);

  useEffect(() => {
    reFetch();
  }, []);
  useWhyDidYouUpdate('[useAgreementQuery]', { loading, data, error });
  return {
    loading,
    error,
    message,
    records,
    reFetch,
  };
};
