import React, { FC, useState } from 'react';
import { Form, Button, Card, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import * as yup from 'yup';
import useForm from 'react-hook-form';

// import { useWhyDidYouUpdate, useLogger } from '../../hooks';
import { OrderRequest } from '../../models';
import { TyreLimits } from './tyre-limits';

function testValue(min: number, max: number): any {
  return value => {
    let error = '';
    if (min && value < min) {
      error += `Αποδεκτή ποσότητα >= ${min}`;
    }
    if (max && value > max) {
      error += `Αποδεκτή ποσότητα <= ${max}`;
    }
    console.log('#####', value, error);
    if (error !== '') return false;
    else return true;
  };
}

function testMessage(min: number, max: number): any {
  return ctx => {
    let error = '';
    if (min && ctx.value < min) {
      error += `Αποδεκτή ποσότητα >= ${min}`;
    }
    if (max && ctx.value > max) {
      error += `Αποδεκτή ποσότητα <= ${max}`;
    }
    if (error !== '') return error;
    else return undefined;
  };
}

export type OrderFormData = {
  OrderId: number;
  Tyre1: string;
  Tyre2: string;
  Tyre3: string;
  Tyre4: string;
  Tyre5: string;
  Notes: string;
};

type Props = {
  order: OrderRequest;
  onSubmit: (data: OrderFormData) => void;
  notQ4?: boolean;
  notQ1235?: boolean;
  tyreLimits: TyreLimits;
};

//--------------------------------------------------------------------------------------------
// Component
//--------------------------------------------------------------------------------------------
const RequestCard: FC<Props> = ({
  order,
  onSubmit,
  notQ4 = false,
  notQ1235 = false,
  tyreLimits,
}) => {
  //useLogger('[RequestCard]', order.CstPointStatus, order.OrderId);

  /**
   * Setup Yup Validation & Type
   */
  const validationSchema = yup.object().shape({
    OrderId: yup.number(),
    Tyre1: yup.number().test({
      test: testValue(0, tyreLimits.max1),
      message: testMessage(0, tyreLimits.max1),
    }),
    Tyre2: yup
      .number()
      .test({ test: testValue(0, tyreLimits.max2), message: testMessage(0, tyreLimits.max2) }),
    Tyre3: yup
      .number()
      .test({ test: testValue(0, tyreLimits.max3), message: testMessage(0, tyreLimits.max3) }),
    Tyre4: yup
      .number()
      .test({ test: testValue(0, tyreLimits.max4), message: testMessage(0, tyreLimits.max4) }),
    Tyre5: yup
      .number()
      .test({ test: testValue(0, tyreLimits.max5), message: testMessage(0, tyreLimits.max5) }),
    Notes: yup.string(),
  });

  /*-------------------- Body -----------------------------------------------*/
  const newOrder = order.CstPointStatus > 0 || order.OrderId === 0;
  const [showAlert, setShowAlert] = useState(true);

  const initialValues = {
    OrderId: newOrder ? 0 : order.OrderId,
    Tyre1: newOrder ? '0' : order.Tyre1,
    Tyre2: newOrder ? '0' : order.Tyre2,
    Tyre3: newOrder ? '0' : order.Tyre3,
    Tyre4: newOrder ? '0' : order.Tyre4,
    Tyre5: newOrder ? '0' : order.Tyre5,
    Notes: newOrder ? '' : order.Notes,
  };
  console.log('$$$ order', order, initialValues);

  const textButton = newOrder ? 'ΑΠΟΣΤΟΛΗ ΑΙΤΗΣΗΣ' : 'ΔΙΟΡΘΩΣΗ ΑΙΤΗΣΗΣ';
  const alertHeader = newOrder ? 'Nέα Αίτηση' : 'Αίτηση σε αναμονή';
  const alertBody = newOrder
    ? 'Προχωρήστε στην αποστολή νέας αίτησης'
    : `Υπάρχει αίτηση σε αναμονή από ${order.Dates}, τροποποιήστε την αίτηση με νέες ποσότητες`;
  const alertColor = newOrder ? 'success' : 'danger';

  const { register, handleSubmit, formState, errors, watch } = useForm<OrderFormData>({
    defaultValues: initialValues,
    validationSchema: validationSchema,
    mode: 'onBlur',
  });

  const watch1 = watch('Tyre1');
  const watch2 = watch('Tyre2');
  const watch3 = watch('Tyre3');
  const watch4 = watch('Tyre4');
  const watch5 = watch('Tyre5');
  const reqMinimum =
    (tyreLimits.min1 > 0 && parseInt(watch1) >= tyreLimits.min1) ||
    (tyreLimits.min2 > 0 && parseInt(watch2) >= tyreLimits.min2) ||
    (tyreLimits.min3 > 0 && parseInt(watch3) >= tyreLimits.min3) ||
    (tyreLimits.min4 > 0 && parseInt(watch4) >= tyreLimits.min4) ||
    (tyreLimits.min5 > 0 && parseInt(watch5) >= tyreLimits.min5);

  const canSubmit = formState.dirty && formState.isValid && reqMinimum;
  console.log('$$$ formstate', formState, order);

  const canEdit1235 = (newOrder && !notQ1235) || (!newOrder && order.Tyre4 === '0');
  const canEdit4 = (newOrder && !notQ4) || (!newOrder && order.Tyre4 !== '0');
  console.log(reqMinimum);
  /*-------------------- Handlers -------------------------------------------*/
  const handleOnSubmit = (data: OrderFormData) => {
    console.log('$$$ Submit:', data, order);
    onSubmit({ ...data, OrderId: order.OrderId });
  };

  const handleAlertDesmiss = () => {
    setShowAlert(false);
  };

  /*-------------------- Render ---------------------------------------------*/
  // useWhyDidYouUpdate('[RequestCard]', { order, formState, errors, sum });
  return (
    <Card className="bg-light shadow">
      <Card.Header>
        <Card.Title className="mb-0">Αίτηση Συλλογής Ελαστικών</Card.Title>
      </Card.Header>

      <Form onSubmit={handleSubmit(handleOnSubmit)}>
        <Card.Body className="p-2 p-sm-4">
          {showAlert && (
            <Alert variant={alertColor} onClose={handleAlertDesmiss} dismissible>
              <Alert.Heading>{alertHeader}</Alert.Heading>
              <p>{alertBody}</p>
            </Alert>
          )}
          <div className="is-invalid text-danger my-3">
            {!reqMinimum && formState.dirty && (
              <div>
                <strong>ΠΡΟΣΟΧΗ:</strong>
                Τουλάχιστον μια κατηγορία θα πρέπει να περιέχει τον ελάχιστο απαιτούμενο αριθμό
                ελαστικών
              </div>
            )}
          </div>
          {/* Κατηγορία A ********************************************************************* */}
          <Form.Group
            className="mb-1 mb-sm-2"
            controlId="formTyre1"
            style={{ display: !canEdit1235 || !tyreLimits.show1 ? 'none' : null }}
          >
            <Form.Label className="mb-1 mb-sm-2">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-small`}>
                    <strong>Κατηγορία A (Μέσο Βάρος 8 kg)</strong>
                    <ul>
                      <li>Εξωτερική διάμετρος έως 1400mm</li>
                      <li>Ελαστικά επιβατικών αυτοκινήτων (επιβατικά λάστιχα και λάστιχα 4X4).</li>
                      <li>
                        Ελαστικά εμπορικών οχημάτων με ονομαστική (εσωτερική) διάμετρο ζάντας
                        μικρότερη από 17,5 ίντσες.
                      </li>
                      <li>
                        Ελαστικά αγροτικών τρακτέρ συμβατικά (diagonal) για μπροστινούς τροχούς.
                      </li>
                      <li>
                        Ελαστικά βιομηχανικών και ανυψωτικών οχημάτων (πνευματικά) με ονομαστική
                        (εσωτερική) διάμετρο ζάντας μικρότερη ή ίση με 12 ίντσες.
                      </li>
                    </ul>
                    <strong>ελάχιστη ποσότητα: {tyreLimits.min1}</strong>
                  </Tooltip>
                }
              >
                <FaInfoCircle size="2em" className="text-info pr-1" />
              </OverlayTrigger>
              Κατηγορία Α
            </Form.Label>

            <Form.Control
              name="Tyre1"
              type="number"
              min={0}
              ref={register}
              isInvalid={'Tyre1' in errors}
              disabled={!canEdit1235}
              autoFocus
            />

            <Form.Control.Feedback type="invalid">
              {errors && errors['Tyre1'] ? errors['Tyre1'].message : ''}
              {/* {'Αποδεκτή ποσότητα <= 999'} */}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Κατηγορία Β ********************************************************************* */}
          <Form.Group
            className="mb-1 mb-sm-2"
            controlId="formTyre2"
            style={{ display: !canEdit1235 || !tyreLimits.show2 ? 'none' : null }}
          >
            <Form.Label className="mb-1 mb-sm-2">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-big`}>
                    <strong>Κατηγορία B (Μέσο Βάρος 50 kg)</strong>
                    <ul>
                      <li>Εξωτερική διάμετρος έως 1400mm</li>
                      <li>
                        Ελαστικά εμπορικών οχημάτων με ονομαστική (εσωτερική) διάμετρο ζάντας
                        μεγαλύτερη ή ίση με 17,5 ίντσες.
                      </li>
                      <li>
                        Ελαστικά αγροτικών τρακτέρ για οπίσθιους τροχούς κίνησης με εξωτερική
                        διάμετρο έως 1400mm.
                      </li>
                      <li>
                        Ελαστικά βιομηχανικών οχημάτων (πνευματικά-industrial) με ονομαστική
                        (εσωτερική) διάμετρο ζάντας μεγαλύτερη από 12 ίντσες και με εξωτερική
                        διάμετρο έως 1400mm.
                      </li>
                    </ul>
                    <strong>ελάχιστη ποσότητα: {tyreLimits.min2}</strong>
                  </Tooltip>
                }
              >
                <FaInfoCircle size="2em" className="text-info pr-1" />
              </OverlayTrigger>
              Κατηγορία Β
            </Form.Label>
            <Form.Control
              name="Tyre2"
              type="number"
              min={0}
              ref={register}
              isInvalid={'Tyre2' in errors}
              disabled={!canEdit1235}
            />
            <Form.Control.Feedback type="invalid">
              {errors && errors['Tyre2'] ? errors['Tyre2'].message : ''}
              {/* {'Αποδεκτή ποσότητα <=500'} */}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Κατηγορία Γ ********************************************************************* */}
          <Form.Group
            className="mb-1 mb-sm-2"
            controlId="formTyre3"
            style={{ display: !canEdit1235 || !tyreLimits.show3 ? 'none' : null }}
          >
            <Form.Label className="mb-1 mb-sm-2">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-moto`}>
                    <strong>Κατηγορία Γ (Μέσο Βάρος 4 kg)</strong>
                    <ul>
                      <li>Εξωτερική διάμετρος έως 1400mm</li>
                      <li>Ελαστικά moto.</li>
                    </ul>
                    <strong>ελάχιστη ποσότητα: {tyreLimits.min3}</strong>
                  </Tooltip>
                }
              >
                <FaInfoCircle size="2em" className="text-info pr-1" />
              </OverlayTrigger>
              Κατηγορία Γ
            </Form.Label>
            <Form.Control
              name="Tyre3"
              type="number"
              min={0}
              ref={register}
              isInvalid={'Tyre3' in errors}
              disabled={!canEdit1235}
            />
            <Form.Control.Feedback type="invalid">
              {errors && errors['Tyre3'] ? errors['Tyre3'].message : ''}
              {/* {'Αποδεκτή ποσότητα <=500'} */}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Κατηγορία Δ1 - 4 ********************************************************************* */}

          <Form.Group
            className="mb-1 mb-sm-2"
            controlId="formTyre4"
            style={{ display: !canEdit4 || !tyreLimits.show4 ? 'none' : null }}
          >
            <Form.Label className="mb-1 mb-sm-2">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-tyre4`}>
                    <strong>Κατηγορία Δ1 (Μέσο Βάρος 150 kg)</strong>
                    <ul>
                      <li>Εξωτερική διάμετρος έως 1600mm</li>
                      <li>
                        Ελαστικά χωματουργικών οχημάτων και οχημάτων έργου (Grader, Loader, RDT,
                        ADT, Crane, Mechanical Handling)
                      </li>
                    </ul>
                    {tyreLimits.min4 > 0 && <strong>ελάχιστη ποσότητα: {tyreLimits.min4}</strong>}
                  </Tooltip>
                }
              >
                <FaInfoCircle size="2em" className="text-info pr-1" />
              </OverlayTrigger>
              Κατηγορία Δ1
            </Form.Label>
            <Form.Control
              name="Tyre4"
              type="number"
              min={0}
              ref={register}
              isInvalid={'Tyre4' in errors}
              disabled={!canEdit4}
            />
            <Form.Control.Feedback type="invalid">
              {errors && errors['Tyre4'] ? errors['Tyre4'].message : ''}
              {/* {'Αποδεκτή ποσότητα <=500'} */}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Κατηγορία Δ2 - 5 ********************************************************************* */}
          <Form.Group
            className="mb-1 mb-sm-2"
            controlId="formTyre5"
            style={{ display: !canEdit1235 || !tyreLimits.show5 ? 'none' : null }}
          >
            <Form.Label className="mb-1 mb-sm-2">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-moto`}>
                    <strong>Κατηγορία Δ2 (Μέσο Βάρος 100 kg)</strong>
                    <ul>
                      <li>Εξωτερική διάμετρος από 1400mm έως 1600mm</li>
                      <li>
                        Ελαστικά αγροτικών οχημάτων με εξωτερική διάμετρο από 1400mm έως 1600mm.
                      </li>
                      <li>
                        Ελαστικά βιομηχανικών οχημάτων (πνευματικά-industrial) με εξωτερική διάμετρο
                        από 1400mm έως 1600mm.
                      </li>
                    </ul>
                    {tyreLimits.min5 > 0 && <strong>ελάχιστη ποσότητα: {tyreLimits.min5}</strong>}
                  </Tooltip>
                }
              >
                <FaInfoCircle size="2em" className="text-info pr-1" />
              </OverlayTrigger>
              Κατηγορία Δ2
            </Form.Label>
            <Form.Control
              name="Tyre5"
              type="number"
              min={0}
              ref={register}
              isInvalid={'Tyre5' in errors}
              disabled={!canEdit1235}
            />
            <Form.Control.Feedback type="invalid">
              {errors && errors['Tyre5'] ? errors['Tyre5'].message : ''}
              {/* {'Αποδεκτή ποσότητα <=500'} */}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formNotes">
            <Form.Label className="mb-1 mb-sm-2">Σημειώσεις</Form.Label>
            <Form.Control name="Notes" type="text" ref={register} />
          </Form.Group>
        </Card.Body>
        <Card.Footer>
          <Button block variant="primary" type="submit" disabled={!canSubmit}>
            <span>{textButton}</span>
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
};

export default RequestCard;
