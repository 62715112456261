import { useState, useEffect } from 'react';
import useAxios from '@use-hooks/axios';
import { oc } from 'ts-optchain';
import { useRootStore } from '../store';
import { useWhyDidYouUpdate } from '../hooks';

/*
###
GET https://ecopointweb.dyndns.org/webapi/xGetCstPointAgreements.cshtml?User=h.sarris@gmail.com&Pass=motion$hellas&CstPointId=4082
###
*/

export interface Agreements {
  HasConsented: boolean;
  ConsentDate: string;
  NewAddress: string;
  NewMunicipality: string;
  NewNomos: string;
  NewDistrict: string;
  NewZipCode: string;
  NewPhone1: string;
  NewPhone2: string;
  NewPhone3: string;
  NewFax1: string;
  NewFax2: string;
  NewEmail: string;
  NewContact: string;
  NewContactPosition: string;
  NewAddressLatitude: number;
  NewAddressLongitude: number;
  DataChanged: boolean;
  AgreementId: number;
  AgreementName: string;
  IsTerminated: boolean;
  UserProfile_EMail: string;
  TerminatedDate: string;
  IID: number;
}

export const useAgreementsQuery = () => {
  const { appStore } = useRootStore();
  const [message, setMessage] = useState('');
  const [records, setRecords] = useState<[Agreements]>();
  const url =
    appStore.webapi +
    `xGetCstPointAgreements.cshtml?` +
    `User=${appStore.user}` +
    `&Pass=${appStore.password}` +
    `&CstPointId=${appStore.cstPointId}`;
  const { response, loading, error, reFetch } = useAxios({
    url: url,
    method: 'GET',
  });
  const data = oc(response).data();
  useEffect(() => {
    if (error) {
      setMessage('Σφάλμα επικοινωνίας με τον Server!');
    } else {
      if (!loading && !!data) {
        const resp = data as [Agreements];
        setRecords(resp);
      }
    }
  }, [response, loading, error, data]);

  useEffect(() => {
    reFetch();
  }, []);
  useWhyDidYouUpdate('[useAgreementsQuery]', { loading, data, error });
  return {
    loading,
    error,
    message,
    records,
    reFetch,
  };
};
