import { Log } from './log';

//* Export Factories
//*
export const log = Log();

//* Export Classes
//*
export class Services {}

export const service = new Services();
