import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useRootStore } from '../../store';
import { AgreementsIds } from '../../common/constants';

const iCertifyString0 =
  'Βεβαιώνω ότι η παραπάνω εταιρεία δεν είναι υπόχρεος Παραγωγός κατά την έννοια της νομοθεσίας (άρθρο 2 παρ. 4 ΚΥΑ ΥΠΕΝ/ΔΔ Α/36464/547, ΦΕΚ Β 2217/6.4.2023) δηλαδή δεν είναι α) εισαγωγέας ελαστικών ως αυτοτελή προϊόντα β) εισαγωγέας ελαστικών που αποτελούν μέρος οχημάτων γ) εισαγωγέας μεταχειρισμένων ελαστικών δ) εισαγωγέας μεταχειρισμένων οχημάτων. Σε περίπτωση που στο μέλλον η εκπροσωπούμενη από εμένα εταιρεία ασκήσει οποιαδήποτε από τις παραπάνω αναφερόμενες δραστηριότητες (α έως δ) υποχρεούται να απευθύνει σχετική ενημέρωση στο ΣΣΕΔ ECOELASTIKA και να συνάψει σύμβαση συνεργασίας υπόχρεου Παραγωγού.';
const iCertifyString1 =
  'Βεβαιώνω ότι η παραπάνω εταιρεία δεν πραγματοποιεί εισαγωγές οχημάτων ή μερών οχημάτων τέλους κύκλου ζωής για διάλυση. Σε περίπτωση που η εκπροσωπούμενη από εμένα εταιρεία ασκήσει στο μέλλον οποιαδήποτε από τις παραπάνω αναφερόμενες δραστηριότητες, υποχρεούται να παραδώσει τα ελαστικά τέλους κύκλου ζωής που θα προκύψουν από την διάλυση των οχημάτων σε αδειοδοτημένους τελικούς αποδέκτες με δικά της μέσα και έξοδα.';

const iTyreImporterText = `Κάνω εισαγωγές ελαστικών, νέων ή/και μεταχειρισμένων (είτε πρόκειται για αυτοτελή προϊόντα
  είτε για μέρη οχημάτων που διατίθενται για πρώτη φορά στην Ελληνική αγορά).`;

export const IsTyreImporter = ({
  setFieldValue,
  getFieldsValues,
  setCanConsent,
  setConsented,
  register,
  tyreImporterText = iTyreImporterText,
}) => {
  const { appStore } = useRootStore();
  const [isTyreImporter, setIsTyreImporter] = useState<boolean>();

  const calcCanConsent = (vIsTyreImporter: boolean, vCertifyNoTyreImport: boolean) => {
    let value = false;
    if (vIsTyreImporter) value = true;
    else {
      if (vCertifyNoTyreImport) value = true;
      else value = false;
    }

    setCanConsent(value);
    if (!value) setConsented(false);
  };
  const handleIsTyreImporterChange = e => {
    e.persist();

    const valueIsTyreImporter = e.target.value === 'true' ? true : false;
    setIsTyreImporter(valueIsTyreImporter);
    setFieldValue('IsTyreImporter', valueIsTyreImporter);

    const values = getFieldsValues();
    calcCanConsent(valueIsTyreImporter, values.CertifyNoTyreImport);
  };

  return (
    <div style={{ textAlign: 'start', marginBottom: '24px' }}>
      <Form.Group className="mb-0 mt-2" controlId="IsTyreImporter">
        <Form.Label>{tyreImporterText}</Form.Label>
        <Form.Check
          ref={register}
          name="IsTyreImporter"
          type="radio"
          label="Ναί"
          value={'true'}
          defaultChecked={false}
          onChange={handleIsTyreImporterChange}
          checked={isTyreImporter === true}
        />
        <Form.Check
          ref={register}
          name="IsTyreImporter"
          type="radio"
          label="Όχι"
          value={'false'}
          defaultChecked={false}
          onChange={handleIsTyreImporterChange}
          checked={isTyreImporter == false}
          required
        />
      </Form.Group>

      <Form.Group
        className="mb-0 mt-2"
        controlId="CertifyNoTyreImport"
        style={{ display: isTyreImporter === false ? 'block' : 'none' }}
      >
        <Form.Check
          ref={register}
          type="checkbox"
          name="CertifyNoTyreImport"
          label={
            appStore.inUseAgreementId === AgreementsIds.Dialitiria
              ? iCertifyString1
              : iCertifyString0
          }
          defaultChecked={false}
          onChange={e => {
            e.persist();
            const valueCertifyNoTyreImport = e.target.checked ? true : false;
            setFieldValue('CertifyNoTyreImport', valueCertifyNoTyreImport);

            const values = getFieldsValues();
            const valueIsTyreImporter = values.CertifyNoTyreImport === 'true' ? true : false;
            calcCanConsent(valueIsTyreImporter, valueCertifyNoTyreImport);
          }}
        />
      </Form.Group>
    </div>
  );
};
