import React, { FC, useState } from 'react';
import { Button, Card, Accordion } from 'react-bootstrap';
import { FaSync } from 'react-icons/fa';
import shortid from 'shortid';

import { useWhyDidYouUpdate, useLogger } from '../../hooks';

import { Order } from '../../api';
import OrderItem from './order-item';

type Props = {
  orders: Order[];
  recNo: number;
  onClickRefresh: () => void;
  onReportRequest: (orderId: number, email: boolean) => void;
};

const OrdersCard: FC<Props> = ({ orders, recNo, onClickRefresh, onReportRequest }) => {
  /*-------------------- Body -----------------------------------------------*/
  const [collapsedId, setCollapsedId] = useState('0'); // Collapse handling of Accordion keep the open state
  /*-------------------- Handlers -------------------------------------------*/
  const handleItemClick = (id: string) => {
    setCollapsedId(collapsedId !== id ? id : '0');
  };

  const handleRefreshClick = () => {
    setCollapsedId('0');
    onClickRefresh();
  };

  const handleReportRequest = (orderId: number, email: boolean) => {
    console.log(orderId, email);
    onReportRequest(orderId, email);
  };
  /*-------------------- Render ---------------------------------------------*/
  useLogger('[OrdersCard]');
  useWhyDidYouUpdate('[OrdersCard]', { orders, recNo, collapsedId });
  return (
    <>
      <Card className="bg-light shadow">
        <Card.Header>
          <Card.Title className="mb-0 d-flex flex-row justify-content-between align-items-center">
            <span>Ιστορικό Αιτήσεων</span>
            <div>
              <Button variant="primary" onClick={handleRefreshClick}>
                <FaSync />
              </Button>
            </div>
          </Card.Title>
        </Card.Header>
        <Card.Body className="p-2 p-sm-4">
          <Accordion activeKey={collapsedId}>
            {orders.map((item, index) => (
              <OrderItem
                key={shortid.generate()}
                keyValue={(index + 1).toString()}
                order={item}
                onClick={handleItemClick}
                onSubmit={handleReportRequest}
                collapsedId={collapsedId}
              />
            ))}
          </Accordion>
        </Card.Body>
        <Card.Footer>{'Εγγραφές:' + recNo}</Card.Footer>
      </Card>
    </>
  );
};

export default OrdersCard;
