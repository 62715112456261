import React, { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useWhyDidYouUpdate } from '../../hooks';
import { useOrdersQuery, useReportQuery } from '../../api';
import ModalSpinner from '../../components/modal-spinner';
import ModalDialog from '../../components/modal-dialog';
import OrdersCard from './orders-card';

type Props = RouteComponentProps & {};

const OrdersView: FC<Props> = props => {
  /*-------------------- Body -----------------------------------------------*/
  const [orderId, setOrderId] = useState(0);
  const [emailChecked, setEmailChecked] = useState(false);

  // -- For report QUERY hook
  const type = emailChecked ? 'EMAIL' : 'BLOB';
  const { busy, finish, reFetch: reFetchReport } = useReportQuery('Order', type, orderId);
  const { loading, error, records, reFetch } = useOrdersQuery();
  const recordsNo = records.length > 0 ? parseInt(records[0].RecNo) : 0;

  /*-------------------- Handlers -------------------------------------------*/
  const handleRefresh = () => {
    reFetch();
  };

  const handleReportRequest = (_orderId: number, _email: boolean) => {
    setOrderId(_orderId);
    setEmailChecked(_email);
    reFetchReport();
  };
  useWhyDidYouUpdate('[OrdersView]', { records, loading, error });
  /*-------------------- Render ---------------------------------------------*/
  return (
    <>
      {recordsNo > 0 && (
        <OrdersCard
          orders={records}
          recNo={recordsNo}
          onClickRefresh={handleRefresh}
          onReportRequest={handleReportRequest}
        />
      )}
      <ModalSpinner show={loading || busy}></ModalSpinner>
      <ModalDialog title={'Η Λήψη του Δελτίου ολοκληρώθηκε'} trigger={finish} onClick={() => {}} />
    </>
  );
};
export default OrdersView;
