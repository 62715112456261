import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useAxios from '@use-hooks/axios';
import { oc } from 'ts-optchain';

import { useWhyDidYouUpdate, useSt8 } from '../../hooks';
import { useRootStore } from '../../store';

import { OrderWRequest } from '../../models';

import RequestWCard, { OrderWFormData } from './request-card';
import ModalSpinner from '../../components/modal-spinner';
import ModalReturn from '../../components/modal-dialog';
import { ROUTES } from '../../routes';
import ModalDialogFull from '../../components/modal-dialog-full';

/*

    Νέα Αίτηση για Κατόχους και Διαλυτήρια

*/
type Props = RouteComponentProps & {};
const RequestWView: FC<Props> = props => {
  /*-------------------- Body -----------------------------------------------*/
  const { appStore } = useRootStore();
  const [isActive, setIsActive] = useState(true);
  const [modalText, setModalText] = useState('Παρακαλώ υπογράψτε τους όρους συνεργασίας');
  const finishPre = useSt8(false);
  const finish = useSt8(false);
  const order = useSt8(({} as any) as OrderWRequest);
  const orderFormData = useSt8(({
    Weight: '0',
    Notes: '',
  } as any) as OrderWFormData);

  const cstPointId = appStore.cstPointId;
  const user = appStore.user;
  const password = appStore.password;

  // ---------------------------  preRequest Hook
  const urlPre =
    appStore.webapi +
    `xPreRequest2.cshtml?User=${user}` +
    `&Pass=${password}` +
    `&CstPointId=${cstPointId}`;

  const { response: responsePre, loading: loadingPre, error: errorPre } = useAxios({
    url: urlPre,
    method: 'GET',
    trigger: [],
  });
  const dataPre = oc(responsePre).data(false);

  useEffect(() => {
    if (errorPre) {
      console.log('Error!');
    } else {
      if (dataPre !== false) {
        console.log(JSON.parse(dataPre));
        const orderObj: OrderWRequest = JSON.parse(dataPre);
        switch (orderObj.ErrResult) {
          case 0: // Normal order
            order(orderObj);
            finishPre(true);
            break;
          case 4: // No orders
            order({ ...orderObj, OrderId: 0 });
            finishPre(true);
            break;
          case 3: // CstPoint not active
            setIsActive(false);
            setModalText(
              appStore.hasConsented
                ? 'Παρακαλώ επικοινωνήστε με ECOELASTIKA (κος Τατσιόπουλος 210 61.28.260) για την ενεργοποίηση του λογαριασμού σας'
                : 'Παρακαλώ υπογράψτε τους όρους συνεργασίας και επικοινωνήστε με ECOELASTIKA (κος Τατσιόπουλος 210 61.28.260) για την ενεργοποίηση του λογαριασμού σας',
            );
            finishPre(true);
            break;
          default:
            console.log(orderObj.ErrMessage);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPre, errorPre]);

  useWhyDidYouUpdate('[RequestWView:1]', { dataPre, loadingPre, errorPre, finishPre, order });

  // ---------------------  Request Edit/New Order Hook
  const url =
    appStore.webapi +
    `xRequest2.cshtml?User=${user}` +
    `&Pass=${password}` +
    `&CstPointId=${cstPointId}` +
    `&ReqWeight=${orderFormData().Weight}` +
    `&ReqNotes=${orderFormData().Notes}`;

  const { response, loading, error, reFetch } = useAxios({ url, method: 'GET' });
  const data = oc(response).data(false);

  useEffect(() => {
    if (data !== false) {
      console.log(JSON.parse(data));
      finish(true);
    }
  }, [data, finish]);

  useWhyDidYouUpdate('[RequestWView:2]', { data, loading, error });

  /*-------------------- Handlers -------------------------------------------*/
  const handleSubmit = formData => {
    orderFormData(formData);
    reFetch();
  };

  const handleEndClick = () => {
    props.history.push(ROUTES.Orders2);
  };

  /*-------------------- Render ---------------------------------------------*/
  return (
    <>
      {finishPre() && (
        <>
          {!isActive /* || !appStore.hasConsented  */ ? (
            <ModalDialogFull
              title={'Δεν έχετε δικαίωμα για νέα αίτηση'}
              text={modalText}
              onClick={() => props.history.push(ROUTES.CstPoint)}
            />
          ) : (
            <>
              {appStore.firstMustConsent && (
                <ModalDialogFull
                  title={'Δεν έχετε δικαίωμα για νέα αίτηση'}
                  text={'Παρακαλούμε αποδεχτείτε τους όρους συνεργασίας πρώτα'}
                  onClick={() => props.history.push(ROUTES.Agreement)}
                />
              )}
              <RequestWCard order={order()} onSubmit={handleSubmit} />
            </>
          )}
        </>
      )}
      <ModalSpinner show={loadingPre || loading}></ModalSpinner>
      <ModalReturn title={'Η ΑΙΤΗΣΗ ΚΑΤΑΧΩΡΗΘΗΚΕ'} trigger={finish()} onClick={handleEndClick} />
    </>
  );
};

export default RequestWView;
