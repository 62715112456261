import React, { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useWhyDidYouUpdate } from '../../hooks';
import { useReportQuery } from '../../api';
import { useRootStore } from '../../store';

import YearReportCard from './year-report-card';
import ModalDialog from '../../components/modal-dialog';
import ModalSpinner from '../../components/modal-spinner';

type Props = RouteComponentProps & {};

const YearReportView: FC<Props> = props => {
  /*-------------------- Body -----------------------------------------------*/
  const { appStore } = useRootStore();
  const [year, setYear] = useState('');
  const [emailChecked, setEmailChecked] = useState('');

  // local variables
  const cstPointId = appStore.cstPointId;
  const type = emailChecked ? 'EMAIL' : 'BLOB';
  const { busy, finish, reFetch: reFetchReport } = useReportQuery(
    'Year',
    type,
    0,
    cstPointId,
    year,
  );
  /*-------------------- Handlers -------------------------------------------*/
  const handleSubmit = (_year: string, _email) => {
    console.log('Submit YEAR:', _year, ' Email', _email);
    setYear(_year);
    setEmailChecked(_email);
    reFetchReport();
  };

  useWhyDidYouUpdate('[YearReportView]', { year, emailChecked, busy });

  /*-------------------- Render ---------------------------------------------*/
  return (
    <>
      <YearReportCard onSubmit={handleSubmit}></YearReportCard>
      <ModalSpinner show={busy}></ModalSpinner>
      <ModalDialog title={'H Έκθεση ολοκληρώθηκε'} trigger={finish} />
    </>
  );
};

export default YearReportView;
