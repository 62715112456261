import React, { FC } from 'react';
import { Navbar } from 'react-bootstrap';
import './footer.css';

// import { useWhyDidYouUpdate, useLogger } from '../hooks';

import gitnum from '../gitnum';
import * as app from '../../package.json';

type Props = {};

const Footer: FC<Props> = () => (
  <footer>
    <Navbar
      className="pb-1 pt-1 justify-content-between"
      bg="dark"
      variant="dark"
      fixed="bottom"
      style={{ maxHeight: 32 }}
    >
      <Navbar.Text className="p-0">
        <small>{'\u00A92019 Ecoelastika-Ecopoint'}</small>
      </Navbar.Text>
      <Navbar.Text className="p-0">
        <small>{gitnum.version}</small>
      </Navbar.Text>
    </Navbar>
  </footer>
);

export default Footer;
