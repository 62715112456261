import React, { FC, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

// import { useWhyDidYouUpdate } from '../hooks';

type Props = {
  title: string;
  text: string;
  onClick?: () => void;
};

const ModalDialogFull: FC<Props> = ({ title, text, onClick = () => {} }) => {
  /*-------------------- Body -----------------------------------------------*/
  const [show, setShow] = useState(true);

  /* useEffect(() => {
    if (trigger) {
      setShow(true);
    }
  }, [trigger]); */

  const handleClick = () => {
    setShow(false);
    onClick();
  };
  /*-------------------- Render ---------------------------------------------*/
  return (
    <Modal centered backdrop="static" show={show}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClick}>
          ΣΥΝΕΧΕΙΑ
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDialogFull;
