import React, { FC, useState, useEffect, useRef } from 'react';
import { Form, Button, Card, Alert, Col, Row } from 'react-bootstrap';
import * as yup from 'yup';
import useForm from 'react-hook-form';

// import { useWhyDidYouUpdate, useLogger } from '../../hooks';
import { Agreement } from '../../models';
import { useWhyDidYouUpdate } from '../../hooks';
import { useRootStore } from '../../store';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { useGeocodeReverseQuery, useGeocodeQuery } from '../../api';
import { IsTyreImporter } from './isTyreImporter';

declare const google;
/**
 * Setup Yup Validation & Type
 */
const validationSchema = yup.object().shape({
  Address: yup.string().required(),
  ZipCode: yup.string().required(),
  Phone1: yup.string().required(),
  Phone2: yup.string(),
  Phone3: yup.string(),
  Fax1: yup.string(),
  Email: yup.string().required(),
  Contact: yup.string().required(),
  ContactPosition: yup.string(),
  Municipality: yup.string().required(),
  IsTyreImporter: yup.boolean(),
  CertifyNoTyreImport: yup.boolean(),
});

export type AgreementFormData = {
  Address: string;
  ZipCode: string;
  Phone1: string;
  Phone2: string;
  Phone3: string;
  Fax1: string;
  Email: string;
  Contact: string;
  ContactPosition: string;
  Municipality: string;
  AddressLongitude: number;
  AddressLatitude: number;
  dataChanged: boolean;
  IsTyreImporter: boolean;
  CertifyNoTyreImport: boolean;
};

type Props = {
  agreement: Agreement;
  consented: boolean;
  onSubmit: (data: AgreementFormData) => void;
  recordsMun: any;
  recordsPos: any;
  setConsented: any;
};

const CstPointFields: FC<Props> = ({
  agreement,
  consented,
  onSubmit,
  recordsMun,
  recordsPos,
  setConsented,
}) => {
  /*-------------------- Body -----------------------------------------------*/
  const { appStore } = useRootStore();
  const isFirstRun = useRef(true);
  const isFirstRun2 = useRef(true);
  const initialValues = {
    Address: !agreement.Address ? '' : agreement.Address,
    ZipCode: !agreement.ZipCode ? '' : agreement.ZipCode,
    Phone1: !agreement.Phone1 ? '' : agreement.Phone1,
    Phone2: !agreement.Phone2 ? '' : agreement.Phone2,
    Phone3: !agreement.Phone3 ? '' : agreement.Phone3,
    Fax1: !agreement.Fax1 ? '' : agreement.Fax1,
    Email: !agreement.Email ? '' : agreement.Email,
    Contact: !agreement.Contact ? '' : agreement.Contact,
    ContactPosition: !agreement.ContactPosition ? '' : agreement.ContactPosition,
    Municipality: !agreement.Municipality ? '' : agreement.Municipality,
    ContactPositionNew:
      agreement.ContactPosition !== 'Ιδιοκτήτης' &&
      agreement.ContactPosition !== 'Νόμιμος Εκπρόσωπος' &&
      agreement.ContactPosition !== 'Υπάλληλος' &&
      agreement.ContactPosition !== 'Υπεύθυνος καταστήματος'
        ? agreement.ContactPosition
        : '',
    IsTyreImporter: agreement.IsTyreImporter ? agreement.IsTyreImporter : (undefined as boolean),
    CertifyNoTyreImport: agreement.CertifyNoTyreImport ? agreement.CertifyNoTyreImport : false,
  };

  const [canConsent, setCanConsent] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const { register, handleSubmit, formState, errors, setValue, getValues } = useForm<any>({
    defaultValues: initialValues,
    validationSchema: validationSchema,
    mode: 'onBlur',
  });
  const [dataChanged, setDataChanged] = useState(false);
  const [position, setPosition] = useState({
    lat: agreement.AddressLatitude,
    lng: agreement.AddressLongitude,
  });
  const [newOption, setNewOption] = useState(false);
  const [address, setAddress] = useState(agreement.Address);
  const [zipCode, setZipCode] = useState(agreement.ZipCode);
  const [lat, setLat] = useState(agreement.AddressLatitude);
  const [lng, setLng] = useState(agreement.AddressLongitude);
  const { records, reFetch: fetchGeo } = useGeocodeQuery({
    address: address,
    zipCode: zipCode,
  });

  const { records: addressRecords, reFetch: fetchGeoReverse } = useGeocodeReverseQuery({
    lat: lat,
    lng: lng,
  });

  /*-------------------- Handlers -------------------------------------------*/
  const processedData = (data: any) => {
    const newData = {
      Address: data.Address.toUpperCase(),
      ZipCode: data.ZipCode,
      Phone1: data.Phone1,
      Phone2: data.Phone2,
      Phone3: data.Phone3,
      Fax1: data.Fax1,
      Email: data.Email.toUpperCase(),
      Contact: data.Contact.toUpperCase(),
      ContactPosition:
        data.ContactPosition === 'Άλλη Ιδιότητα' || data.ContactPosition === ''
          ? data.ContactPositionNew
          : data.ContactPosition,
      Municipality: data.Municipality,
      AddressLongitude: position.lng,
      AddressLatitude: position.lat,
      dataChanged: data.dataChanged,
      IsTyreImporter: data.IsTyreImporter === 'true' ? true : false,
      CertifyNoTyreImport: data.CertifyNoTyreImport,
    };

    return newData;
  };

  const handleOnSubmit = (data: any) => {
    if (
      data.Municipality === '' ||
      ((data.ContactPosition === 'Άλλη Ιδιότητα' && data.ContactPositionNew === '') ||
        (data.ContactPosition === '' && data.ContactPositionNew === ''))
    ) {
      setShowAlert(true);
    } else {
      console.log('Submit:', data);
      onSubmit(processedData(data));
    }
  };

  const handleAlertDismiss = () => {
    setShowAlert(false);
  };

  const containerStyle = {
    width: '500px',
    height: '720px',
  };
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState({
    lat: 38.005,
    lng: 23.823,
  });

  const focusMarker = position => {
    setZoom(17);
    setCenter(position);
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDTPYVZ76DV-eairJCtDjYAIyQp5rYcG_w', // ,
    // ...otherOptions
  });

  useEffect(() => {
    if (zipCode.length > 4 && address.length > 0) {
      const timer = setTimeout(() => {
        fetchGeo();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [address, zipCode]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      const timer = setTimeout(() => {
        fetchGeoReverse();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [lat, lng]);

  useEffect(() => {
    if (isFirstRun2.current) {
      isFirstRun2.current = false;
    } else {
      if (addressRecords.address.length > 0 && addressRecords.zipCode.length > 0) {
        setValue('Address', addressRecords.address);
        setValue('ZipCode', addressRecords.zipCode);
      }
    }
  }, [addressRecords]);
  /*-------------------- Render ---------------------------------------------*/
  useWhyDidYouUpdate('[CstPointFields]', { dataChanged, formState, errors });
  return (
    isLoaded && (
      <Form onSubmit={handleSubmit(handleOnSubmit)}>
        <Card.Body className="p-2 p-sm-4">
          <>
            <Row>
              <Col>
                <Form.Group className="mb-1 mb-sm-2" controlId="formAddress">
                  <Form.Label className="mb-1 mb-sm-2">Διεύθυνση</Form.Label>

                  <Form.Control
                    name="Address"
                    type="string"
                    ref={register}
                    isInvalid={'Address' in errors}
                    disabled={!dataChanged}
                    onChange={e => setAddress((e.target as any).value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {'Το πεδίο είναι υποχρεωτικό'}
                  </Form.Control.Feedback>
                </Form.Group>

                {dataChanged ? (
                  <Form.Group controlId="formNewMunicipality">
                    <Form.Label>Δήμος</Form.Label>
                    <Form.Control
                      name="Municipality"
                      as="select"
                      ref={register}
                      isInvalid={'Municipality' in errors}
                    >
                      {recordsMun.map(record => {
                        return (
                          <option key={record.Municipality} value={record.Municipality}>
                            {record.Municipality}
                          </option>
                        );
                      })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {'Το πεδίο είναι υποχρεωτικό'}
                    </Form.Control.Feedback>
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-1 mb-sm-2" controlId="formMunicipality">
                    <Form.Label className="mb-1 mb-sm-2">Δήμος</Form.Label>
                    <Form.Control
                      name="Municipality"
                      type="string"
                      ref={register}
                      isInvalid={'Municipality' in errors}
                      disabled={!dataChanged}
                    />
                    <Form.Control.Feedback type="invalid">
                      {'Το πεδίο είναι υποχρεωτικό'}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}

                <Form.Group className="mb-1 mb-sm-2" controlId="formZipCode">
                  <Form.Label className="mb-1 mb-sm-2">Τ.Κ.</Form.Label>
                  <Form.Control
                    name="ZipCode"
                    type="string"
                    ref={register}
                    isInvalid={'ZipCode' in errors}
                    disabled={!dataChanged}
                    onChange={e => setZipCode((e.target as any).value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {'Το πεδίο είναι υποχρεωτικό'}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-1 mb-sm-2" controlId="formPhone1">
                  <Form.Label className="mb-1 mb-sm-2">Τηλέφωνο</Form.Label>
                  <Form.Control
                    name="Phone1"
                    type="string"
                    ref={register}
                    isInvalid={'Phone1' in errors}
                    disabled={!dataChanged}
                  />
                  <Form.Control.Feedback type="invalid">
                    {'Το πεδίο είναι υποχρεωτικό'}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-1 mb-sm-2" controlId="formPhone2">
                  <Form.Label className="mb-1 mb-sm-2">Τηλέφωνο 2</Form.Label>
                  <Form.Control
                    name="Phone2"
                    type="string"
                    ref={register}
                    isInvalid={'Phone2' in errors}
                    disabled={!dataChanged}
                  />
                </Form.Group>

                <Form.Group className="mb-1 mb-sm-2" controlId="formPhone3">
                  <Form.Label className="mb-1 mb-sm-2">Κινητό</Form.Label>
                  <Form.Control
                    name="Phone3"
                    type="string"
                    ref={register}
                    isInvalid={'Phone3' in errors}
                    disabled={!dataChanged}
                  />
                </Form.Group>

                <Form.Group className="mb-1 mb-sm-2" controlId="formFax1">
                  <Form.Label className="mb-1 mb-sm-2">Fax</Form.Label>
                  <Form.Control
                    name="Fax1"
                    type="string"
                    ref={register}
                    isInvalid={'Fax1' in errors}
                    disabled={!dataChanged}
                  />
                </Form.Group>

                <Form.Group className="mb-1 mb-sm-2" controlId="formEmail">
                  <Form.Label className="mb-1 mb-sm-2">Email</Form.Label>
                  <Form.Control
                    name="Email"
                    type="string"
                    ref={register}
                    isInvalid={'Email' in errors}
                    disabled={!dataChanged}
                  />
                  <Form.Control.Feedback type="invalid">
                    {'Το πεδίο είναι υποχρεωτικό'}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-1 mb-sm-2" controlId="formContact">
                  <Form.Label className="mb-1 mb-sm-2">Εκπρόσωπος</Form.Label>
                  <Form.Control
                    name="Contact"
                    type="string"
                    ref={register}
                    isInvalid={'Contact' in errors}
                    disabled={!dataChanged}
                  />
                  <Form.Control.Feedback type="invalid">
                    {'Το πεδίο είναι υποχρεωτικό'}
                  </Form.Control.Feedback>
                </Form.Group>

                {dataChanged ? (
                  <Row>
                    <Col>
                      <Form.Group controlId="formNewContactPosition">
                        <Form.Label>Ιδιότητα Εκπροσώπου</Form.Label>
                        <Form.Control
                          name="ContactPosition"
                          as="select"
                          ref={register}
                          isInvalid={'ContactPosition' in errors}
                          onChange={e =>
                            (e.target as any).value === 'Άλλη Ιδιότητα'
                              ? setNewOption(true)
                              : (setNewOption(false), setValue('ContactPositionNew', ''))
                          }
                        >
                          {recordsPos.map(record => {
                            return (
                              <option
                                key={record.Id}
                                value={record.Name}
                                // onSelect={() => setNewOption(false)}
                              >
                                {record.Name}
                              </option>
                            );
                          })}
                          <option
                            key={'newOption'}
                            value={'Άλλη Ιδιότητα'}
                            //onSelect={() => setNewOption(true)}
                          >
                            {'Άλλη Ιδιότητα'}
                          </option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {'Το πεδίο είναι υποχρεωτικό'}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1 mb-sm-2" controlId="formContactPositionNew">
                        <Form.Label>Άλλη Ιδιότητα</Form.Label>
                        <Form.Control
                          name="ContactPositionNew"
                          type="string"
                          ref={register}
                          isInvalid={'ContactPositionNew' in errors}
                          disabled={!newOption}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ) : (
                  <Form.Group className="mb-1 mb-sm-2" controlId="formContactPosition">
                    <Form.Label className="mb-1 mb-sm-2">Ιδιότητα Εκπροσώπου</Form.Label>
                    <Form.Control
                      name="ContactPosition"
                      type="string"
                      ref={register}
                      isInvalid={'ContactPosition' in errors}
                      disabled={!dataChanged}
                    />
                    <Form.Control.Feedback type="invalid">
                      {'Το πεδίο είναι υποχρεωτικό'}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}

                <Form.Group className="mb-0 mt-2" controlId="dataChanged-checkbox">
                  <Form.Check
                    type="checkbox"
                    name="dataChanged"
                    ref={register}
                    label="Μεταβολή Στοιχείων"
                    defaultChecked={false}
                    onChange={e => setDataChanged(e.target.checked)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <GoogleMap
                  /* options={options} */
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={zoom}
                >
                  {records && (
                    <Marker
                      title={''}
                      onPositionChanged={() => {
                        focusMarker(records);
                        setPosition(records);
                      }}
                      //onLoad={() => focusMarker(records)}
                      position={records}
                      draggable={dataChanged}
                      onDragEnd={e => {
                        setLat(e.latLng.lat());
                        setLng(e.latLng.lng());
                        setPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                      }}
                    ></Marker>
                  )}
                </GoogleMap>
                {dataChanged && (
                  <p style={{ color: 'orange' }}>
                    Μπορείτε να μετακινήσετε την καρφίτσα αν πιστεύετε ότι είναι λάθος.
                  </p>
                )}
              </Col>
            </Row>
            {showAlert && (
              <Alert variant={'danger'} onClose={handleAlertDismiss} dismissible>
                <p>{'Πρέπει να επιλέξετε Δήμο και Ιδιότητα Εκπροσώπου!'}</p>
              </Alert>
            )}
          </>
        </Card.Body>
        <Card.Footer>
          <IsTyreImporter
            setFieldValue={setValue}
            getFieldsValues={getValues}
            setCanConsent={setCanConsent}
            register={register}
            setConsented={setConsented}
          />

          <div style={{ textAlign: 'start', marginBottom: '5px' }}>
            <Form.Group className="mb-0 mt-2" controlId="agreement-checkbox">
              <Form.Check
                disabled={!canConsent}
                type="checkbox"
                label="Διάβασα και αποδέχομαι τους όρους συνεργασίας"
                defaultChecked={false}
                checked={consented}
                onChange={e => setConsented(e.target.checked)}
              />
            </Form.Group>
          </div>
          <Button block variant="primary" type="submit" disabled={!consented}>
            Υποβολή της Αποδοχής των Όρων Συνεργασίας
          </Button>
        </Card.Footer>
      </Form>
    )
  );
};

export default CstPointFields;
