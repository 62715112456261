import { useState, useEffect } from 'react';
import useAxios from '@use-hooks/axios';
import { oc } from 'ts-optchain';
import { useRootStore } from '../store';
import { useWhyDidYouUpdate } from '../hooks';
import { saveAs } from 'file-saver';
import { base64StringToBlob } from 'blob-util';
import { getType } from 'mime/lite';
/*
###
http://motion-2012:8085/webapi/YearReportALL.cshtml?CstPointId=10&Year=2017&Type=BLOB&User=h.sarris@gmail.com
###

###
http://motion-2012:8085/webapi/PeriodReportAll.cshtml?CstPointId=10&From=01/01/2014&To=01/01/2017&Type=BLOB&User=h.sarris@gmail.com
###

###
http://motion-2012:8085/webapi/OrderReportALL.cshtml?OrderId=3000&Type=BLOB&User=h.sarris@gmail.com
###
*/

export const useAgreementByIdPDFQuery = ({
  agreementId,
  type,
}: {
  agreementId: number;
  type: 'PDF' | 'EMAIL';
}) => {
  const { appStore } = useRootStore();
  const [message, setMessage] = useState('');
  const [load, setLoad] = useState(false);
  const [finish, setFinish] = useState(false);

  let url =
    appStore.webapi +
    `Agreement.cshtml?` +
    `User=${appStore.user}` +
    `&Pass=${appStore.password}` +
    `&CstPointId=${appStore.cstPointId}` +
    `&AgreementId=${agreementId}` +
    `&Type=${type}`;

  console.log(url);

  const { response, loading, error, reFetch } = useAxios({
    url,
    method: 'GET',
  });
  const data = oc(response).data();

  /* --Το effect τρεχει οταν αλλάζει το loading/error */
  useEffect(() => {
    console.log('running effect');
    if (loading) {
      setLoad(true);
      setFinish(false);
    }
    if (!loading && !!data && load) {
      setLoad(false);
      if (type === 'PDF') {
        const type = getType('pdf');
        /*  const blob = base64StringToBlob(data.Blob, type); */
        const isWebView =
          navigator.userAgent.includes('wv') || navigator.userAgent.includes('EcopointWeb');
        if (isWebView) {
          window.postMessage('/webapi/PDF/' + data.Filename, '*');
        } else {
          saveAs(url, 'Agreement');
        }
      }
      setFinish(true);
    }
  }, [loading, load, error, type, data]);

  useWhyDidYouUpdate('[useAgreementPDFQuery]', { url, loading, data, error, load, finish });
  return {
    data,
    load,
    loading,
    busy: load || loading,
    finish,
    error,
    reFetch,
  };
};
