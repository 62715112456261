import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useAxios from '@use-hooks/axios';
import { oc } from 'ts-optchain';

import { useWhyDidYouUpdate, useSt8 } from '../../hooks';
import { useRootStore } from '../../store';

import { OrderRequest } from '../../models';

import RequestCard, { OrderFormData } from './request-card';
import ModalSpinner from '../../components/modal-spinner';
import ModalReturn from '../../components/modal-dialog';
import ModalDialogFull from '../../components/modal-dialog-full';
import { ROUTES } from '../../routes';
import { getTyreLimits, shopsTyreLimits } from './tyre-limits';

/*

    Νέα Αίτηση για Καταστήματα

*/

type Props = RouteComponentProps & {};
const RequestView: FC<Props> = props => {
  /*-------------------- Body -----------------------------------------------*/
  const { appStore } = useRootStore();

  const [isActive, setIsActive] = useState(true);
  const [modalText, setModalText] = useState('Παρακαλώ υπογράψτε τους όρους συνεργασίας');
  const [finishPre, setFinishPre] = useState(false);
  const [finish, setFinish] = useState(false);
  const [order, setOrder] = useState<OrderRequest>();
  const [order1235, setOrder1235] = useState<OrderRequest>();
  const [order4, setOrder4] = useState<OrderRequest>();
  const [message, setMessage] = useState<string>('');
  const [orderFormData, setOrderFormData] = useState<OrderFormData>({
    OrderId: 0,
    Tyre1: '0',
    Tyre2: '0',
    Tyre3: '0',
    Tyre4: '0',
    Tyre5: '0',
    Notes: '',
  });

  const cstPointId = appStore.cstPointId;
  const user = appStore.user;
  const password = appStore.password;

  // --- preRequest Hook
  const urlPre =
    appStore.webapi +
    `xPreRequest_v2.cshtml?User=${user}` +
    `&Pass=${password}` +
    `&CstPointId=${cstPointId}`;

  const { response: responsePre, loading: loadingPre, error: errorPre } = useAxios({
    url: urlPre,
    method: 'GET',
    trigger: [],
  });
  const dataPre = oc(responsePre).data(false);

  useEffect(() => {
    console.log('Effect');
    if (errorPre) {
      setMessage('Σφάλμα επικοινωνίας με τον Server!');
    } else {
      if (dataPre !== false) {
        const isInJSON = dataPre.ErrResult !== undefined;
        const orderObj = isInJSON ? dataPre : JSON.parse(dataPre);
        // console.log('$$$', orderObj);
        switch (orderObj.ErrResult) {
          case 0: // Normal order -- Υπάρχουσα Εγγραφή
            //setOrder(orderObj.order4); // $$$ remove

            if (orderObj.order1235 !== null) setOrder1235(orderObj.order1235);
            else setOrder1235({ ...orderObj, OrderId: 0 }); // Νεα Εγγραφή

            if (orderObj.order4 !== null) setOrder4(orderObj.order4);
            else setOrder4({ ...orderObj, OrderId: 0 }); // Νεα Εγγραφή

            setFinishPre(true);
            break;
          case 4: // No orders
            setOrder({ ...orderObj, OrderId: 0 }); // Νεα Εγγραφή
            setFinishPre(true);
            break;
          case 3: // CstPoint not active
            setIsActive(false);
            setModalText(
              appStore.hasConsented
                ? 'Παρακαλώ επικοινωνήστε με ECOELASTIKA (κος Τατσιόπουλος 210 61.28.260) για την ενεργοποίηση του λογαριασμού σας'
                : 'Παρακαλώ υπογράψτε τους όρους συνεργασίας και επικοινωνήστε με ECOELASTIKA (κος Τατσιόπουλος 210 61.28.260) για την ενεργοποίηση του λογαριασμού σας',
            );
            setFinishPre(true);
            break;
          default:
            console.log(orderObj.ErrMessage);
        }
      }
    }
  }, [appStore.hasConsented, dataPre, errorPre]);

  useWhyDidYouUpdate('[RequestView:1]', {
    dataPre,
    loadingPre,
    errorPre,
    finishPre,
    order,
    message,
  });

  // --- Request Edit/New Order Hook
  const url =
    appStore.webapi +
    `xRequest_v2.cshtml?User=${user}` +
    `&Pass=${password}` +
    `&CstPointId=${cstPointId}` +
    `&CollectionIID=${orderFormData.OrderId}` +
    `&ReqQty1=${orderFormData.Tyre1}` +
    `&ReqQty2=${orderFormData.Tyre2}` +
    `&ReqQty3=${orderFormData.Tyre3}` +
    `&ReqQty4=${orderFormData.Tyre4}` +
    `&ReqQty5=${orderFormData.Tyre5}` +
    `&Notes=${orderFormData.Notes}`;
  const { response, loading, error, reFetch } = useAxios({ url, method: 'GET' });
  const data = oc(response).data(false);

  useEffect(() => {
    if (data !== false) {
      console.log(JSON.parse(data));
      setFinish(true);
    }
  }, [data]);

  useWhyDidYouUpdate('[RequestView:2]', { data, loading, error, orderFormData });

  /*-------------------- Handlers -------------------------------------------*/
  const handleSubmit = (formData: OrderFormData) => {
    setOrderFormData(formData);
    reFetch();
  };

  const handleEndClick = () => {
    props.history.push(ROUTES.Orders);
  };

  /*-------------------- Render ---------------------------------------------*/
  return (
    <>
      {(!isActive || (!appStore.hasConsented && appStore.cstPointTypeId === 0)) && finishPre && (
        <ModalDialogFull
          title={'Δεν έχετε δικαίωμα για νέα αίτηση'}
          text={modalText}
          onClick={() => props.history.push(ROUTES.CstPoint)}
        />
      )}
      {appStore.firstMustConsent && (
        <ModalDialogFull
          title={'Δεν έχετε δικαίωμα για νέα αίτηση'}
          text={'Παρακαλούμε αποδεχτείτε τους όρους συνεργασίας πρώτα'}
          onClick={() => props.history.push(ROUTES.Agreement)}
        />
      )}
      {finishPre &&
        isActive &&
        (appStore.hasConsented || appStore.cstPointTypeId !== 0) &&
        (order ? (
          <RequestCard
            order={order}
            tyreLimits={getTyreLimits(appStore.inUseAgreementId)}
            onSubmit={handleSubmit}
          />
        ) : (
          <div>
            <RequestCard
              order={order1235}
              tyreLimits={getTyreLimits(appStore.inUseAgreementId)}
              onSubmit={handleSubmit}
              notQ4={true}
            />
            <RequestCard
              order={order4}
              tyreLimits={getTyreLimits(appStore.inUseAgreementId)}
              onSubmit={handleSubmit}
              notQ1235={true}
            />
          </div>
        ))}
      <ModalSpinner show={loadingPre || loading}></ModalSpinner>
      <ModalReturn title={'Η ΑΙΤΗΣΗ ΚΑΤΑΧΩΡΗΘΗΚΕ'} trigger={finish} onClick={handleEndClick} />
    </>
  );
};

export default RequestView;
