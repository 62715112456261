import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import './react-datepicker.css';

/**
 *  Global CSS Files
 */
//import '@fortawesome/fontawesome-free/css/all.css';

import { App } from './app/app';
import * as serviceWorker from './serviceWorker';

//
// Loading configuration and pass it to App
//
/*
let config = {} as ConfigType;
const configPromise = fetch('data/config.json');
configPromise
  .then(res => res.json())
  .then(data => {
    config = { ...data };
  })
  .catch(() => {})
  .finally(() => {
    ReactDOM.render(<App config={config} />, document.getElementById('root'));
  });
*/
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
