import React, { FC } from 'react';
import { Form, Button, Card, InputGroup } from 'react-bootstrap';
import useForm from 'react-hook-form';
import * as yup from 'yup';

import { useWhyDidYouUpdate } from '../../hooks';
import { FaUser, FaLock } from 'react-icons/fa';
/**
 * Setup Yup Validation
 */
const validationSchema = yup.object().shape({
  username: yup.string().email(),
  tempPass: yup.string().required(),
  password: yup
    .string()
    .required()
    .min(3),
});

export type ChangePassFormData = yup.InferType<typeof validationSchema>;

type Props = {
  onSubmit: (v: ChangePassFormData) => void;
};

const ChangePassForm: FC<Props> = ({ onSubmit }) => {
  /*-------------------- Body -----------------------------------------------*/
  const { register, handleSubmit, formState, errors } = useForm<ChangePassFormData>({
    validationSchema,
    mode: 'onSubmit',
  });
  const canSubmit = formState.dirty && formState.isValid;
  // useWhyDidYouUpdate('[ChangePassForm]', formState);
  /*-------------------- Handlers -------------------------------------------*/

  /*-------------------- Render ---------------------------------------------*/
  return (
    <Card className="bg-light shadow-sm p-3 mb-5" style={{ maxWidth: 640 }}>
      <Card.Img className="bg-light" variant="top" src="index_3D_Logo_GR.jpg" />
      <Card.Body>
        <Card.Title className="text-center mb-4 mt-1" as="h3">
          Αλλαγή Κωδικού
        </Card.Title>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FaUser />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                name="username"
                placeholder="Email Χρήστη"
                type="email"
                ref={register}
                isInvalid={'username' in errors}
              />
              <Form.Control.Feedback type="invalid">Μη αποδεκτό email</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FaLock />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                name="tempPass"
                placeholder="Παλιός Κωδικός Πρόσβασης"
                type="password"
                ref={register}
                isInvalid={'tempPass' in errors}
              />
              <Form.Control.Feedback type="invalid">Μη αποδεκτός κωδικός</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FaLock />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                name="password"
                placeholder="Νέος Κωδικός Πρόσβασης"
                type="password"
                ref={register}
                isInvalid={'password' in errors}
              />
              <Form.Control.Feedback type="invalid">Μη αποδεκτός κωδικός</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Button
            block
            variant="primary"
            size="lg"
            type="submit"
            disabled={!canSubmit}
            style={{ height: 52 }}
          >
            <span>Αλλαγή Κωδικού</span>
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};
export default ChangePassForm;
