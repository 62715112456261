import React, { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { format } from 'date-fns';

import { useWhyDidYouUpdate } from '../../hooks';
import { useReportQuery } from '../../api';
import { useRootStore } from '../../store';

import PeriodReportCard from './period-report-card';
import ModalReturn from '../../components/modal-dialog';
import ModalSpinner from '../../components/modal-spinner';

type Props = RouteComponentProps & {};

const PeriodReportView: FC<Props> = props => {
  /*-------------------- Body -----------------------------------------------*/
  const { appStore } = useRootStore();
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [emailChecked, setEmailChecked] = useState('');

  // local variables
  const cstPointId = appStore.cstPointId;
  const strFromDate = format(fromDate, 'dd.MM.yyyy');
  const strToDate = format(toDate, 'dd.MM.yyyy');
  const type = emailChecked ? 'EMAIL' : 'BLOB';
  const { busy, finish, reFetch: reFetchReport } = useReportQuery(
    'Period',
    type,
    0,
    cstPointId,
    '',
    strFromDate,
    strToDate,
  );

  /*-------------------- Handlers -------------------------------------------*/
  const handleSubmit = (_fromDate, _toDate, _email) => {
    console.log('Submit YEAR:', ' Email', _email);
    setFromDate(_fromDate);
    setToDate(_toDate);
    setEmailChecked(_email);
    reFetchReport();
  };

  useWhyDidYouUpdate('[PeriodReportView]', {
    fromDate,
    toDate,
    email: emailChecked,
    busy,
  });

  /*-------------------- Render ---------------------------------------------*/
  return (
    <>
      <PeriodReportCard onSubmit={handleSubmit}></PeriodReportCard>
      <ModalSpinner show={busy}></ModalSpinner>
      <ModalReturn title={'Η ΑΝΑΦΟΡΑ ΟΛΟΚΛΗΡΩΘΗΚΕ'} trigger={finish} />
    </>
  );
};

export default PeriodReportView;
