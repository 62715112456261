import React, { FC, useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import * as yup from 'yup';
import useForm from 'react-hook-form';
import { useWhyDidYouUpdate } from '../../hooks';
import { OrderWRequest } from '../../models';

/**------------------------ ΣΗΜΕΙΩΣΕΙΣ ----------------------------------------
 *
 * Η φόρμα όταν ανοίγει θα πρέπει να υπάρχουν ήδη οι αρχικές τιμές, αν αλλάξουν
 * μετά, δεν λαμβάνονται υπόψη αυτόματα. Θα πρέπει να κανω setvalue χειροκίνητα
 *
 */

/**
 * Setup Yup Validation & Type
 */
const validationSchema = yup.object().shape({
  Weight: yup.number().test({ test: value => value >= 5000 && value <= 12000 }),
  Notes: yup.string(),
});

export type OrderWFormData = {
  Weight: string;
  Notes: string;
};

type Props = {
  order: OrderWRequest;
  onSubmit: (data: OrderWFormData) => void;
};

const RequestWCard: FC<Props> = ({ order, onSubmit }) => {
  /*-------------------- Body -----------------------------------------------*/
  const newOrder = order.Status === 'Εχει Ολοκληρωθεί η Συλλογή' || order.OrderId === 0;
  const [showAlert, setShowAlert] = useState(true);
  const initialValues: OrderWFormData = {
    Weight: newOrder ? '0' : order.Weight,
    Notes: newOrder ? '' : order.Notes,
  };

  const textButton = newOrder ? 'ΑΠΟΣΤΟΛΗ ΑΙΤΗΣΗΣ' : 'ΔΙΟΡΘΩΣΗ ΑΙΤΗΣΗΣ';
  const alertHeader = newOrder ? 'Nέα Αίτηση' : 'Αίτηση σε αναμονή';
  const alertBody = newOrder
    ? 'Προχωρήστε στην αποστολή νέας αίτησης'
    : `Υπάρχει αίτηση σε αναμονή από ${order.Dates}, τροποποιήστε την αίτηση με νέες ποσότητες`;
  const alertColor = newOrder ? 'success' : 'danger';

  const { register, handleSubmit, formState, errors } = useForm<OrderWFormData>({
    defaultValues: initialValues,
    validationSchema: validationSchema,
    mode: 'onBlur',
  });

  const canSubmit = formState.dirty && formState.isValid;

  /*-------------------- Handlers -------------------------------------------*/
  const handleOnSubmit = (data: OrderWFormData) => {
    console.log('Submit:', data);
    onSubmit(data);
  };

  const handleAlertDesmiss = () => {
    setShowAlert(false);
  };

  /*-------------------- Render ---------------------------------------------*/
  //useWhyDidYouUpdate('[RequestWCard]', { order, errors, formState });
  return (
    <Card className="bg-light shadow">
      <Card.Header>
        <Card.Title className="mb-0">Αίτηση Συλλογής Ελαστικών</Card.Title>
      </Card.Header>

      <Form onSubmit={handleSubmit(handleOnSubmit)}>
        <Card.Body className="p-2 p-sm-4">
          {showAlert && (
            <Alert variant={alertColor} onClose={handleAlertDesmiss} dismissible>
              <Alert.Heading>{alertHeader}</Alert.Heading>
              <p>{alertBody}</p>
            </Alert>
          )}

          <Form.Group className="mb-1 mb-sm-2" controlId="formWeight">
            <Form.Label className="mb-1 mb-sm-2">Βάρος Ελαστικών (kg)</Form.Label>
            <Form.Control
              name="Weight"
              type="number"
              step="any"
              required
              ref={register}
              isInvalid={'Weight' in errors}
            />
            <Form.Control.Feedback type="invalid">
              Αποδεκτή ποσότητα 5000-12000 Kg
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formNotes">
            <Form.Label className="mb-1 mb-sm-2">Σημειώσεις</Form.Label>
            <Form.Control name="Notes" type="text" ref={register} />
          </Form.Group>
        </Card.Body>
        <Card.Footer>
          <Button block variant="primary" type="submit" disabled={!canSubmit}>
            <span>{textButton}</span>
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
};

export default RequestWCard;
