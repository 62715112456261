import useEffectOnce from './effect-once';
import useUpdateEffect from './update-effect';
import { log } from '../services';

const useLogger = (componentName: string, ...rest) => {
  useEffectOnce(() => {
    log.log(`> ${componentName} mounted`, ...rest);
    return () => log.log(`< ${componentName} unmounted`);
  });

  useUpdateEffect(() => {
    log.log(`! ${componentName} updated`, ...rest);
  });
};

export default useLogger;
