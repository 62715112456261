import { useState, useEffect } from 'react';
import useAxios from '@use-hooks/axios';
import { oc } from 'ts-optchain';
import { useRootStore } from '../store';
import { useWhyDidYouUpdate } from '../hooks';

/*
###
GET http://192.168.160.203:8085/webapi/xOrders.cshtml?User=bikenrider@gmail.com&Pass=motion$hellas&CstPointId=2481
###
*/

export interface Geocode {
  lat: number;
  lng: number
}

export const useGeocodeQuery = (variables) => {
  const { appStore } = useRootStore();
  const [message, setMessage] = useState('');
  const [records, setRecords] = useState<any>();
  const url = `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDTPYVZ76DV-eairJCtDjYAIyQp5rYcG_w` +
    `&address=${variables.address},${variables.zipCode}`
    + `&language=el`;
  const { response, loading, error, reFetch } = useAxios({
    url: url,
    method: 'GET',
  });
  const data = oc(response).data();
  useEffect(() => {
    console.log('url=', url);
    if (error) {
      setMessage('Σφάλμα επικοινωνίας με τον Server!');
    } else {
      if (!loading && !!data && data.results.length > 0) {
        const resp = data.results[0].geometry.location as Geocode;
          setRecords(resp);
      }
    }
  }, [response, loading, error, data]);

  useEffect(() => {
    reFetch();
  }, [])
  useWhyDidYouUpdate('[useGeocodeQuery]', { loading, data, error });
  return {
    loading,
    error,
    message,
    records,
    reFetch,
  };
};
