import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { useWhyDidYouUpdate } from '../../hooks';
import {
  useAgreementQuery,
  useMunicipalitiesQuery,
  useSubmitAgreementQuery,
  useAgreementPDFQuery,
  useContactPositionsQuery,
} from '../../api';
import ModalSpinner from '../../components/modal-spinner';
import { Card, Form, Button } from 'react-bootstrap';
import { TermsAndConditions } from './termsAndConditions';
import CstPointFields, { AgreementFormData } from './cstPointFields';
import { useRootStore } from '../../store';
import CstPointNewFields from './cstPointNewFields';
import ModalDialog from '../../components/modal-dialog';
import { parseISO } from 'date-fns';
import { AgreementTypes } from '../../common/constants';
import { AgreementsView } from '../agreements/agreements';
import { axios } from '@use-hooks/axios';
import { ROUTES } from '../../routes';

type Props = RouteComponentProps & {};

const AgreementView: FC<Props> = props => {
  /*-------------------- Body -----------------------------------------------*/
  const { appStore } = useRootStore();
  const history = useHistory();
  const [agreement, setAgreement] = useState<any>('');
  // -- For report QUERY hook

  const { loading, error, records, reFetch } = useAgreementQuery();
  const { busy, finish: finishPdf, reFetch: reFetchPDF } = useAgreementPDFQuery('PDF');
  const { busy: busyEmail, finish: finishEmail, reFetch: sendEmail } = useAgreementPDFQuery(
    'EMAIL',
  );
  const {
    loading: loadingMun,
    error: errorMun,
    records: recordsMun,
    reFetch: reFetchMun,
  } = useMunicipalitiesQuery();

  const {
    loading: loadingPos,
    error: errorPos,
    records: recordsPos,
    reFetch: reFetchPos,
  } = useContactPositionsQuery();

  const { records: recordsSubmit, reFetch: submit, finish } = useSubmitAgreementQuery();
  const [consented, SetConsented] = useState(false);

  const [printOnly, setPrintOnly] = useState<boolean>(false);

  /*-------------------- Handlers -------------------------------------------*/

  const handleSubmit = (formData: AgreementFormData) => {
    const newAgreement = { ...formData, AgreementId: calculateWichAgreementId() };
    setAgreement(newAgreement);
    appStore.consent();
    submit(newAgreement);
  };

  // Triggered όταν κάνει Submit
  useEffect(() => {
    if (finish) {
      sendEmail();
    }
  }, [finish]);

  useEffect(() => {
    if (finishEmail) {
      reFetchPDF();
    }
  }, [finishEmail]);

  useEffect(() => {
    const asyncEffect = async () => {
      console.log(
        `$$$ effect finishPdf=${JSON.stringify(finishPdf)} printOnly=${JSON.stringify(printOnly)}`,
      );
      if (finishPdf && printOnly !== true) {
        reFetch(); // Φέρνει το Agreement με Axios
        const consentedAgreementId = recordsSubmit;
        // like silent relogin
        if (consentedAgreementId) {
          console.log('$$$ effect finishPdf consentedAgreementId', consentedAgreementId);
          appStore.setConsentedAgreementId(consentedAgreementId);
          appStore.consent();
          // Reread CstPoint (μπορεί να έγινε Active)
          const urlPre =
            appStore.webapi +
            `xGetCstPoint.cshtml?User=${appStore.user}` +
            `&Pass=${appStore.password}` +
            `&CstPointId=${appStore.cstPointId}`;
          const response = await axios.get(urlPre);
          const newCstPointRec = response.data;
          if (newCstPointRec.IsNotActive === 0) {
            appStore.setIsNotActive(false);
            console.log('setIsNotActive(false)');
          }
          appStore.defineBannerParams();
        }
        // Θέλει refresh για να φύγει το banner
        // window.location.reload();
        history.push(ROUTES.Agreement);
      }
      setPrintOnly(false);
    };
    asyncEffect();
  }, [finishPdf]);

  const getFields = record => {
    const data = {
      Address: record.Address ? record.Address.toUpperCase() : null,
      ZipCode: record.ZipCode,
      Phone1: record.Phone1,
      Phone2: record.Phone2,
      Phone3: record.Phone3,
      Fax1: record.Fax1,
      Email: record.Email ? record.Email.toUpperCase() : null,
      Contact: record.Contact ? record.Contact.toUpperCase() : null,
      ContactPosition: record.ContactPosition,
      Municipality: record.Municipality,
      AddressLatitude: record.AddressLatitude,
      AddressLongitude: record.AddressLongitude,
      IsTyreImporter: record.IsTyreImporter,
      CertifyNoTyreImport: record.CertifyNoTyreImport,
    };
    return data;
  };

  const getNewFields = record => {
    const data = {
      NewAddress: record.NewAddress ? record.NewAddress.toUpperCase() : null,
      NewZipCode: record.NewZipCode ? record.NewZipCode.toUpperCase() : null,
      NewPhone1: record.NewPhone1,
      NewPhone2: record.NewPhone2,
      NewPhone3: record.NewPhone3,
      NewFax1: record.NewFax1,
      NewEmail: record.NewEmail ? record.NewEmail.toUpperCase() : null,
      NewContact: record.NewContact ? record.NewContact.toUpperCase() : null,
      NewMunicipality: record.NewMunicipality,
      NewContactPosition: record.NewContactPosition,
      NewAddressLatitude: record.NewAddressLatitude,
      NewAddressLongitude: record.NewAddressLongitude,
    };
    return data;
  };

  function calculateWichAgreementId(): number {
    if (!records.HasConsented) return appStore.inUseAgreementId;
    else return records.AgreementId;
  }

  useWhyDidYouUpdate('[AgreementView]', { records, recordsMun, recordsPos, loading, error });
  /*-------------------- Render ---------------------------------------------*/
  return (
    <>
      <Card className="bg-light shadow mb-2">
        <Card.Header>
          <Card.Title className="mb-0 d-flex flex-row justify-content-between align-items-center">
            <span>Όροι Συνεργασίας</span>
          </Card.Title>
        </Card.Header>
        {records && recordsMun && (
          <Card.Body style={{ textAlign: 'center' }} className="p-2 p-sm-4">
            {!records.HasConsented ? (
              <p style={{ color: 'navy' }}>
                Παρακαλούμε αφού διαβάσετε τους όρους συνεργασίας πατήστε <b>υποβολή</b> στο τέλος
                της σελίδας.
              </p>
            ) : (
              records && (
                <b style={{ textAlign: 'center', color: 'DarkGreen' }}>
                  {' Οι όροι συνεργασίας είναι υπογεγραμμένοι. Ημερομηνία Υπογραφής: ' +
                    records.ConsentDate}
                </b>
              )
            )}

            <TermsAndConditions
              agreementId={calculateWichAgreementId()}
              pdfFileLink={
                appStore.webapi +
                (records.PdfFileName !== null ? records.PdfFileName : records.InUsePdfFileName)
              }
            />

            {!records.HasConsented ? (
              <>
                <h4 style={{ marginTop: '80px', textAlign: 'start' }}>Στοιχεία Σημείου Συλλογής</h4>
                <p style={{ marginTop: '10px', textAlign: 'start' }}>
                  Παρακαλούμε ελέγξτε τα στοιχεία σας και εφόσον διαφέρουν πατήστε το{' '}
                  <b>Μεταβολή Στοιχείων</b> για να τα διορθώσετε
                </p>
                <div style={{ marginTop: '25px', textAlign: 'start' }}>
                  <CstPointFields
                    agreement={getFields(records)}
                    consented={consented}
                    recordsMun={recordsMun}
                    recordsPos={recordsPos}
                    onSubmit={handleSubmit}
                    setConsented={SetConsented}
                  />
                </div>
              </>
            ) : records.DataChanged ? (
              <>
                <h4 style={{ marginTop: '80px', textAlign: 'start' }}>
                  Νέα Στοιχεία Σημείου Συλλογής
                </h4>
                <div style={{ marginTop: '80px', textAlign: 'start' }}>
                  <CstPointNewFields agreement={getNewFields(records)} onPDFSubmit={reFetchPDF} />
                </div>
              </>
            ) : (
              <Button
                block
                variant="primary"
                onClick={() => {
                  setPrintOnly(true);
                  reFetchPDF();
                }}
              >
                Εκτύπωση Σύμβασης Συνεργασίας
              </Button>
            )}
          </Card.Body>
        )}
      </Card>
      <ModalSpinner show={loading || loadingMun || loadingPos || busy || busyEmail}></ModalSpinner>
      <ModalDialog
        title={'Η Λήψη της Σύμβασης ολοκληρώθηκε'}
        trigger={finishPdf}
        onClick={() => {}}
      />

      <AgreementsView />
    </>
  );
};
export default AgreementView;
