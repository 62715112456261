import React, { FC } from 'react';
import { Form, Col, Card, Accordion } from 'react-bootstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { oc } from 'ts-optchain';

import { useWhyDidYouUpdate } from '../../hooks';

import { OrderW } from '../../api';
import ReportFooter from '../../components/report-footer';

type Props = {
  keyValue: string;
  order: OrderW;
  collapsedId: string;
  onClick: (id: string) => void;
  onSubmit: (orderId: number, email: boolean) => void;
};

const OrderWItem: FC<Props> = ({ keyValue, order, collapsedId, onClick, onSubmit }) => {
  /*-------------------- Body------------------------------------------------*/
  const colDate = oc(order).ColDate('?ColDate');
  const collectorName = oc(order).CollectorName('?CollectorName');
  const notes = oc(order).Notes('?Notes');
  const reqDate = oc(order).ReqDate('?ReqDate');
  const status = oc(order).Status('?Status');
  const weight = oc(order).Weight('?Weight');
  const onWait = status === 'Εκρεμεί η Συλλογή';

  const title = 'Αίτηση ' + reqDate;
  let subtitle = 'Σε αναμονή παραλαβής';
  let classColor = 'text-danger';

  if (!onWait) {
    subtitle = 'Έχει γίνει παραλαβή ' + colDate;
    classColor = 'text-success';
  }
  /*-------------------- Handlers -------------------------------------------*/
  const handleClick = () => {
    onClick(keyValue);
  };

  const handleSubmit = (email: boolean) => {
    console.log(email);
    onSubmit(order.OrderId, email);
  };

  useWhyDidYouUpdate('[OrderWItem]', collapsedId);
  /*-------------------- Render ---------------------------------------------*/
  return (
    <Card className="border-right-0 border-left-0">
      <Accordion.Toggle
        as={Card.Header}
        className="pb-2 pt-2"
        eventKey={keyValue}
        onClick={handleClick}
      >
        <div className="mb-0 d-flex flex-row justify-content-between align-items-center">
          <Col>
            <Card.Title className={classColor}>{title}</Card.Title>
            <Card.Subtitle className="text-muted">{subtitle}</Card.Subtitle>
          </Col>
          {collapsedId === keyValue ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </Accordion.Toggle>

      <Accordion.Collapse eventKey={keyValue} className="border">
        <>
          <Card.Body className="pt-0 pb-2 pl-2 pr-2">
            <Form.Group as={Form.Row} className="m-1" controlId="formPlaintextCollector">
              <Form.Label column sm={4} md={3} lg={2} className="">
                Μεταφορέας:
              </Form.Label>
              <Col className="border">
                <Form.Control plaintext readOnly defaultValue={collectorName} />
              </Col>
            </Form.Group>

            <Form.Group as={Form.Row} className="m-1" controlId="formPlaintextWeight">
              <Form.Label column sm={4} md={3} lg={2} className="">
                Βάρος Ελαστικών:
              </Form.Label>
              <Col className="border">
                <Form.Control plaintext readOnly defaultValue={weight} />
              </Col>
            </Form.Group>

            {notes !== '' ? (
              <Form.Group as={Form.Row} className="m-1" controlId="formPlaintextNotes">
                <Form.Label column sm={4} md={3} lg={2} className="">
                  Σημειώσεις:
                </Form.Label>
                <Col className="border">
                  <Form.Control plaintext readOnly defaultValue={notes} />
                </Col>
              </Form.Group>
            ) : (
              <></>
            )}
          </Card.Body>
          <ReportFooter buttonText="ΛΗΨΗ ΔΕΛΤΙΟΥ" onSubmit={handleSubmit} whiteBg />
        </>
      </Accordion.Collapse>
    </Card>
  );
};

export default OrderWItem;
