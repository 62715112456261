import React, { FC, useEffect } from 'react';

import { useRootStore } from './../../store';
import { useWhyDidYouUpdate, useLogger } from '../../hooks';

type Props = {};
/*-------------------- Body -----------------------------------------------*/
const LogoutView: FC<Props> = props => {
  useWhyDidYouUpdate(' LogoutView]', props);
  useLogger(' LogoutView]');
  const { appStore } = useRootStore();

  useEffect(() => {
    appStore.logOut();
  }, []);
  /*-------------------- Handlers -------------------------------------------*/
  /*-------------------- Render ---------------------------------------------*/
  return (
    <div>
      <div>
        <p>LogoutView</p>
      </div>
    </div>
  );
};

export default LogoutView;
