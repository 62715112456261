import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Container, Card } from 'react-bootstrap';
import { useWhyDidYouUpdate } from '../hooks';
import { useRootStore } from '../store';
import UserCard from './user-card';
import Footer from './footer';
import Header from './header';
import withSizes from 'react-sizes';
import { ROUTES } from '../routes';
import './banner.css';

/**
 * LOOKUP TABLE
 *
 * Οργανώνω τα routes σε έναν πίνακα για να αναφέρομαι σε αυτά με το static
 * name και όχι με το string
 */
import AboutView from '../views/about';
import ChangePassView from '../views/change-pass';
import ForgotPassView from '../views/forgot-pass';
import LoginView from '../views/login';
import LogoutView from '../views/logout';
import OrdersView from '../views/orders';
import Orders2View from '../views/orders2';
import PeriodReportView from '../views/period-report';
import NewUserView from '../views/new-user';
import RequestView from '../views/request';
import Request2View from '../views/request2';
import SelectPointView from '../views/select-point';
import YearReportView from '../views/year-report';
import AgreementView from '../views/agreement';
import NewAgreementView from '../views/agreement-new';
import CertificateView from '../views/certificate';
import CstPointView from '../views/cstpoint';
import { FaGift, FaUser } from 'react-icons/fa';
import RewardPage from '../views/reward';
import { AgreementTypes } from '../common/constants';

/******************************************************************************
 * Ειδικό component για να "κρύψω" τα routes που δεν θέλω να υπάρχουν όταν
 * ο χρήστης δεν έχει κάνει ακόμα login. Στην ουσία είναι κατα συνθήκη είτε
 * Route ή Redirect στο login
 */
type RouteProps = Route['props'] & {
  component: React.ComponentType<any>;
};

const PrivateRoute: FC<RouteProps> = ({ component: Component, ...rest }) => {
  const { appStore } = useRootStore();
  console.log('PrivateRoute', rest.path, Component.name);
  return (
    <Route
      {...rest}
      render={props => {
        if (appStore.isPointSelected) return <Component {...props} />;
        if (appStore.isLogIn) return <Redirect to={ROUTES.Select} />;
        return <Redirect to={ROUTES.Login} />;
      }}
    />
  );
};

const CalcFirstPage = appStore => {
  let gotoRoute = ROUTES.CstPoint;

  // Καταστήματα
  if (appStore.isTireShop) {
    if (!appStore.hasConsented) gotoRoute = ROUTES.NewAgreement;
    else if (
      appStore.hasConsented &&
      appStore.consentedAgreementId !== AgreementTypes.Shops_Sept_2023.toString()
    )
      gotoRoute = ROUTES.NewAgreement;
    else if (!appStore.awardNoParticipate) gotoRoute = ROUTES.Reward;
    else gotoRoute = ROUTES.CstPoint;
    // Μή Καταστήματα
  } else {
    if (appStore.showTerms) gotoRoute = ROUTES.Agreement;
    else gotoRoute = ROUTES.CstPoint;
  }

  console.log('CalcFirstPage', gotoRoute, appStore);

  return gotoRoute;
};

/*
   Έχει το που πάει μετά το SelectPoint
*/
const SelectRoute: FC<RouteProps> = ({ component: Component, ...rest }) => {
  const { appStore } = useRootStore();
  console.log('SelectRoute', rest.path, Component.name);

  const gotoRoute = CalcFirstPage(appStore);

  return (
    <Route
      {...rest}
      render={props => {
        if (appStore.isPointSelected)
          return <Redirect to={appStore.prevRoute !== '' ? appStore.prevRoute : gotoRoute} />;
        if (appStore.isLogIn) return <Component {...props} />;
        return <Redirect to={ROUTES.Login} />;
      }}
    />
  );
};

/******************************************************************************
 * Ειδικό component για να δρομολογήσω τα routes σε περίπτωση που ο χρήστης έχει κάνει
 * ήδη login
 */
const PublicRoute: FC<RouteProps> = ({ component: Component, ...rest }) => {
  const { appStore } = useRootStore();
  console.log('PublicRoute', rest.path, Component.name);

  const gotoRoute = CalcFirstPage(appStore);

  console.log(
    'PublicRoute gotoRoute',
    gotoRoute,
    appStore.isTireShop,
    appStore.cstPointTypeId,
    appStore.awardNoParticipate,
  );

  return (
    <Route
      {...rest}
      render={props => (!appStore.isLogIn ? <Component {...props} /> : <Redirect to={gotoRoute} />)}
    />
  );
};

/******************************************************************************
 * Βασικό route component AppRoute
 *
 * Μόνο αυτό χρειάζεται να είναι observer του AppStore, θα μπορούσε όλο το appStore
 * να βρίσκεται σαν state σε αυτό το αντικείμενο και να μη χρησιμοποιήσω καθόλου mobx.
 *
 * Κάθε φορά που γίνεται update το appStore το AppRoute γινεται update και δρομολογεί αναλόγως
 * την σελίδα που πρέπει. Με τον τρόπο αυτό οι σελίδες κατά κανόνα δεν χρειάζεται να ελέγχουν
 * το login/logout ή κάποιο πεδίο του appStore.
 *
 */

type Props = {
  isMobile: Boolean;
};

const AppRoute = ({ isMobile }: Props) => {
  const { appStore } = useRootStore();
  const history = useHistory();
  const AbsoluteWrapper = styled.div``;
  const location = useLocation();
  useWhyDidYouUpdate('[AppRoute]', { appStore });
  const isStorePoint = appStore.cstPointTypeId !== 2;

  const ButtonLink = ({ name, path }) => {
    //console.log(`pathname: ${location.pathname} and path: ${path}`);
    return (
      <div
        className="button-link"
        onClick={() => history.push(path)}
        style={
          location.pathname === path
            ? { backgroundColor: '#118000', fontWeight: 'bold', color: 'white' }
            : {}
        }
      >
        <div style={{ margin: 'auto' }}>{name}</div>
      </div>
    );
  };

  const SideBar = useCallback(() => {
    return (
      <div className="d-flex flex-column flex-grow-1 " style={{ paddingTop: 0 /*80*/ }}>
        {appStore.isPointSelected && (
          <Card
            style={{ height: 1000, width: 350, position: 'fixed', paddingTop: 20 }}
            className="bg-light shadow"
          >
            <em>
              <p className="pl-3">Επιλογές Μενού:</p>
            </em>
            <ButtonLink name="Νέα Αίτηση" path={isStorePoint ? ROUTES.Request : ROUTES.Request2} />
            <ButtonLink
              name="Παραστατικά Διακίνησης"
              path={isStorePoint ? ROUTES.Orders : ROUTES.Orders2}
            />
            <ButtonLink name="Ιστορικό" path={ROUTES.PeriodReport} />
            <ButtonLink name="Βεβαίωση" path={ROUTES.Certificate} />
            <ButtonLink name="Ετήσια Έκθεση / Η.Μ.Α" path={ROUTES.YearReport} />
            {appStore.showTerms && <ButtonLink name="Όροι Συνεργασίας" path={ROUTES.Agreement} />}
            {appStore.showNewTerms && (
              <ButtonLink name="Νέοι Όροι Συνεργασίας" path={ROUTES.NewAgreement} />
            )}
            <ButtonLink
              name={
                <>
                  Προφίλ &nbsp;
                  <FaUser size={32} />
                </>
              }
              path={ROUTES.CstPoint}
            />
            {appStore.isPointSelected && appStore.isTireShop && (
              <ButtonLink
                name={
                  <>
                    Επιβράβευση &nbsp;
                    <FaGift size={32} />
                  </>
                }
                path={ROUTES.Reward}
              />
            )}
          </Card>
        )}
      </div>
    );
  }, [location]);

  return (
    <AbsoluteWrapper className="App">
      <Header
        isLogin={appStore.isLogIn}
        isPointSelected={appStore.isPointSelected}
        isStorePoint={isStorePoint}
        user={appStore.user}
        onLogoutClick={() => appStore.logOut()}
      ></Header>

      {appStore.isLogIn && !isMobile ? (
        <>
          {/* Empty Space for Header Navigation */}
          <div
            id="header-space"
            className="d-flex flex-row"
            style={{ background: 'red', height: '92px' }}
          >
            header-space
          </div>

          {/* Banner Σύμβασης*/}
          {appStore.isLogIn && appStore.isPointSelected && appStore.showBanner && (
            <>
              <div
                id="banner"
                className="d-flex flex-row  div-ov-hidden"
                style={{ position: 'absolute', zIndex: 3000 }}
              >
                <div className="animated-text" style={{ paddingBottom: '2px' }}>
                  {appStore.bannerText}
                </div>
              </div>
              <div
                id="header-space"
                // className="d-flex flex-row"
                style={{ background: 'red', height: '26px' }}
              >
                header-space
              </div>
            </>
          )}

          <div id="context" className="d-flex flex-row">
            <SideBar />

            <div className="flex-grow-2" style={{ position: 'relative', marginLeft: 350 }}>
              <Container
                style={{
                  paddingTop: 24 /*+80*/,
                  paddingBottom: 48,
                }}
              >
                {appStore.isPointSelected && (
                  <UserCard
                    user={appStore.user}
                    name={appStore.cstPointName}
                    typeId={appStore.cstPointTypeId}
                    address={appStore.cstPointAddress}
                  />
                )}
                <Switch>
                  <PublicRoute path={ROUTES.Login} exact strict component={LoginView} />
                  <PublicRoute path={ROUTES.ChangePass} exact strict component={ChangePassView} />
                  <PublicRoute path={ROUTES.ForgotPass} exact strict component={ForgotPassView} />
                  <SelectRoute path={ROUTES.Select} exact strict component={SelectPointView} />
                  <PrivateRoute path={ROUTES.Agreement} exact strict component={AgreementView} />
                  <PrivateRoute
                    path={ROUTES.NewAgreement}
                    exact
                    strict
                    component={NewAgreementView}
                  />
                  <PrivateRoute path={ROUTES.Logout} exact strict component={LogoutView} />
                  <PrivateRoute path={ROUTES.Orders} exact strict component={OrdersView} />
                  <PrivateRoute path={ROUTES.Orders2} exact strict component={Orders2View} />
                  <PrivateRoute
                    path={ROUTES.PeriodReport}
                    exact
                    strict
                    component={PeriodReportView}
                  />
                  <PrivateRoute
                    path={ROUTES.Certificate}
                    exact
                    strict
                    component={CertificateView}
                  />
                  <PrivateRoute path={ROUTES.CstPoint} exact strict component={CstPointView} />
                  <PublicRoute path={ROUTES.Register} exact strict component={NewUserView} />
                  <PrivateRoute path={ROUTES.Request} exact strict component={RequestView} />
                  <PrivateRoute path={ROUTES.Request2} exact strict component={Request2View} />
                  <PrivateRoute path={ROUTES.YearReport} exact strict component={YearReportView} />
                  <PrivateRoute path={ROUTES.Reward} exact strict component={RewardPage} />
                  <Route render={() => <Redirect to={ROUTES.CstPoint}></Redirect>} />
                </Switch>
              </Container>
            </div>
            <div className="d-flex flex-column flex-grow-1"></div>
          </div>
        </>
      ) : (
        <LoginOrMobileContainer />
      )}

      <Footer />
    </AbsoluteWrapper>
  );
};
const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
});

export default React.memo(withSizes(mapSizesToProps)(observer(AppRoute)));

const LoginOrMobileContainer = () => {
  const { appStore } = useRootStore();
  return (
    <>
      <Container
        className="d-flex flex-column flex-grow-1"
        style={{ paddingTop: 120, paddingBottom: 48 }}
      >
        {/* Banner Σύμβασης*/}
        {appStore.isLogIn && appStore.isPointSelected && appStore.showBanner && (
          <div id="banner" className="d-flex flex-row  div-ov-hidden-mobile">
            <div className="animated-text" style={{ paddingBottom: '2px' }}>
              {appStore.bannerText}
            </div>
          </div>
        )}

        {appStore.isPointSelected && (
          <UserCard
            user={appStore.user}
            name={appStore.cstPointName}
            typeId={appStore.cstPointTypeId}
            address={appStore.cstPointAddress}
          />
        )}
        <Switch>
          <PublicRoute path={ROUTES.Login} exact strict component={LoginView} />
          <SelectRoute path={ROUTES.Select} exact strict component={SelectPointView} />
          <PrivateRoute path={ROUTES.About} exact strict component={AboutView} />
          <PrivateRoute path={ROUTES.Agreement} exact strict component={AgreementView} />
          <PrivateRoute path={ROUTES.NewAgreement} exact strict component={NewAgreementView} />
          <PublicRoute path={ROUTES.ChangePass} exact strict component={ChangePassView} />
          <PublicRoute path={ROUTES.ForgotPass} exact strict component={ForgotPassView} />
          <PrivateRoute path={ROUTES.Logout} exact strict component={LogoutView} />
          <PrivateRoute path={ROUTES.Orders} exact strict component={OrdersView} />
          <PrivateRoute path={ROUTES.Orders2} exact strict component={Orders2View} />
          <PrivateRoute path={ROUTES.PeriodReport} exact strict component={PeriodReportView} />
          <PrivateRoute path={ROUTES.Certificate} exact strict component={CertificateView} />
          <PrivateRoute path={ROUTES.CstPoint} exact strict component={CstPointView} />
          <PublicRoute path={ROUTES.Register} exact strict component={NewUserView} />
          <PrivateRoute path={ROUTES.Request} exact strict component={RequestView} />
          <PrivateRoute path={ROUTES.Request2} exact strict component={Request2View} />
          <PrivateRoute path={ROUTES.YearReport} exact strict component={YearReportView} />
          <PrivateRoute path={ROUTES.Reward} exact strict component={RewardPage} />
          <Route render={() => <Redirect to={ROUTES.About}></Redirect>} />
        </Switch>
      </Container>
    </>
  );
};
