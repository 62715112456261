import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useAxios from '@use-hooks/axios';
import { oc } from 'ts-optchain';

import { useWhyDidYouUpdate, useSt8 } from '../../hooks';
import { useRootStore } from '../../store';

import { CstPointForm, CstPointDb } from '../../models';

import CstPointCard, { CstPointFormData } from './cstpoint-card';
import ModalSpinner from '../../components/modal-spinner';
import ModalReturn from '../../components/modal-dialog';
import ModalDialogFull from '../../components/modal-dialog-full';
import { ROUTES } from '../../routes';
import { useMunicipalitiesQuery } from '../../api';

type Props = RouteComponentProps & {};
const CstPointView: FC<Props> = props => {
  /*-------------------- Body -----------------------------------------------*/
  const { appStore } = useRootStore();

  const [isActive, setIsActive] = useState(true);
  const [finishPre, setFinishPre] = useState(false);
  const [finish, setFinish] = useState(false);
  const [key, setKey] = useState(1);
  const [cstPoint, setCstPoint] = useState<CstPointForm>();
  const [message, setMessage] = useState<string>('');
  const [cstPointFormData, setCstPointFormData] = useState<CstPointFormData>({
    Address: '',
    Municipality: '',
    ZipCode: '',
    VAT: '',
    IRS: '',
    ima: '',
    Phone1: '',
    Phone2: '',
    Phone3: '',
    Fax1: '',
    Email: '',
    Contact: '',
  });

  if (!appStore.hasConsented && !appStore.isAlerted && appStore.cstPointTypeId === 0) {
    setTimeout(function() {
      appStore.alert();
      props.history.push(ROUTES.Agreement);
    }, 500);
  }

  const cstPointId = appStore.cstPointId;
  const user = appStore.user;
  const password = appStore.password;

  // --- Municipalities Query
  const {
    loading: loadingMun,
    error: errorMun,
    records: recordsMun,
    reFetch: reFetchMun,
  } = useMunicipalitiesQuery();

  // --- preCstPoint Hook
  const urlPre =
    appStore.webapi +
    `xGetCstPoint.cshtml?User=${user}` +
    `&Pass=${password}` +
    `&CstPointId=${cstPointId}`;

  const {
    response: responsePre,
    loading: loadingPre,
    error: errorPre,
    reFetch: refetchCstPoint,
  } = useAxios({
    url: urlPre,
    method: 'GET',
    trigger: [],
  });
  const dataPre = oc(responsePre).data(false);

  useEffect(() => {
    console.log('Effect');
    if (errorPre) {
      setMessage('Σφάλμα επικοινωνίας με τον Server!');
    } else {
      if (dataPre !== false) {
        // console.log(JSON.parse(dataPre));

        const cstPointObj: CstPointDb = dataPre;
        setCstPoint(cstPointObj);
        console.log('done= ', cstPointObj);
        setFinishPre(true);
        setKey(key + 1);
      }
    }
  }, [dataPre, errorPre]);

  useWhyDidYouUpdate('[CstPointView:1]', {
    dataPre,
    loadingPre,
    errorPre,
    finishPre,
    cstPoint,
    message,
  });

  // --- CstPoint Edit/New Order Hook
  const url =
    appStore.webapi +
    `xUpdateCstPoint.cshtml?User=${user}` +
    `&Pass=${password}` +
    `&CstPointId=${cstPointId}` +
    `&NewContact=${cstPointFormData.Contact}` +
    `&NewAddress=${cstPointFormData.Address}` +
    `&NewMunicipality=${cstPointFormData.Municipality}` +
    `&NewZipCode=${cstPointFormData.ZipCode}` +
    `&NewPhone1=${cstPointFormData.Phone1}` +
    `&NewPhone2=${cstPointFormData.Phone2}` +
    `&NewPhone3=${cstPointFormData.Phone3}` +
    `&NewFax1=${cstPointFormData.Fax1}` +
    `&NewEmail=${cstPointFormData.Email}` +
    `&NewIma=${cstPointFormData.ima}`;
  const { response, loading, error, reFetch } = useAxios({ url, method: 'GET' });
  const data = oc(response).data(false);

  useEffect(() => {
    if (data !== false) {
      console.log(data);
      setFinish(true);
    }
  }, [data]);

  useWhyDidYouUpdate('[CstPointView:2]', { data, loading, error, cstPointFormData });

  /*-------------------- Handlers -------------------------------------------*/
  const handleSubmit = (formData: CstPointFormData) => {
    setCstPointFormData(formData);
    reFetch();
  };

  const handleEndClick = () => {
    //props.history.push(ROUTES.Orders);
    refetchCstPoint();
    setFinish(false);
  };

  /*-------------------- Render ---------------------------------------------*/
  return (
    <>
      {finishPre && recordsMun.length > 0 && (
        <CstPointCard
          key={key}
          cstPoint={cstPoint}
          onSubmit={handleSubmit}
          recordsMun={recordsMun}
        />
      )}
      <ModalSpinner show={loadingPre || loading || loadingMun}></ModalSpinner>
      <ModalReturn title={'Επιτυχής Αποθήκευση'} trigger={finish} onClick={handleEndClick} />
    </>
  );
};

export default CstPointView;
