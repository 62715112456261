import React, { FC, useState } from 'react';
import { Form, Button, Card, Alert, Row, Col } from 'react-bootstrap';
import * as yup from 'yup';
import useForm from 'react-hook-form';

// import { useWhyDidYouUpdate, useLogger } from '../../hooks';
import { AgreementSubmitted } from '../../models';
import { useWhyDidYouUpdate } from '../../hooks';
import { useRootStore } from '../../store';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { useGeocodeQuery } from '../../api/geocode';

/**
 * Setup Yup Validation & Type
 */
const validationSchema = yup.object().shape({
  NewAddress: yup.string(),
  NewZipCode: yup.string(),
  NewPhone1: yup.string(),
  NewPhone2: yup.string(),
  NewPhone3: yup.string(),
  NewNewFax1: yup.string(),
  Email: yup.string(),
  NewContact: yup.string(),
  NewContactPosition: yup.string(),
  NewMunicipality: yup.string(),
});

export type AgreementFormData = {
  NewAddress: string;
  NewZipCode: string;
  NewPhone1: string;
  NewPhone2: string;
  NewPhone3: string;
  NewFax1: string;
  NewEmail: string;
  NewContact: string;
  NewContactPosition: string;
  NewMunicipality: string;
  NewAddressLongitude: number;
  NewAddressLatitude: number;
  dataChanged: boolean;
};

type Props = {
  agreement: AgreementSubmitted;
  onPDFSubmit: () => void;
};

const CstPointNewFields: FC<Props> = ({ agreement, onPDFSubmit }) => {
  /*-------------------- Body -----------------------------------------------*/
  const { appStore } = useRootStore();
  const initialValues = {
    NewAddress: !agreement.NewAddress ? '' : agreement.NewAddress,
    NewZipCode: !agreement.NewZipCode ? '' : agreement.NewZipCode,
    NewPhone1: !agreement.NewPhone1 ? '' : agreement.NewPhone1,
    NewPhone2: !agreement.NewPhone2 ? '' : agreement.NewPhone2,
    NewPhone3: !agreement.NewPhone3 ? '' : agreement.NewPhone3,
    NewFax1: !agreement.NewFax1 ? '' : agreement.NewFax1,
    NewEmail: !agreement.NewEmail ? '' : agreement.NewEmail,
    NewContact: !agreement.NewContact ? '' : agreement.NewContact,
    NewContactPosition: !agreement.NewContactPosition ? '' : agreement.NewContactPosition,
    NewMunicipality: !agreement.NewMunicipality ? '' : agreement.NewMunicipality,
  };

  const { register, formState, errors } = useForm<AgreementFormData>({
    defaultValues: initialValues,
    validationSchema: validationSchema,
    mode: 'onBlur',
  });

  const handleReport = () => {
    onPDFSubmit();
    console.log('PDF');
  };

  const { records } = useGeocodeQuery({
    address: agreement.NewAddress,
    zipCode: agreement.NewZipCode,
  });

  const containerStyle = {
    width: '500px',
    height: '780px',
  };
  const [zoom, setZoom] = useState(agreement.NewAddressLatitude ? 17 : 10);
  const [center, setCenter] = useState({
    lat: agreement.NewAddressLatitude ? agreement.NewAddressLatitude : 38.005,
    lng: agreement.NewAddressLongitude ? agreement.NewAddressLongitude : 23.823,
  });

  const focusMarker = position => {
    setZoom(17);
    setCenter(position);
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDTPYVZ76DV-eairJCtDjYAIyQp5rYcG_w', // ,
    // ...otherOptions
  });

  /*-------------------- Render ---------------------------------------------*/
  useWhyDidYouUpdate('[CstPointNewFields]', { formState, errors });
  return (
    isLoaded && (
      <Form>
        <Card.Body className="p-2 p-sm-4">
          <>
            <Row>
              <Col>
                <Form.Group className="mb-1 mb-sm-2" controlId="formAddress">
                  <Form.Label className="mb-1 mb-sm-2">Διεύθυνση</Form.Label>

                  <Form.Control
                    name="NewAddress"
                    type="string"
                    ref={register}
                    isInvalid={'NewAddress' in errors}
                    disabled={true}
                  />
                </Form.Group>

                <Form.Group className="mb-1 mb-sm-2" controlId="formMunicipality">
                  <Form.Label className="mb-1 mb-sm-2">Δήμος</Form.Label>
                  <Form.Control
                    name="NewMunicipality"
                    type="string"
                    ref={register}
                    isInvalid={'NewMunicipality' in errors}
                    disabled={true}
                  />
                </Form.Group>

                <Form.Group className="mb-1 mb-sm-2" controlId="formZipCode">
                  <Form.Label className="mb-1 mb-sm-2">Τ.Κ.</Form.Label>
                  <Form.Control
                    name="NewZipCode"
                    type="string"
                    ref={register}
                    isInvalid={'NewZipCode' in errors}
                    disabled={true}
                  />
                </Form.Group>

                <Form.Group className="mb-1 mb-sm-2" controlId="formPhone1">
                  <Form.Label className="mb-1 mb-sm-2">Τηλέφωνο</Form.Label>
                  <Form.Control
                    name="NewPhone1"
                    type="string"
                    ref={register}
                    isInvalid={'NewPhone1' in errors}
                    disabled={true}
                  />
                </Form.Group>

                <Form.Group className="mb-1 mb-sm-2" controlId="formPhone2">
                  <Form.Label className="mb-1 mb-sm-2">Τηλέφωνο 2</Form.Label>
                  <Form.Control
                    name="NewPhone2"
                    type="string"
                    ref={register}
                    isInvalid={'NewPhone2' in errors}
                    disabled={true}
                  />
                </Form.Group>

                <Form.Group className="mb-1 mb-sm-2" controlId="formPhone3">
                  <Form.Label className="mb-1 mb-sm-2">Κινητό</Form.Label>
                  <Form.Control
                    name="NewPhone3"
                    type="string"
                    ref={register}
                    isInvalid={'NewPhone3' in errors}
                    disabled={true}
                  />
                </Form.Group>

                <Form.Group className="mb-1 mb-sm-2" controlId="formFax1">
                  <Form.Label className="mb-1 mb-sm-2">Fax</Form.Label>
                  <Form.Control
                    name="NewFax1"
                    type="string"
                    ref={register}
                    isInvalid={'NewFax1' in errors}
                    disabled={true}
                  />
                </Form.Group>

                <Form.Group className="mb-1 mb-sm-2" controlId="formEmail">
                  <Form.Label className="mb-1 mb-sm-2">Email</Form.Label>
                  <Form.Control
                    name="NewEmail"
                    type="string"
                    ref={register}
                    isInvalid={'NewEmail' in errors}
                    disabled={true}
                  />
                </Form.Group>

                <Form.Group className="mb-1 mb-sm-2" controlId="formContact">
                  <Form.Label className="mb-1 mb-sm-2">Εκπρόσωπος</Form.Label>
                  <Form.Control
                    name="NewContact"
                    type="string"
                    ref={register}
                    isInvalid={'NewContact' in errors}
                    disabled={true}
                  />
                </Form.Group>

                <Form.Group className="mb-1 mb-sm-2" controlId="formContactPosition">
                  <Form.Label className="mb-1 mb-sm-2">Ιδιότητα Εκπροσώπου</Form.Label>
                  <Form.Control
                    name="NewContactPosition"
                    type="string"
                    ref={register}
                    isInvalid={'NewContactPosition' in errors}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <GoogleMap
                  /* options={options} */
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={zoom}
                >
                  <Marker
                    title={''}
                    //onPositionChanged={() => focusMarker(records)}
                    //onLoad={() => focusMarker(records)}
                    position={
                      agreement.NewAddressLatitude
                        ? {
                            lat: agreement.NewAddressLatitude,
                            lng: agreement.NewAddressLongitude,
                          }
                        : records
                    }
                  ></Marker>
                </GoogleMap>
              </Col>
            </Row>
          </>
        </Card.Body>
        <Card.Footer>
          <Button block variant="primary" onClick={handleReport}>
            Εκτύπωση Σύμβασης Συνεργασίας
          </Button>
        </Card.Footer>
      </Form>
    )
  );
};

export default CstPointNewFields;
