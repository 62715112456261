import React, { FC } from 'react';
import { Form, Button, Card, InputGroup } from 'react-bootstrap';
import useForm from 'react-hook-form';
import * as yup from 'yup';

import { useWhyDidYouUpdate } from '../../hooks';
/**
 * Setup Yup Validation
 */
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
});

export type ForgotPassFormData = yup.InferType<typeof validationSchema>;

type Props = {
  onSubmit: (v: ForgotPassFormData) => void;
};

const ForgotPassForm: FC<Props> = ({ onSubmit }) => {
  /*-------------------- Body -----------------------------------------------*/
  const { register, handleSubmit, formState } = useForm<ForgotPassFormData>({
    validationSchema: validationSchema,
  });
  const canSubmit = formState.dirty && formState.isValid;
  // useWhyDidYouUpdate('[ForgotPassForm]', { formState, canSubmit });
  /*-------------------- Handlers -------------------------------------------*/
  /*-------------------- Render ---------------------------------------------*/
  return (
    <Card className="bg-light shadow-sm p-3 mb-5" style={{ maxWidth: 640 }}>
      <Card.Img className="bg-light" variant="top" src="index_3D_Logo_GR.jpg" />
      <Card.Body>
        <Card.Title className="text-center mb-4 mt-1" as="h3">
          Αποστολή Αιτήματος Προσωρινού Κωδικού
        </Card.Title>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <i className="fa fa-user"></i>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control name="email" placeholder="Email Χρήστη" type="email" ref={register} />
            </InputGroup>
          </Form.Group>

          <Button
            block
            variant="primary"
            size="lg"
            type="submit"
            disabled={!canSubmit}
            style={{ height: 52 }}
          >
            <span>Αποστολή</span>
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};
export default ForgotPassForm;
