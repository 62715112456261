import React, { FC, useState } from 'react';
import { Form, Row, Col, Card } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { format, subDays } from 'date-fns';

// import { useWhyDidYouUpdate, useLogger } from '../../hooks';
import ReportFooter from '../../components/report-footer';

type Props = {
  onSubmit: (fromDate: Date, toDate: Date, email: boolean) => void;
};

const PeriodReportCard: FC<Props> = ({ onSubmit }) => {
  /*-------------------- Body -----------------------------------------------*/
  const [from, setFrom] = useState(subDays(new Date(), 1));
  const [to, setTo] = useState(new Date());
  const [isOpenFrom, setIsOpenFrom] = useState(false);
  const [isOpenTo, setIsOpenTo] = useState(false);

  /*-------------------- Functions -------------------------------------------*/
  const toggleCalendarFrom = () => {
    setIsOpenFrom(!isOpenFrom);
  };

  const toggleCalendarTo = () => {
    setIsOpenTo(!isOpenTo);
  };

  /*-------------------- Handlers -------------------------------------------*/
  const handleFromChange = (date: Date) => {
    setFrom(date);
    toggleCalendarFrom();
  };

  const handleToChange = (date: any) => {
    setTo(date);
    toggleCalendarTo();
  };

  const handleSubmit = (email: boolean) => {
    onSubmit(from, to, email);
  };

  /*-------------------- Render ---------------------------------------------*/
  return (
    <>
      <Card className="bg-light shadow">
        <Card.Header>
          <Card.Title className="mb-0">Ενημερωτικό Δελτίο</Card.Title>
        </Card.Header>

        <Form>
          <Card.Body className="p-2 p-sm-4">
            <Form.Row>
              <Col>
                <Form.Label>Περίοδος Αναφοράς:</Form.Label>
              </Col>
            </Form.Row>

            <Row>
              <Col>
                <Form.Group className="mb-0" as={Row} controlId="period-report-card_from">
                  <Form.Label column sm={7}>
                    Aπό :
                  </Form.Label>
                  <Col>
                    <Form.Control
                      value={format(from, 'dd/MM/yyyy')}
                      style={{ maxWidth: 180, minWidth: 100 }}
                      onClick={toggleCalendarFrom}
                      onChange={() => {}}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mb-0" as={Row} controlId="period-report-card_from">
                  <Form.Label column sm={7}>
                    Μέχρι :
                  </Form.Label>
                  <Col>
                    <Form.Control
                      value={format(to, 'dd/MM/yyyy')}
                      style={{ maxWidth: 180, minWidth: 100 }}
                      onClick={toggleCalendarTo}
                      onChange={() => {}}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>

          <ReportFooter buttonText="ΔΗΜΙΟΥΡΓΙΑ ΑΝΑΦΟΡΑΣ" onSubmit={handleSubmit} />
        </Form>

        {isOpenFrom && (
          <DatePicker
            name="Test"
            maxDate={to}
            selected={from}
            onChange={handleFromChange}
            withPortal
            inline
          />
        )}
        {isOpenTo && (
          <DatePicker
            name="Test"
            minDate={from}
            maxDate={new Date()}
            selected={to}
            onChange={handleToChange}
            withPortal
            inline
          />
        )}
      </Card>
    </>
  );
};

export default PeriodReportCard;
