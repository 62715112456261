import React, { FC, useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';

// import { useWhyDidYouUpdate, useLogger } from '../hooks';

type Props = {
  buttonText: string;
  buttonDisabled?: boolean;
  whiteBg?: boolean;
  onSubmit: (emailSelected: boolean) => void;
};

const ReportFooter: FC<Props> = ({
  buttonText,
  buttonDisabled = false,
  whiteBg = false,
  onSubmit,
}) => {
  const [email, setEmail] = useState(false);

  const handleEmailChange = () => {
    setEmail(!email);
  };

  const handleClick = _e => {
    // e && e.preventDefault();
    onSubmit(email);
  };

  const styles = whiteBg ? { backgroundColor: 'white' } : {};

  return (
    <Card.Footer style={styles}>
      <Button
        style={{ height: 52 }}
        block
        variant="primary"
        disabled={buttonDisabled}
        onClick={handleClick}
      >
        <span>{buttonText}</span>
      </Button>

      <Form.Group className="mb-0 mt-2" controlId="report-footer-checkbox">
        <Form.Check
          type="checkbox"
          label="Αποστολή με E-mail"
          defaultChecked={false}
          onChange={handleEmailChange}
        />
      </Form.Group>
    </Card.Footer>
  );
};

export default ReportFooter;
