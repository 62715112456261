import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useAxios from '@use-hooks/axios';
import { oc } from 'ts-optchain';

import { useWhyDidYouUpdate, useSt8 } from '../../hooks';
import { useRootStore } from '../../store';
import { CstPoint } from '../../models';
import LoginForm, { LoginFormData } from './login-form';
import ModalSpinner from '../../components/modal-spinner';
import { ROUTES } from '../../routes';

type Props = RouteComponentProps & {};

const LoginView: FC<Props> = props => {
  /*-------------------- Body -----------------------------------------------*/
  const { appStore } = useRootStore();
  const user = useSt8('');
  const password = useSt8('');
  const message = useSt8('');

  /** ------------- First Login to get the cstPoints --------------------------*/
  const url = appStore.webapi + `xLogin.cshtml?` + `User=${user()}&` + `Pass=${password()}`;

  const { response, loading, error, reFetch } = useAxios({ url, method: 'GET' });
  const data = oc(response).data(false);

  /**
   *  Το effect τρεχει οταν αλλάζει το data/error
   */
  useEffect(() => {
    if (error) {
      message('Σφάλμα επικοινωνίας με τον Server!');
    } else {
      if (data !== false) {
        const obj = JSON.parse('[' + data + ']');
        const points = [...obj] as CstPoint[];
        const errMessage = oc(points[0]).ErrMessage('');
        const errResult = oc(points[0]).ErrResult(-1);
        message(errMessage);
        //console.log(points);
        if (errResult === 0) {
          appStore.login(user(), password(), points);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  // useWhyDidYouUpdate('[LoginView]', { data, loading, error });
  /*-------------------- Handlers -------------------------------------------*/
  const handleSubmit = (res: LoginFormData) => {
    user(res.email);
    password(res.password);
    message('');
    reFetch();
  };
  /*-------------------- Render ---------------------------------------------*/
  return (
    <div style={{ margin: 'auto' }}>
      <LoginForm
        onSubmit={handleSubmit}
        message={message()}
        onForgotClick={() => props.history.push(ROUTES.ForgotPass)}
        onTemporaryClick={() => props.history.push(ROUTES.ChangePass)}
        onNewUserClick={() => props.history.push(ROUTES.Register)}
      />
      <ModalSpinner show={loading}></ModalSpinner>
    </div>
  );
};

export default LoginView;
