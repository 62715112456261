import { observable, action, configure, computed } from 'mobx';
import { persist } from 'mobx-persist';
import * as crypto from 'crypto-js';
import { log } from '../services';
import { CstPoint } from '../models';
import { AgreementTypes, CstPointTypes } from '../common/constants';

configure({ enforceActions: 'always' }); // strict mode
//------------------------------------------------------
// *    --- Functions ---
//

// *    --- Store Class ---
//
export class AppStore {
  @observable webapi = '';
  @persist @observable user = '';
  @persist @observable private passwordEnc = '';
  @persist('list') @observable cstPoints = [] as CstPoint[];
  @persist @observable lastLoginDate = 0;
  @persist @observable lastUpdateDate = 0;
  @persist @observable cstPointId = 0;
  @persist @observable cstPointTypeId = 0;
  @persist @observable cstPointName = '';
  @persist @observable cstPointAddress = '';
  @persist @observable hasConsented = false;
  @persist @observable consentedAgreementId = 0;
  @persist @observable awardNoParticipate = false;
  @persist @observable inUseAgreementId = 0; // Σύμβαση (Όροι Συνεργασίας) που ισχύει για την ομάδα και τύπο του σημείου
  @persist @observable validContractId = 0; // Σύμβαση εν Ισχύ (για παλαιό τρόπο)
  @persist @observable firstMustConsent = false; // Να υπογράψει πρωτού κάνει οτιδήποτε
  @persist @observable isNotActive = false;
  @persist @observable isTireShop = false;
  @persist @observable isAlerted = false;
  @persist @observable prevRoute = '';

  @persist @observable showTerms = false;
  @persist @observable showNewTerms = false;
  @persist @observable showBanner = false;
  @persist @observable bannerText =
    'Έχει αλλάξει η νομοθεσία και χρειάζεται να κάνετε αποδοχή τους νέους όρους συνεργασίας έως τις 31/12/2023.';

  // *
  // * --------------- Getters -------------------------------
  @computed get isLogIn(): boolean {
    return this.passwordEnc !== '';
  }

  @computed get isPointSelected(): boolean {
    return this.passwordEnc !== '' && this.cstPointId !== 0;
  }

  @computed get password(): string {
    const bytes = crypto.AES.decrypt(this.passwordEnc, '12345');
    const password = bytes.toString(crypto.enc.Utf8);
    return password;
  }

  @computed get pointsNumber(): number {
    return this.cstPoints.length;
  }

  // * --------------- Methods -------------------------------
  @action login(user: string, password: string, points: CstPoint[]) {
    this.user = user;
    const cipher = crypto.AES.encrypt(password, '12345');
    this.passwordEnc = cipher.toString();
    this.lastLoginDate = new Date().valueOf();
    this.cstPoints = [...points];
    if (points.length === 1) {
      this.cstPointId = points[0].CstPointId;
      this.cstPointTypeId = points[0].CstPointTypeId;
      this.isTireShop = points[0].CstPointTypeId === 0 ? true : false;
      this.cstPointName = points[0].CstPointName;
      this.cstPointAddress = points[0].CstPointAddress;
      this.isNotActive =
        typeof points[0].IsNotActive === 'string'
          ? points[0].IsNotActive === '0'
            ? false
            : true
          : points[0].IsNotActive === 0
          ? false
          : true;
      this.hasConsented =
        typeof points[0].HasConsented === 'string'
          ? points[0].HasConsented === 'False'
            ? false
            : true
          : points[0].HasConsented;
      this.consentedAgreementId = points[0].ConsentedAgreementId;
      this.awardNoParticipate =
        typeof points[0].AwardNoParticipate === 'string'
          ? points[0].AwardNoParticipate === 'False'
            ? false
            : true
          : points[0].AwardNoParticipate;
      this.inUseAgreementId = parseInt(points[0].InUseAgreementId.toString());
      this.validContractId = parseInt(points[0].ValidContractId.toString());
    } else {
      this.cstPointId = 0;
      this.cstPointTypeId = 0;
      this.cstPointName = '';
      this.cstPointAddress = '';
      this.isNotActive = true;
      this.hasConsented = false;
      this.consentedAgreementId = 0;
      this.awardNoParticipate = false;
    }
    const dt = new Date(Date.now());
    sessionStorage.setItem('date', dt.toLocaleDateString() + ' ' + dt.toLocaleTimeString());

    // Έναρξη των Group Terms για Παρόχους & Διαλυτήρια
    // ακύρωσε πριν το milestone
    this.checkGroupTermsMilestone();

    // Define Banner
    this.defineBannerParams();
  }

  @action checkGroupTermsMilestone() {
    // Έναρξη των Group Terms για Παρόχους & Διαλυτήρια
    // ακύρωσε πριν το milestone
    if (Date.now() < new Date('2023-10-20').getTime()) {
      this.inUseAgreementId = 0;
      this.validContractId = 0;
    }
  }

  @action alert() {
    this.isAlerted = true;
  }

  @action consent() {
    this.hasConsented = true;
  }

  @action setIsNotActive(value: boolean) {
    this.isNotActive = value;
  }

  @action setConsentedAgreementId(value) {
    this.consentedAgreementId = value;
  }

  @action setUpdateDate(value) {
    this.lastUpdateDate = value;
  }

  @action unselectPoint() {
    this.cstPointId = 0;
  }

  @action selectPoint(id: number) {
    const points = this.cstPoints.filter(item => item.CstPointId === id);
    if (points.length === 1) {
      this.cstPointId = points[0].CstPointId;
      this.cstPointTypeId = points[0].CstPointTypeId;
      this.isTireShop = points[0].CstPointTypeId === 0 ? true : false;
      this.cstPointName = points[0].CstPointName;
      this.cstPointAddress = points[0].CstPointAddress;
      this.isNotActive =
        typeof points[0].IsNotActive === 'string'
          ? points[0].IsNotActive === '0'
            ? false
            : true
          : points[0].IsNotActive === 0
          ? false
          : true;
      this.hasConsented =
        typeof points[0].HasConsented === 'string'
          ? points[0].HasConsented === 'False'
            ? false
            : true
          : points[0].HasConsented;
      this.consentedAgreementId = points[0].ConsentedAgreementId;
      this.awardNoParticipate =
        typeof points[0].AwardNoParticipate === 'string'
          ? points[0].AwardNoParticipate === 'False'
            ? false
            : true
          : points[0].AwardNoParticipate;
      this.inUseAgreementId =
        points[0].InUseAgreementId === null ? 0 : parseInt(points[0].InUseAgreementId.toString());
      this.validContractId = parseInt(points[0].ValidContractId.toString());
    }

    // Έναρξη των Group Terms για Παρόχους & Διαλυτήρια
    // ακύρωσε πριν το milestone
    this.checkGroupTermsMilestone();

    // Define Banner
    this.defineBannerParams();
  }

  @action setPrevRoute(route: string) {
    this.prevRoute = route;
  }

  @action logOut() {
    this.user = '';
    this.passwordEnc = '';
    this.cstPoints = [] as CstPoint[];
    this.cstPointId = 0;
    this.cstPointTypeId = 0;
    this.cstPointName = '';
    this.cstPointAddress = '';
    this.isNotActive = false;
    this.hasConsented = false;
    this.isAlerted = false;
    this.prevRoute = '';
  }

  @action private setWebApi(value) {
    this.webapi = value;
  }

  @action private init() {
    log.log(`[AppStore] init`);
    //this.setWebApi(`https://${window.location.hostname}/webapi/`);
    console.log('port', window.location.port);
    if (window.location.hostname === '10.192.64.103')
      this.setWebApi(`http://10.192.64.103:8185/webapi/`);
    else if (window.location.hostname === '192.168.160.203')
      this.setWebApi(`http://192.168.160.203:8085/webapi/`);
    else if (window.location.hostname === 'localhost' && window.location.port === '30000') {
      //this.setWebApi(`http://192.168.160.203:8085/webapi/`);
      //this.setWebApi(`http://10.192.64.103:8185/webapi/`); // production θέλει VPN
      //this.setWebApi(`http://85.73.92.64:8185/webapi/`); // production
      this.setWebApi(`http://ecopointweb.dyndns.org/webapi/`); // production
    } else if (window.location.hostname === 'localhost') {
      this.setWebApi(`https://ecopointweb.dyndns.org/webapi/`);
      // this.setWebApi(`http://192.168.160.203:8085/webapi/`);
      //this.setWebApi(`http://10.192.64.103:8185/webapi/`);
    } else if (window.location.port !== '')
      this.setWebApi(`https://${window.location.hostname}:${window.location.port}/webapi/`);
    else this.setWebApi(`https://${window.location.hostname}/webapi/`);
    //this.setWebApi(`https://ecopointweb.dyndns.org/webapi/`);
    /* fetch('data/config.json')
      .then(res => res.json())
      .then(data => {
        log.log(window.location.hostname);
        this.setWebApi(data.webapi);
        log.log('[AppStore] webapi>', this.webapi);
      }); */
  }

  @action defineBannerParams() {
    this.showBanner = false;
    if (!this.isPointSelected) return;

    // Εμφανίζω banner για να αποδεχτούν τους όρους μετά την 15/9/2023
    // Καταστήματα

    //---------------------------------------------
    // Όροι Συνεργασίας
    // Καταστήματα
    if (this.cstPointTypeId == CstPointTypes.Shops) {
      let showNewTermsBanner = false;
      this.showTerms = true;
      if (
        this.hasConsented &&
        (this.consentedAgreementId == AgreementTypes.Shops_0 ||
          this.consentedAgreementId == AgreementTypes.Shops_Sept_2022)
      )
        showNewTermsBanner = true;
      else if (this.hasConsented && this.consentedAgreementId == AgreementTypes.Shops_Sept_2023)
        showNewTermsBanner = false;
      else if (!this.hasConsented) showNewTermsBanner = false;
      else showNewTermsBanner = false;

      if (!showNewTermsBanner) this.bannerText = '';
      else {
        this.bannerText =
          'Έχει αλλάξει η νομοθεσία και χρειάζεται να κάνετε αποδοχή τους νέους όρους συνεργασίας έως τις 31/12/2023.';
        this.showNewTerms = showNewTermsBanner;
      }
    }
    // Κάτοχοι εκτός Διαλυτηρίων
    else if (this.cstPointTypeId == CstPointTypes.OthersNoDialitirio) {
      this.showNewTerms = false;
      // Αν για την ομάδα χρησιμοποιούνται Όροι Συνεργασίας => Δείξε τους Όρους
      this.showTerms = this.inUseAgreementId > 0;

      // Override: Αν έχει υπογεγραμμένο συμβόλαιο => μη δείχνεις τους όρους
      if (this.validContractId > 0) this.showTerms = false;

      // Αν δεν υπάρχούν ενεργοί Όροι Συνεργασίας ή Συμβόλαιο => Show Banner
      // & firstMustConsent
      if (this.showTerms && this.validContractId === 0 && !this.hasConsented) {
        this.bannerText = 'Παρακαλούμε αποδεχτείτε τους Όρους συνεργασίας.';
        this.showBanner = true;
        this.firstMustConsent = true;
      } else {
        this.showBanner = false;
        this.firstMustConsent = false;
        this.bannerText = '';
      }
    }
    // Διαλυτήρια
    else if (this.cstPointTypeId == CstPointTypes.Dialitirio) {
      this.showNewTerms = false;
      // Αν για την κατηγορία χρησιμοποιούνται Όροι Συνεργασίας => Δείξε τους Όρους
      this.showTerms = this.inUseAgreementId > 0;

      // Override: Αν έχει υπογεγραμμένο συμβόλαιο => μη δείχνεις τους όρους
      if (this.validContractId > 0) this.showTerms = false;

      // Αν δεν υπάρχούν ενεργοί Όροι Συνεργασίας ή Συμβόλαιο => Show Banner
      // & firstMustConsent
      if (this.showTerms && this.validContractId === 0 && !this.hasConsented) {
        this.bannerText = 'Παρακαλούμε αποδεχτείτε τους Όρους συνεργασίας.';
        this.showBanner = true;
        this.firstMustConsent = true;
      } else {
        this.showBanner = false;
        this.firstMustConsent = false;
        this.bannerText = '';
      }
    }

    //---------------------------------------------
    // Απενεργοποιημένος Λογαριασμός

    if (this.isNotActive) {
      this.showBanner = true;
      if (this.isTireShop) {
        if (!this.hasConsented) {
          this.bannerText =
            'Ανενεργός Λογαριασμός, για ενεργοποίηση αποδεχτείτε τους όρους συνεργασίας';
        } else {
          this.bannerText =
            'Απενεργοποιημένος Λογαριασμός, για ενεργοποίηση επικοινωνήστε με την Ecoelastika';
        }
      } else {
        if (this.showTerms) {
          if (!this.hasConsented) {
            this.bannerText =
              'Ανενεργός Λογαριασμός, για ενεργοποίηση αποδεχτείτε τους όρους συνεργασίας';
          } else {
            this.bannerText =
              'Ανενεργός Λογαριασμός, για ενεργοποίηση επικοινωνήστε με την Ecoelastika. Έχετε αποδεχτεί τους όρους συνεργασίας.';
          }
        } else {
          this.bannerText =
            'Απενεργοποιημένος Λογαριασμός, για ενεργοποίηση επικοινωνήστε με την Ecoelastika';
        }
      }
    }
  }
  // * --------------- Constructor -------------------------------
  constructor() {
    this.init();
  }
}
