import React, { FC, useState } from 'react';
import { Form, Row, Col, Card } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { format, subDays } from 'date-fns';

// import { useWhyDidYouUpdate, useLogger } from '../../hooks';
import ReportFooter from '../../components/report-footer';

type Props = {
  onSubmit: (email: boolean) => void;
};

const CertificateCard: FC<Props> = ({ onSubmit }) => {
  /*-------------------- Body -----------------------------------------------*/

  /*-------------------- Handlers -------------------------------------------*/

  const handleSubmit = (email: boolean) => {
    onSubmit(email);
  };

  /*-------------------- Render ---------------------------------------------*/
  return (
    <>
      <Card className="bg-light shadow">
        <Card.Header>
          <Card.Title className="mb-0">
            Βεβαίωση Αποκομιδής Ελαστικών τέλους κύκλου ζωής για Ανακύκλωση
          </Card.Title>
        </Card.Header>

        <Form>
          <ReportFooter buttonText="ΔΗΜΙΟΥΡΓΙΑ ΒΕΒΑΙΩΣΗΣ" onSubmit={handleSubmit} />
        </Form>
      </Card>
    </>
  );
};

export default CertificateCard;
