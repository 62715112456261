import React from 'react';
import { useAgreementContentQuery } from '../../api/agreement-content';

export const TermsAndConditions = ({ agreementId, pdfFileLink }) => {
  //fetch terms
  const { loading, error, record, reFetch } = useAgreementContentQuery({
    id: agreementId,
  });

  return (
    <div>
      {/* pdf embedded

        <div style={{ marginTop: '1.5rem' }}>
        <object
          //data="https://ecopointweb.dyndns.org/webapi/agreement-template-id4.pdf"
          //data="http://10.192.64.103:8185/webapi/agreement-template-id7.pdf"
          data={pdfFileLink}
          type="application/pdf"
          width="100%"
          height="1200px"
        >
          <p>
            <a href={pdfFileLink}>Please press here to open the file!</a>
          </p>
        </object>
      </div> */}

      {/* <object
        id="frame"
        // data={pdfFileLink}
        data="http://10.192.64.103:8185/webapi/agreement-template-id5.htm"
        type="text/html"
        width="100%"
        height="260px"
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      >
        <p>
          <a href={pdfFileLink}>Please press here to open the file!</a>
        </p>
      </object> */}

      <div
        className="mt-3 mb-3"
        dangerouslySetInnerHTML={record ? { __html: record.TextContent } : null}
      ></div>
    </div>
  );
};
