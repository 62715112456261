import React from 'react';
//import packageJson from '../package.json';
//global.appVersion = packageJson.version;
import gitnum from '../gitnum';
global.appVersion = gitnum.version;

// version from response - first param, local version second param
const serverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...');
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function(names) {
            for (const name of names) caches.delete(name);
          });
        }

        // delete browser cache and hard reload
        window.location.reload();
      },
    };
  }

  componentDidMount() {
    const myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-cache');

    const myInit = {
      method: 'GET',
      headers: myHeaders,
    };

    const myRequest = new Request('/meta.json');
    //fetch('/meta.json')
    fetch(myRequest, myInit)
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;
        this.setState({ latestVersion: latestVersion, currentVersion: currentVersion });
        console.log(
          'versions',
          latestVersion,
          currentVersion,
          JSON.stringify(latestVersion === currentVersion),
        );

        const shouldForceRefresh = serverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`,
          );
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }
  render() {
    const {
      loading,
      isLatestVersion,
      refreshCacheAndReload,
      latestVersion,
      currentVersion,
    } = this.state;
    return this.props.children({
      loading,
      isLatestVersion,
      refreshCacheAndReload,
      latestVersion,
      currentVersion,
    });
  }
}

export default CacheBuster;
