import { useState, useEffect } from 'react';
import useAxios from '@use-hooks/axios';
import { oc } from 'ts-optchain';
import { useRootStore } from '../store';
import { useWhyDidYouUpdate } from '../hooks';

/*
###
GET http://192.168.160.203:8085/webapi/xOrders.cshtml?User=bikenrider@gmail.com&Pass=motion$hellas&CstPointId=2481
###
*/

export interface ContactPosition {
  Id: number;
  Name: string;

}

export const useContactPositionsQuery = () => {
  const { appStore } = useRootStore();
  const [message, setMessage] = useState('');
  const [records, setRecords] = useState([] as any[]);
  const url = appStore.webapi +
    `xGetCstContactPositions.cshtml?` +
    `User=${appStore.user}` +
    `&Pass=${appStore.password}`;
  const { response, loading, error, reFetch } = useAxios({
    url: url,
    method: 'GET',
    trigger: [],
    forceDispatchEffect: () => true,
  });
  const data = oc(response).data();
  useEffect(() => {
    if (error) {
      setMessage('Σφάλμα επικοινωνίας με τον Server!');
      setRecords([]);
    } else {
      if (!loading && !!data) {
        const resp = data as any[];
          setRecords(resp);

      }
    }
  }, [response, loading, error, data]);

  useWhyDidYouUpdate('[useContactPositionsQuery]', { loading, data, error });
  return {
    loading,
    error,
    message,
    records,
    reFetch,
  };
};
