import React, { FC, useEffect } from 'react';
import { Col, Card } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';

import { useWhyDidYouUpdate, useLogger } from '../../hooks';
import { ROUTES } from '../../routes';
import { useRootStore } from '../../store';
import { useLogQuery } from '../../api/log';

type Props = RouteComponentProps & {};
/*-------------------- Body -----------------------------------------------*/
const AboutView: FC<Props> = props => {
  const { appStore } = useRootStore();
  useWhyDidYouUpdate('[AboutView]', props);
  useLogger('[AboutView]');
  const { loading, error, records, reFetch } = useLogQuery();
  /*-------------------- Handlers -------------------------------------------*/
  if (!appStore.hasConsented && !appStore.isAlerted && appStore.cstPointTypeId === 0) {
    setTimeout(function() {
      appStore.alert();
      props.history.push(ROUTES.Agreement);
    }, 500);
  }

  useEffect(() => {
    reFetch();
  }, [reFetch]);

  const hoursPassed = lastLoginDate => {
    const loginDate = new Date(lastLoginDate);
    const now = new Date();
    const diff = (now.getTime() - loginDate.getTime()) / (1000 * 60 * 60);
    return Math.abs(Math.round(diff));
  };

  useEffect(() => {
    if (hoursPassed(appStore.lastLoginDate) >= 8) appStore.logOut();
  }, [appStore, appStore.lastLoginDate]);

  /*-------------------- Render ---------------------------------------------*/
  return (
    <Card className="bg-light shadow">
      <Card.Header>
        <Card.Title className="mb-0">Πληροφορίες Εφαρμογής</Card.Title>
      </Card.Header>
      <Card.Body className="p-2 p-sm-4">
        <Card.Text>
          Η συλλογή των ελαστικών τέλους κύκλου ζωής γίνεται απευθείας από τα σημεία συλλογής που
          είναι τα βουλκανιζατέρ, τα συνεργεία, τα διαλυτήρια αυτοκινήτων που έχουν συμβληθεί με το
          αντίστοιχο Σύστημα Εναλλακτικής Διαχείρισης οχημάτων τέλους κύκλου ζωής (ΕΔΟΕ) καθώς και
          τα αναγομωτήρια.
        </Card.Text>
        <Card.Text>
          Το κάθε σημείο συλλογής όταν συγκεντρώσει τουλάχιστον 60 ελαστικά Κατηγορίας Α ή/και 10
          ελαστικά Κατηγορίας Β ή/και 45 ελαστικά Κατηγορίας Γ θα πρέπει να ειδοποιεί την
          ECOELASTIKA για την παραλαβή των ελαστικών τέλους κύκλου ζωής, δηλώνοντας την ποσότητα που
          έχει συγκεντρώσει ανά κατηγορία ελαστικού η οποία καταγράφεται στο μηχανογραφικό σύστημα
          της εταιρίας. Ο συλλέκτης-μεταφορέας που έχει οριστεί για κάθε γεωγραφική περιοχή,
          ειδοποιείται ηλεκτρονικά σε καθημερινή βάση από την ECOELASTIKA για τις εκκρεμείς
          παραγγελίες.
        </Card.Text>
        <Card.Text>
          Παράλληλα, το σύστημα παραλαμβάνει λάστιχα από «κατόχους» όπως π.χ. τεχνικές εταιρίες,
          οργανισμοί, δήμοι κ.λ.π., κατόπιν υπογραφής ειδικής σχετικής σύμβασης. Με τον τρόπο αυτό
          έχει καταστεί εφικτό να οδηγηθούν προς αξιοποίηση σημαντικές ποσότητες που ήταν
          συσσωρευμένες από χρόνια, πριν από την ενεργοποίηση του συστήματος της Ecoelastika.
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <Card.Text className="h6">
          <strong>Επικοινωνία:</strong>
        </Card.Text>
        <Col className="pl-4">
          <Card.Text className="m-0">Εταιρία: ECOELASTIKA Α.Ε.</Card.Text>
          <Card.Text className="m-0">Διεύθυνση: Σωρού 14</Card.Text>
          <Card.Text className="m-0">Τ.Κ.: 151 25 Μαρούσι</Card.Text>
          <Card.Text className="m-0">Τηλ. Επικοινωνίας: 210.61.28.260, 210.61.28.370</Card.Text>
          <Card.Text className="m-0">Φαξ: 210.61.28.659</Card.Text>
          <Card.Text className="m-0">Αρ.ΓΕΜΗ: 005728201000</Card.Text>
          <Card.Text className="m-0">E-mail: info@ecoelastika.gr</Card.Text>
        </Col>
      </Card.Footer>
    </Card>
  );
};

export default AboutView;
