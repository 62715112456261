import RingBuffer from './ring-buffer';

// * Use Factory Pattern!

export const Log = () => {
  console.log('[Log] Factory');
  if (process.env.REACT_APP_CONSOLE !== '1') {
    console.log('[Log] Disabled');
    window['console']['log'] = function() {};
  }
  let messages = new RingBuffer(100);
  return {
    messages,
    log(...args: any[]) {
      var preservedConsoleLog = console.log;
      preservedConsoleLog.apply(console, args);
      if (process.env.REACT_APP_CONSOLE_MESSAGES === '1') {
        messages.push(args);
      }
    },
  };
};

export interface Log extends ReturnType<typeof Log> {}
