import React, { FC } from 'react';
import { Form, Button, Card, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaUser, FaLock, FaInfoCircle } from 'react-icons/fa';
import useForm from 'react-hook-form';
import * as yup from 'yup';

import AppStores from '../../components/appstores';
import { TooltipButton } from '../../components/tooltip-button';
import FlexRow from '../../components/flex-row';

//import { useWhyDidYouUpdate } from '../../hooks';
/**
 * Setup Yup Validation
 */
const validationSchema = yup.object().shape({
  email: yup.string().email(),
  password: yup
    .string()
    .required()
    .min(3),
});

export type LoginFormData = yup.InferType<typeof validationSchema>;

type Props = {
  message?: string;
  onSubmit: (v: LoginFormData) => void;
  onForgotClick?: () => void;
  onNewUserClick?: () => void;
  onTemporaryClick?: () => void;
};

const LoginForm: FC<Props> = ({
  message = ' ',
  onSubmit,
  onForgotClick = () => {},
  onNewUserClick = () => {},
  onTemporaryClick = () => {},
}) => {
  /*-------------------- Body -----------------------------------------------*/
  const { register, handleSubmit, formState, errors } = useForm<LoginFormData>({
    validationSchema,
    mode: 'onSubmit',
  });
  const canSubmit = formState.dirty && formState.isValid;
  // useWhyDidYouUpdate('[LoginForm]', { formState, errors });
  /*-------------------- Handlers -------------------------------------------*/

  /*-------------------- Render ---------------------------------------------*/
  return (
    <Card className="bg-light shadow-sm p-3 mb-5" style={{ maxWidth: 640 }}>
      <Card.Body>
        <Card.Title className="text-center mb-4 mt-1" as="h3">
          Καλώς ήρθατε στο Ecopoint!
        </Card.Title>
        <Card.Subtitle className="text-center mb-4 mt-1">
          <p>
            Συνδεθείτε τώρα για να καταχωρήσετε αίτημα παραλαβής ελαστικών
            <FlexRow>
              τέλους κύκλου ζωής, να αποκτήσετε πρόσβαση στα στοιχεία και στο προφίλ σας
              <span style={{ cursor: 'pointer' }}>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id={`tooltip-login`}>
                      <ol>
                        <li>
                          <strong>Κάντε την αίτηση</strong> για την συλλογή των ελαστικών τέλους
                          κύκλου ζωής από το κατάστημά σας <br />
                          <strong>
                            Όσο νωρίτερα γίνει η αίτηση, τόσο καλύτερα! Οι αιτήσεις μπαίνουν σε
                            προτεραιότητα... ακόμα και αν υποβληθούν μέσα στο Σαββατοκύριακο!
                          </strong>
                        </li>
                        <br />
                        <li>
                          <strong>Κατεβάστε όλα τα στοιχεία που αφορούν το κατάστημά σας!</strong>
                          <br />
                          ➢ Παραστατικά διακίνησης <br />
                          ➢ Ιστορικό των παραγγελιών / παραλαβών σας <br />
                          ➢ Ετήσια έκθεση αποβλήτων για το Η.Μ.Α. <br />
                          ➢ Βεβαίωση συνεργασίας <br />
                          ➢ Σύμβαση της μεταξύ μας συνεργασίας <br />
                        </li>
                        <br />
                        <li>
                          <strong>
                            Προφίλ: Δείτε ή μεταβάλετε τα στοιχεία σας που έχουν αλλάξει (π.χ.
                            διεύθυνση, email, τηλέφωνο)
                          </strong>
                        </li>
                      </ol>
                    </Tooltip>
                  }
                >
                  <FaInfoCircle size="22" color="black" />
                </OverlayTrigger>
              </span>
            </FlexRow>
          </p>
        </Card.Subtitle>
        <p className="text-danger text-center">{message}</p>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FaUser />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                name="email"
                placeholder="Email Χρήστη"
                type="email"
                ref={register}
                isInvalid={'email' in errors}
              />
              <Form.Control.Feedback type="invalid">Μη αποδεκτό email</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FaLock />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                name="password"
                placeholder="Κωδικός Πρόσβασης"
                type="password"
                ref={register}
                isInvalid={'password' in errors}
              />
              <Form.Control.Feedback type="invalid">Μη αποδεκτός κωδικός</Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Button
            block
            variant="primary"
            size="lg"
            type="submit"
            disabled={!canSubmit}
            style={{ height: 52 }}
          >
            <span>Είσοδος Χρήστη</span>
          </Button>
        </Form>
      </Card.Body>
      <Card.Footer>
        <p className="text-center">
          <Button
            style={{ borderWidth: 0 }}
            variant="outline-primary"
            size="sm"
            className="text-center"
            onClick={onForgotClick}
          >
            Ξέχασα τον κωδικό πρόσβασης
          </Button>
        </p>
        <p className="text-center">
          <Button
            style={{ borderWidth: 0 }}
            variant="outline-primary"
            size="sm"
            className="text-center"
            onClick={onTemporaryClick}
          >
            Αλλαγή Κωδικού Πρόσβασης
          </Button>
        </p>
        <p className="text-center">
          <Button
            style={{ borderWidth: 0 }}
            variant="outline-primary"
            size="sm"
            className="text-center"
            onClick={onNewUserClick}
          >
            Εγγραφή Νέου Χρήστη
          </Button>
        </p>
      </Card.Footer>
      {/* <AppStores /> */}
    </Card>
  );
};
export default LoginForm;
