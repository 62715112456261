import React, { FC } from 'react';
import { Form, Row, Col, Card, Accordion, Table } from 'react-bootstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { oc } from 'ts-optchain';

import { useWhyDidYouUpdate } from '../../hooks';

import { Order } from '../../api';
import ReportFooter from '../../components/report-footer';
import { AppStore } from '../../store/app-store';
import { useRootStore } from '../../store';

type Props = {
  keyValue: string;
  order: Order;
  collapsedId: string;
  onClick: (id: string) => void;
  onSubmit: (orderId: number, email: boolean) => void;
};

const OrderItem: FC<Props> = ({ keyValue, order, collapsedId, onClick, onSubmit }) => {
  /*-------------------- Body------------------------------------------------*/
  const colDate = oc(order).ColDate('?ColDate');
  const collectorName = oc(order).CollectorName('?CollectorName');
  const notes = oc(order).Notes('?Notes');
  const reqDate = oc(order).ReqDate('?ReqDate');
  const status = oc(order).Status('?Status');
  const tyre1 = oc(order).Tyre1('?Tyre1');
  const tyre2 = oc(order).Tyre2('?Tyre2');
  const tyre3 = oc(order).Tyre3('?Tyre3');
  const tyre4 = oc(order).Tyre4('?Tyre4');
  const tyre5 = oc(order).Tyre5('?Tyre5');
  const plannedDate = oc(order).PlannedDate('?PlannedDate');
  const isPlannedDateConfirmed = oc(order).IsPlannedDateConfirmed(false);
  const collectorPhone = oc(order).CollectorPhone('-');
  const onWait = status === 'Εκρεμεί η Συλλογή';

  const title = 'Αίτηση ' + reqDate;
  let subtitle = 'Σε αναμονή παραλαβής';
  let classColor = 'text-danger';
  let subsubtitle = '';

  const { appStore } = useRootStore();

  if (onWait && plannedDate !== '') {
    if (!isPlannedDateConfirmed) {
      if (appStore.isTireShop) subsubtitle = `Εκτιμώμενη ημερομηνία παραλαβής: ${plannedDate}`;
      else subsubtitle = undefined;
    } else if (!!isPlannedDateConfirmed)
      subsubtitle = `Προγραμματισμένη για τις ${plannedDate} απο ${collectorName} τηλ. ${collectorPhone}`;
  }

  if (!onWait) {
    subtitle = 'Έχει γίνει παραλαβή ' + colDate;
    classColor = 'text-success';
  }
  /*-------------------- Handlers -------------------------------------------*/
  const handleClick = () => {
    onClick(keyValue);
  };

  const handleSubmit = (email: boolean) => {
    console.log(email);
    onSubmit(order.OrderId, email);
  };

  useWhyDidYouUpdate('[OrderItem]', collapsedId);
  /*-------------------- Render ---------------------------------------------*/
  return (
    <Card className="border-right-0 border-left-0">
      <Accordion.Toggle
        as={Card.Header}
        className="pb-2 pt-2"
        eventKey={keyValue}
        onClick={handleClick}
      >
        <div className="mb-0 d-flex flex-row justify-content-between align-items-center">
          <Col>
            <Card.Title className={classColor}>{title}</Card.Title>
            <Card.Subtitle className="text-muted">{subtitle}</Card.Subtitle>
            {subsubtitle && <Card.Subtitle className="text-muted">{subsubtitle}</Card.Subtitle>}
          </Col>
          {collapsedId === keyValue ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </Accordion.Toggle>

      <Accordion.Collapse eventKey={keyValue} className="border">
        <>
          <Card.Body className="pt-0 pb-2 pl-2 pr-2">
            <Form.Group as={Form.Row} className="m-1" controlId="formPlaintextCollector">
              <Form.Label column sm={4} md={3} lg={2} className="">
                Μεταφορέας:
              </Form.Label>
              <Col className="border">
                <Form.Control plaintext readOnly defaultValue={collectorName} />
              </Col>
            </Form.Group>

            <Row className="m-1">
              <Col>
                <Table className="mb-1" responsive size="sm">
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'center' }} colSpan={5}>
                        Ελαστικά Παραλαβής/Αίτησης:
                      </th>
                    </tr>
                    <tr>
                      <th style={{ textAlign: 'center' }}>Α</th>
                      <th style={{ textAlign: 'center' }}>Β</th>
                      <th style={{ textAlign: 'center' }}>Γ</th>
                      <th style={{ textAlign: 'center' }}>Δ1</th>
                      <th style={{ textAlign: 'center' }}>Δ2</th>
                    </tr>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'center' }}>{tyre1}</td>
                      <td style={{ textAlign: 'center' }}>{tyre2}</td>
                      <td style={{ textAlign: 'center' }}>{tyre3}</td>
                      <td style={{ textAlign: 'center' }}>{tyre4}</td>
                      <td style={{ textAlign: 'center' }}>{tyre5}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>

            {notes !== '' ? (
              <Form.Group as={Form.Row} className="m-1" controlId="formPlaintextNotes">
                <Form.Label column sm={4} md={3} lg={2} className="">
                  Σημειώσεις:
                </Form.Label>
                <Col className="border">
                  <Form.Control plaintext readOnly defaultValue={notes} />
                </Col>
              </Form.Group>
            ) : (
              <></>
            )}
          </Card.Body>
          <ReportFooter buttonText="ΛΗΨΗ ΔΕΛΤΙΟΥ" onSubmit={handleSubmit} whiteBg />
        </>
      </Accordion.Collapse>
    </Card>
  );
};

export default OrderItem;
