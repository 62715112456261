import React, { FC } from 'react';
import { Row, Col, Card, Badge } from 'react-bootstrap';

type Props = {
  user: string;
  name: string;
  typeId: number;
  address: string;
};

const UserCard: FC<Props> = ({ name, typeId, address }) => {
  const badgeText = typeId === 2 ? 'Δ' : typeId === 0 ? 'K' : 'ΚεΔ';
  return (
    <Card className="bg-light shadow mb-4">
      <Card.Img className="bg-light" variant="top" src="ecopoint_whiteback.png" />
      <Card.Header className="d-none d-lg-block">
        <Card.Title className="mb-0 text-center">Σημείο Συλλογής</Card.Title>
      </Card.Header>
      <Card.Body className="p-2 p-sm-3">
        <Row>
          <Col className="d-inline-block">
            {name}
            <Badge className="ml-1 align-top" style={{ fontSize: '0.75rem' }} variant="primary">
              {badgeText}
            </Badge>
          </Col>
          <Col className="d-none d-md-block text-right">{address}</Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default UserCard;
