import React, { FC, useState } from 'react';
import { Form, Row, Col, Card } from 'react-bootstrap';

// import { useWhyDidYouUpdate, useLogger } from '../../hooks';
import ReportFooter from '../../components/report-footer';

function lastYears(): string[] {
  let ret: string[] = [] as string[];

  const lastyear = new Date().getFullYear() - 1;

  for (let i = lastyear; i >= 2012; i--) {
    ret.push(i.toString());
  }
  return ret;
}

type Props = {
  onSubmit: (year: string, email: boolean) => void;
};

const YearReportCard: FC<Props> = ({ onSubmit }) => {
  /*-------------------- Body -----------------------------------------------*/
  const years = lastYears();
  const [year, setYear] = useState(years[0]);

  /*-------------------- Handlers -------------------------------------------*/
  const handleYearChange = event => {
    setYear(event.target.value);
  };
  const handleSubmit = (email: boolean) => {
    onSubmit(year, email);
  };
  // useWhyDidYouUpdate('[YearReportCard]', { loading, onSubmit, year, email });

  /*-------------------- Render ---------------------------------------------*/
  return (
    <Card className="bg-light shadow">
      <Card.Header>
        <Card.Title className="mb-0">Ετήσια Έκθεση Αποβλήτων</Card.Title>
      </Card.Header>

      <Form>
        <Card.Body>
          <Form.Group className="mb-0" as={Row} controlId="year-report-card_year">
            <Form.Label column xs={8} sm={7} md={5} lg={4}>
              Επιλογή Επιθυμητού Έτους :
            </Form.Label>
            <Col>
              <Form.Control
                as="select"
                style={{ maxWidth: 150, minWidth: 100 }}
                value={year}
                onChange={handleYearChange}
              >
                {years.map(item => (
                  <option key={item}>{item}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        </Card.Body>
        <ReportFooter buttonText="ΔΗΜΙΟΥΡΓΙΑ ΕΚΘΕΣΗΣ" onSubmit={handleSubmit} />
      </Form>
    </Card>
  );
};

export default YearReportCard;
