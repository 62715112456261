export type TyreLimits = {
  min1: number;
  min2: number;
  min3: number;
  min4: number;
  min5: number;
  max1: number;
  max2: number;
  max3: number;
  max4: number;
  max5: number;
  show1: boolean;
  show2: boolean;
  show3: boolean;
  show4: boolean;
  show5: boolean;
};

// Καταστήματα & Default
export const shopsTyreLimits: TyreLimits = {
  min1: 60,
  min2: 10,
  min3: 45,
  min4: 1,
  min5: 1,
  max1: 999,
  max2: 500,
  max3: 500,
  max4: 500,
  max5: 500,
  show1: true,
  show2: true,
  show3: true,
  show4: true,
  show5: true,
};

// Στόλος
export const stolosTyreLimits: TyreLimits = {
  min1: 200,
  min2: 50,
  min3: 150,
  min4: 1,
  min5: 1,
  max1: 999,
  max2: 500,
  max3: 500,
  max4: 500,
  max5: 500,
  show1: true,
  show2: true,
  show3: true,
  show4: true,
  show5: true,
};

// ΚΔΑΥ
const kdayTyreLimits: TyreLimits = {
  min1: 60,
  min2: 0,
  min3: 45,
  min4: 0,
  min5: 0,
  max1: 999,
  max2: 0,
  max3: 500,
  max4: 0,
  max5: 0,
  show1: true,
  show2: false,
  show3: true,
  show4: false,
  show5: false,
};

// Ανεξέλεγκτη Διάθεση
export const anekselegtiTyreLimits: TyreLimits = {
  min1: 1,
  min2: 1,
  min3: 1,
  min4: 1,
  min5: 1,
  max1: 250,
  max2: 40,
  max3: 500,
  max4: 500,
  max5: 500,
  show1: true,
  show2: true,
  show3: true,
  show4: true,
  show5: true,
};

// ΑΝΑΓΟΜΩΣΗ
const anagomosiTyreLimits: TyreLimits = {
  min1: 0,
  min2: 50,
  min3: 0,
  min4: 20,
  min5: 20,
  max1: 0,
  max2: 500,
  max3: 0,
  max4: 500,
  max5: 500,
  show1: false,
  show2: true,
  show3: false,
  show4: true,
  show5: true,
};

// ΜΕΤΑΧΕΙΡΙΣΜΕΝΑ
const metaxeirismenaTyreLimits: TyreLimits = {
  min1: 200,
  min2: 50,
  min3: 150,
  min4: 1,
  min5: 1,
  max1: 999,
  max2: 500,
  max3: 500,
  max4: 500,
  max5: 500,
  show1: true,
  show2: true,
  show3: true,
  show4: true,
  show5: true,
};

export function getTyreLimits(inUseAgreementId: number): TyreLimits {
  if (inUseAgreementId === 5) {
    return stolosTyreLimits;
  } else if (inUseAgreementId === 6) {
    return kdayTyreLimits;
  } else if (inUseAgreementId === 7) {
    return anekselegtiTyreLimits;
  } else if (inUseAgreementId === 9) {
    return anagomosiTyreLimits;
  } else if (inUseAgreementId === 10) {
    return metaxeirismenaTyreLimits;
  } else return shopsTyreLimits;
}
