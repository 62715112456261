import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { format } from 'date-fns';

import { useSt8, useWhyDidYouUpdate } from '../../hooks';
import { useReportQuery } from '../../api';
import { useRootStore } from '../../store';

import ModalReturn from '../../components/modal-dialog';
import ModalSpinner from '../../components/modal-spinner';
import { useCertificateQuery } from '../../api/certificate';
import CertificateCard from './certificate-card';
import { oc } from 'ts-optchain';
import useAxios from '@use-hooks/axios';
import ModalDialogFull from '../../components/modal-dialog-full';
import { ROUTES } from '../../routes';

type Props = RouteComponentProps & {};

const CertificateView: FC<Props> = props => {
  /*-------------------- Body -----------------------------------------------*/
  const { appStore } = useRootStore();
  const [emailChecked, setEmailChecked] = useState('');

  // local variables
  const cstPointId = appStore.cstPointId;
  const type = emailChecked ? 'EMAIL' : 'BLOB';
  const { busy, finish, reFetch: reFetchReport } = useCertificateQuery(type, cstPointId);
  const [isActive, setIsActive] = useState(true);
  const [modalText, setModalText] = useState('Παρακαλώ υπογράψτε τους όρους συνεργασίας');
  const finishPre = useSt8(false);

  const user = appStore.user;
  const password = appStore.password;
  // ---------------------------  preRequest Hook
  const urlPre =
    appStore.webapi +
    `xPreRequest2.cshtml?User=${user}` +
    `&Pass=${password}` +
    `&CstPointId=${cstPointId}`;

  const { response: responsePre, loading: loadingPre, error: errorPre } = useAxios({
    url: urlPre,
    method: 'GET',
    trigger: [],
  });
  const dataPre = oc(responsePre).data(false);

  useEffect(() => {
    if (errorPre) {
      console.log('Error!');
    } else {
      if (dataPre !== false) {
        console.log(JSON.parse(dataPre));
        const orderObj = JSON.parse(dataPre);
        switch (orderObj.ErrResult) {
          case 0: // Normal order
            finishPre(true);
            break;
          case 4: // No orders
            finishPre(true);
            break;
          case 3: // CstPoint not active
            setIsActive(false);
            setModalText(
              appStore.hasConsented
                ? 'Παρακαλώ επικοινωνήστε με ECOELASTIKA (κος Τατσιόπουλος 210 61.28.260) για την ενεργοποίηση του λογαριασμού σας'
                : 'Παρακαλώ υπογράψτε τους όρους συνεργασίας και επικοινωνήστε με ECOELASTIKA (κος Τατσιόπουλος 210 61.28.260) για την ενεργοποίηση του λογαριασμού σας',
            );
            finishPre(true);
            break;
          default:
            console.log(orderObj.ErrMessage);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPre, errorPre]);
  // -------------------------------------------------------------------

  /*-------------------- Handlers -------------------------------------------*/
  const handleSubmit = _email => {
    console.log('Submit YEAR:', ' Email', _email);
    setEmailChecked(_email);
    reFetchReport();
  };

  useWhyDidYouUpdate('[CertificateView]', {
    email: emailChecked,
    busy,
  });

  /*-------------------- Render ---------------------------------------------*/
  return (
    <>
      {finishPre() && (
        <>
          {!isActive || (appStore.inUseAgreementId !== null && !appStore.hasConsented) ? (
            <ModalDialogFull
              title={'Δεν έχετε δικαίωμα για Βεβαίωση'}
              text={modalText}
              onClick={() => props.history.push(ROUTES.CstPoint)}
            />
          ) : (
            <CertificateCard onSubmit={handleSubmit}></CertificateCard>
          )}
        </>
      )}

      <ModalSpinner show={busy}></ModalSpinner>
      <ModalReturn title={'Η ΒΕΒΑΙΩΣΗ ΟΛΟΚΛΗΡΩΘΗΚΕ'} trigger={finish} />
    </>
  );
};

export default CertificateView;
