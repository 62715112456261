import { useState, useEffect } from 'react';
import useAxios from '@use-hooks/axios';
import { oc } from 'ts-optchain';
import { useRootStore } from '../store';
import { useWhyDidYouUpdate } from '../hooks';

/*
###
GET http://192.168.160.203:8085/webapi/xOrders.cshtml?User=bikenrider@gmail.com&Pass=motion$hellas&CstPointId=2481
###
*/

export interface Agreement {
  IID: number;
  Name: string;
  TextContent: string;
  InUseFromDT: string;
  CstPointTypeId: number;
  CstPountGroupId: number;
}

export const useAgreementContentQuery = ({ id }) => {
  const { appStore } = useRootStore();
  const [message, setMessage] = useState('');
  const [record, setRecord] = useState<Agreement>();
  const url = id
    ? appStore.webapi +
      `xGetAgreementContent.cshtml?` +
      `User=${appStore.user}` +
      `&Pass=${appStore.password}` +
      `&Id=${id}`
    : '';
  const { response, loading, error, reFetch } = useAxios({
    url: url,
    method: 'GET',
  });
  const data = oc(response).data();
  useEffect(() => {
    if (error) {
      setMessage('Σφάλμα επικοινωνίας με τον Server!');
    } else {
      if (!loading && !!data) {
        const resp = data as Agreement;
        setRecord(resp);
      }
    }
  }, [response, loading, error, data]);

  useEffect(() => {
    reFetch();
  }, []);
  useWhyDidYouUpdate('[useAgreementQuery]', { loading, data, error });
  return {
    loading,
    error,
    message,
    record,
    reFetch,
  };
};
